import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {Grid, Typography, useTheme} from "@mui/material";
import { FontSizes } from "../../constants";
import {ComponentRef, getComponentRef} from "../../message/components/records/MessageTagUtil";

const Header = ({ id, label, handleSubComponentLoaded = (subComponent = {...ComponentRef}, isLoaded = false) => {},
                  itemIndex, level, messageTime }) => {

  const theme = useTheme();

  useEffect(() => {
    handleSubComponentLoaded(getComponentRef("Header", itemIndex, level, messageTime), true);
  }, []);

  return <>
    <Grid item container sx={{marginBottom: "1px"}}>
      <Grid item sx={{marginRight: "10px"}}>
        <Typography sx={{marginY: "5px", fontWeight: "bold", color: theme.palette.forms.heading, fontSize: FontSizes.HeadingLabel}} id={id}>{label}</Typography>
      </Grid>
    </Grid>
  </>;
};

Header.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default Header;
