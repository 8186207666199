import React, { useContext, useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import DateTimePicker from "../../../MaterialForms/DateTimePicker";
import { UserContext } from "../../models/UserContext";
import {
  MessageTagModel,
  MessageTagOptions,
} from "../../models/MessageRecordModel";
import { Guid } from "../../../util/Helpers";

const ScheduleMessageModal = ({ show, hideModal, onSave }) => {
  const { currentUser } = useContext(UserContext.UserContext);
  const [scheduleCalendarValue, setScheduleCalendarValue] = useState();

  const isSendDisabled = useMemo(() => {
    if (scheduleCalendarValue) {
      const now = new Date();
      return scheduleCalendarValue <= now;
    }
    return false;
  }, [scheduleCalendarValue]);

  const handleOnSave = () => {
    const messageTagId = Guid();
    const sectionName = MessageTagOptions.ScheduleTag.Name;

    let metaData = [];
    metaData.push({
      id: Guid(),
      key: MessageTagOptions.ScheduleTag.KVDate,
      value: scheduleCalendarValue.getTime(),
      messageTagId,
    });
    metaData.push({
      id: Guid(),
      key: MessageTagOptions.ScheduleTag.KVEnabled,
      value: true,
      messageTagId,
    });

    const scheduleTag = {
      ...MessageTagModel,
      id: messageTagId,
      name: sectionName,
      createdOn: new Date(),
      createdBy: currentUser.id,
      metaData,
    };

    const messageTagSet = {
      systemTags: [],
      accountTags: [],
      companyTags: [],
      reactionTags: [],
      atTags: [],
      userTags: [scheduleTag],
    };

    onSave(messageTagSet);
  };

  const handleOnClose = () => {
    setScheduleCalendarValue(undefined);
    hideModal();
  };

  return (
    <Dialog open={show} onClose={handleOnClose} maxWidth="md">
      <DialogTitle>{`Schedule a message`}</DialogTitle>
      <DialogContent>
        <DateTimePicker onChange={setScheduleCalendarValue} />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          style={{ fontWeight: "bold" }}
          onClick={handleOnSave}
          disabled={isSendDisabled}
        >
          {"Send"}
        </Button>
        <Button
          variant={"contained"}
          color={"error"}
          style={{ fontWeight: "bold" }}
          onClick={() => hideModal()}
        >{`Cancel`}</Button>
      </DialogActions>
    </Dialog>
  );
};

ScheduleMessageModal.propTypes = {
  show: PropTypes.bool,
  hideModal: PropTypes.func,
  onSave: PropTypes.func,
};

export default ScheduleMessageModal;
