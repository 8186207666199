import {HubConnectionBuilder, HubConnectionState} from "@microsoft/signalr";

/**
 * https://docs.microsoft.com/en-us/javascript/api/@microsoft/signalr/?view=signalr-js-latest
 *
 * @param baseUrl
 * @param hubName
 * @returns {Promise<HubConnection>}
 * @constructor
 */

export const SignalRConnection = (baseUrl, hubName) => {
    const setUpSignalRConnection = async () => {
        const connection = new HubConnectionBuilder()
            .withUrl(baseUrl + "/" + hubName, {
                withCredentials: false
            })
            .withAutomaticReconnect()
            .build();

        try {
            await connection.start();
        } catch (error) {
            console.error(error);
        }

        if (connection.state === HubConnectionState.Connected) {
            connection.invoke("negotiate").catch((err) => {
                return console.log("Connection was probably unloaded due to component unload");
                //return console.error(`Caught a SignalR Connect Error - ${err.toString()}`);
            });
        }

        return connection;
    }
    return setUpSignalRConnection();
}