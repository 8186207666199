import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { AppContext } from "../../contexts/AppContext";
import DateTimePicker from "../../../MaterialForms/DateTimePicker";

const CalendarReminderModal = ({ show, hideModal, onSave }) => {
  const { snackbar, setSnackbar } = useContext(AppContext.SnackbarContext);
  const [reminderCalendarValue, setReminderCalendarValue] = useState();

  const handleOnSave = () => {
    onSave(reminderCalendarValue);
    setSnackbar({
      ...snackbar,
      open: true,
      severity: "success",
      message: "Message reminder added",
    });
  };

  const handleOnCancel = () => {
    setReminderCalendarValue(undefined);
    hideModal();
  };

  return (
    <Dialog open={show} onClose={hideModal}>
      <DialogTitle>{`Reminder`}</DialogTitle>
      <DialogContent>
        {`Add this to message reminder`}
        <DateTimePicker onChange={(value) => setReminderCalendarValue(value)} />
      </DialogContent>
      <DialogActions>
        <Button
          variant={"contained"}
          style={{ fontWeight: "bold" }}
          onClick={handleOnSave}
          disabled={!reminderCalendarValue}
        >{`Save`}</Button>
        <Button
          variant={"contained"}
          color={"error"}
          style={{ fontWeight: "bold" }}
          onClick={handleOnCancel}
        >{`Cancel`}</Button>
      </DialogActions>
    </Dialog>
  );
};

CalendarReminderModal.propTypes = {
  show: PropTypes.bool,
  hideModal: PropTypes.func,
  onSave: PropTypes.func,
};

export default CalendarReminderModal;
