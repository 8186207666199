import {
  Circle,
  Close,
  Delete,
  Edit,
  EmojiEmotions,
  EventAvailable,
  EventNote,
  MoreVert,
  Report,
  Schedule,
} from "@mui/icons-material";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";

import { AccountDetailsContext } from "../../models/AccountDetailsContext";
import { UserContext } from "../../models/UserContext";
import { UserListContext, findUser } from "../../models/UserListContext";

import {
  MessageOperationTask,
  messageDateFormat,
} from "../../controllers/MessageController";
import { MessageOperations } from "../../data/MessageData";

import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popover,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Guid, IsTimeWithinHours, StringToColor } from "../../../util/Helpers";
import {
  MessageTagModel,
  MessageTagOptions,
  MessageTagSetModel,
} from "../../models/MessageRecordModel";
import MessageTag from "./MessageTag";

import EmojiPicker from "emoji-picker-react";
import moment from "moment";
import "react-calendar/dist/Calendar.css";
import { api } from "../../../../App";
import useUnreadMessages from "../../../../hooks/useUnreadMessages";
import { FontSizes, MILLI_TO_HOURS_DIVISOR } from "../../../constants";
import { MessageContext } from "../../contexts/MessageContext";
import { SpaceContext } from "../../contexts/SpaceContext";
import { SpaceMemberStruct } from "../../data/SpaceData";
import CalendarPlannerModal from "../modals/CalendarPlanner";
import CalendarReminderModal from "../modals/CalendarReminder";
import EditMessageModal from "../modals/EditMessageModal";
import ScheduleMessageModal from "../modals/ScheduleMessageModal";
import { isValidEditMessage } from "./MessageTagUtil";
import DateTag from "./tags/DateTag";
import ReactionTag from "./tags/ReactionTag";
import Tag from "./tags/Tag";

export const MessageRecordDimensions = {
  id: "",
  width: 0,
  height: 0,
  isDataRendered: false,
};

function MessageRecord(props) {
  const {
    item,
    updateRecord = (messageId = "") => {
      return messageId;
    },
    recordIndex,
    lastViewTime,
    updateLastViewTime = (newDate) => {
      return new Date();
    },
    highlightUnread,
    handleDimensionChange = ({ ...MessageRecordDimension }) => {
      return MessageRecordDimensions;
    },
  } = props;

  const theme = useTheme();
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const messageRef = useRef(null);

  const { accountDetails } = useContext(
    AccountDetailsContext.AccountDetailsContext
  );
  const { userList } = useContext(UserListContext.UserListContext);
  const { currentUser } = useContext(UserContext.UserContext);
  const { messageItemList, messageItemDispatcher } = useContext(
    MessageContext.MessageRecordMgr
  );
  const { activeSpace } = useContext(SpaceContext.ActiveSpaceContext);

  const message = useMemo(() => {
    return {
      id: item.id,
      createdDate: item.createdUtc
        ? new Date(item.createdUtc.toString())
        : new Date(),
      owner: item.createdById,
      content: item.message,
      visible: item.isVisible,
      marker: item.marker,
      messageTagSet: item.messageTagSet,
      historicalMessages: item.historicalMessages,
    };
  }, [item]);

  const messageTagSet = useMemo(
    () =>
      !item.messageTagSet
        ? {
            ...MessageTagSetModel,
            systemTags: [],
            accountTags: [],
            companyTags: [],
            userTags: [],
            reactionTags: [],
            atTags: [],
          }
        : item.messageTagSet,
    [item]
  );

  const [showHighlightedNew, setShowHighlightedNew] = useState(highlightUnread);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const [showOptionsDialog, setShowOptionsDialog] = useState(false);
  const [optionsDialogPopUpSide, setOptionsDialogPopUpSide] = useState("left");
  const [showOptionsButton, setShowOptionsButton] = useState(false);
  const [optionsAnchorElement, setOptionsAnchorElement] = useState(false);
  const [showDailyPlannerModal, setShowDailyPlannerModal] = useState(false);
  const [showReminderModal, setShowReminderModal] = useState(false);
  const [showScheduleMsgModal, setShowScheduleMsgModal] = useState(false);
  const [showEmojiPickerDialog, setShowEmojiPickerDialog] = useState(false);
  const [showMessageEditModal, setShowMessageEditModal] = useState(false);

  const [isTagLoaded, setIsTagLoaded] = useState(false);

  const [unreadMessagesCount, handleSpaceMessagesRead] =
    useUnreadMessages(activeSpace);

  const disableMessageEdit = useMemo(() => {
    const now = new Date();
    const msgDate = new Date(message.createdDate);
    const hoursDifference =
      (now.getTime() - msgDate.getTime()) / MILLI_TO_HOURS_DIVISOR;
    return (
      hoursDifference > 24 ||
      !message.content ||
      !isValidEditMessage(message.content) ||
      currentUser.id !== message.owner
    );
  }, [message]);

  const isEdited = useMemo(() => {
    return message.historicalMessages && message.historicalMessages.length > 0;
  }, [message]);

  useEffect(() => {
    if (
      !message ||
      !message.id ||
      messageRef === null ||
      messageRef.current === null
    ) {
      return;
    }
    // console.log(`Triggered change [${message.id}] -> ${isTagLoaded}`);
    if (isTagLoaded) {
      const { width, height } = messageRef.current.getBoundingClientRect();
      // console.log(`Triggered change sub element loaded -> [${message.id}] @ ${message.createdDate} (width: ${width}px, height: ${height}px) -> ${isTagLoaded}`);
      handleDimensionChange({
        id: message.id,
        width: width,
        height: height,
        isDataRendered: true,
      });
    }
  });

  const handleTagCallback = (isTagLoaded = false) => {
    // console.log(`Is TAG loaded [${message.id}] {${messageRef.current} -> ${isTagLoaded}`);
    if (isTagLoaded) {
      setIsTagLoaded(isTagLoaded);
    }
  };

  const handleOptionsDialogClick = (event) => {
    if (message.owner === currentUser.id) {
      setOptionsDialogPopUpSide("right");
    } else {
      setOptionsDialogPopUpSide("left");
    }

    setShowOptionsDialog(true);
    setOptionsAnchorElement(event.currentTarget);
  };

  const deleteItem = (messageItem) => {
    MessageOperationTask(MessageOperations.SEND_DELETE_MESSAGE, {
      accountDetails: accountDetails,
      messageItem: messageItem,
    });
    handleDeleteDialog();
  };

  const handleDeleteDialog = () => {
    const user = findUser(userList, message.owner);
    if (
      (user !== undefined &&
        currentUser.id === user.id &&
        IsTimeWithinHours(message.createdDate, 5)) ||
      currentUser.isAdmin
    ) {
      setShowDeleteDialog(!showDeleteDialog);
    }
  };

  const renderDeleteDialog = (isMyMessage, displayName = "") => {
    return (
      <Dialog open={showDeleteDialog} onClose={handleDeleteDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Do you want to delete the message
          {isMyMessage ? `` : ` by ${displayName}`} written on{" "}
          {messageDateFormat(message.createdDate)}?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialog} variant="contained" autoFocus>
            Cancel
          </Button>
          <Button onClick={() => deleteItem(message)} variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handlePlannerCalendarSave = (value) => {
    const sectionName = MessageTagOptions.PlannerTag.Name;

    const otherTags = messageTagSet.companyTags.filter(
      (tag) =>
        (tag.name === sectionName && tag.createdBy !== currentUser.id) ||
        tag.name !== sectionName
    );
    let metadata = [];
    metadata.push({
      key: MessageTagOptions.PlannerTag.KVDate,
      value: value.getTime(),
    });
    metadata.push({
      key: MessageTagOptions.PlannerTag.KVCompany,
      value: accountDetails.axial.company.id,
    });
    metadata.push({ key: MessageTagOptions.PlannerTag.KVEnabled, value: true });

    const updatedTags = [
      ...otherTags,
      {
        ...MessageTagModel,
        id: Guid(),
        name: sectionName,
        createdOn: new Date(),
        createdBy: currentUser.id,
        metaData: [...metadata],
      },
    ];

    const updatedMessage = {
      ...item,
      messageTagSet: { ...messageTagSet, companyTags: updatedTags },
    };

    updateMessage(updatedMessage);

    setShowDailyPlannerModal(false);
  };

  const handleReminderCalendarSave = (value) => {
    const sectionName = MessageTagOptions.ReminderTag.Name;

    const otherTags = messageTagSet.userTags.filter(
      (tag) =>
        (tag.name === sectionName && tag.createdBy !== currentUser.id) ||
        tag.name !== sectionName
    );
    let metadata = [];
    metadata.push({
      key: MessageTagOptions.ReminderTag.KVDate,
      value: value.getTime(),
    });
    metadata.push({
      key: MessageTagOptions.ReminderTag.KVEnabled,
      value: true,
    });

    const updatedTags = [
      ...otherTags,
      {
        ...MessageTagModel,
        id: Guid(),
        name: sectionName,
        createdOn: new Date(),
        createdBy: currentUser.id,
        metaData: [...metadata],
      },
    ];

    const updatedMessage = {
      ...item,
      messageTagSet: { ...messageTagSet, userTags: updatedTags },
    };

    updateMessage(updatedMessage);

    setShowReminderModal(false);
  };

  const renderEmojiPicker = () => {
    return (
      <Dialog
        open={showEmojiPickerDialog}
        onClose={() => setShowEmojiPickerDialog(!showEmojiPickerDialog)}
      >
        <DialogTitle>{`Add a Reaction`}</DialogTitle>
        <DialogContent>
          {<EmojiPicker onEmojiClick={handleEmojiClick} />}
        </DialogContent>
      </Dialog>
    );
  };

  const handleEmojiClick = (emojiData, evt) => {
    const { emoji } = emojiData;

    const sectionName = MessageTagOptions.ReactionTag.Name;

    const otherTags = messageTagSet.reactionTags.filter(
      (tag) =>
        tag.name === MessageTagOptions.ReactionTag.Name &&
        tag.createdBy !== currentUser.id
    );

    const myTags = messageTagSet.reactionTags.filter(
      (tag) =>
        tag.name === MessageTagOptions.ReactionTag.Name &&
        tag.createdBy === currentUser.id &&
        tag.metaData.length > 0 &&
        tag.metaData[0].value !== emoji
    );

    let metadata = [];
    metadata.push({
      key: MessageTagOptions.ReactionTag.KVReaction,
      value: emoji,
    });

    const updatedTags = [
      ...otherTags,
      ...myTags,
      {
        ...MessageTagModel,
        id: Guid(),
        name: sectionName,
        createdOn: new Date(),
        createdBy: currentUser.id,
        metaData: [...metadata],
      },
    ];

    const updatedMessage = {
      ...item,
      messageTagSet: { ...messageTagSet, reactionTags: updatedTags },
    };

    updateMessage(updatedMessage);

    setShowEmojiPickerDialog(false);
  };

  const handleReactionRemove = (emoji) => {
    const otherTags = messageTagSet.reactionTags.filter(
      (tag) =>
        tag.name === MessageTagOptions.ReactionTag.Name &&
        tag.createdBy !== currentUser.id
    );

    const myTags = messageTagSet.reactionTags.filter(
      (tag) =>
        tag.name === MessageTagOptions.ReactionTag.Name &&
        tag.createdBy === currentUser.id &&
        tag.metaData.length > 0 &&
        tag.metaData[0].value !== emoji
    );

    const updatedTags = [...otherTags, ...myTags];

    const updatedMessage = {
      ...item,
      messageTagSet: { ...messageTagSet, reactionTags: updatedTags },
    };

    updateMessage(updatedMessage);
  };

  const handleMessageEdit = (message) => {
    updateMessage(message);

    setShowMessageEditModal(false);
  };

  const updateMessage = (updatedMessage) => {
    MessageOperationTask(MessageOperations.SEND_UPDATE_MESSAGE, {
      accountDetails: accountDetails,
      payload: updatedMessage,
    });

    const messages = messageItemList.messageList.filter(
      (message) => message.id !== updatedMessage.id
    );

    messageItemDispatcher({
      type: MessageOperations.LOAD_MESSAGE_LIST,
      ref: { ...messageItemList, messageList: [...messages, updatedMessage] },
    });

    // call back to trigger an update
    updateRecord(item.id);
  };

  const popoverCallbackClosure = (callbackFunction) => {
    setShowOptionsDialog(false);
    setShowOptionsButton(false);
    setOptionsAnchorElement(undefined);

    if (callbackFunction) {
      callbackFunction();
    }
  };

  const renderPopoverMenuItem = (
    title,
    icon,
    shouldRenderOption = true,
    callbackFunction
  ) => {
    if (shouldRenderOption === false) {
      return null;
    }

    return (
      <MenuItem onClick={() => popoverCallbackClosure(callbackFunction)}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText>{title}</ListItemText>
      </MenuItem>
    );
  };

  const renderPopover = () => {
    return (
      <Popover
        id="popover"
        open={showOptionsDialog}
        anchorEl={optionsAnchorElement}
        onClose={() => popoverCallbackClosure()}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: optionsDialogPopUpSide,
        }}
      >
        <Paper sx={{ width: 320, maxWidth: "100%" }}>
          <MenuList>
            {renderPopoverMenuItem(
              "Add Reaction",
              <EmojiEmotions />,
              true,
              () => {
                setShowEmojiPickerDialog(true);
              }
            )}
            {renderPopoverMenuItem(
              "Add this to Daily Planner",
              <EventNote />,
              true,
              () => {
                setShowDailyPlannerModal(true);
              }
            )}
            {renderPopoverMenuItem(
              "Remind me about this message",
              <Schedule />,
              true,
              () => {
                setShowReminderModal(true);
              }
            )}
            {renderPopoverMenuItem(
              "Schedule message for the future",
              <EventAvailable />,
              message.owner === currentUser.id,
              () => {
                setShowScheduleMsgModal(true);
              }
            )}
            <Divider />
            {renderPopoverMenuItem(
              "Delete Message",
              <Delete />,
              message.owner === currentUser.id,
              handleDeleteDialog
            )}
            {renderPopoverMenuItem(
              "Report Message",
              <Report />,
              false, //message.owner !== currentUser.id,
              undefined
            )}
            {renderPopoverMenuItem(
              "Edit Message",
              <Edit />,
              !disableMessageEdit,
              () => {
                setShowMessageEditModal(true);
              }
            )}
            <Divider />
            {renderPopoverMenuItem("Close", <Close />, true, undefined)}
          </MenuList>
        </Paper>
      </Popover>
    );
  };

  const renderEditedTag = () => {
    if (!isEdited) {
      return;
    }

    let lastModifiedDate;
    message.historicalMessages.map((prevMessage) => {
      if (
        !lastModifiedDate ||
        new Date(prevMessage.modifiedUtc).getTime() > lastModifiedDate.getTime()
      ) {
        lastModifiedDate = new Date(prevMessage.modifiedUtc);
      }
    });
    return (
      <Tag
        label={"Edited"}
        date={lastModifiedDate}
        styles={{ backgroundColor: theme.palette.message.tag.bgScheduled }}
      />
    );
  };

  const renderTags = () => {
    if (!item.messageTagSet && !isEdited) {
      return false;
    }

    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <DateTag
          messageTagSet={item.messageTagSet}
          label={"planner"}
          companyId={accountDetails.axial.company.id}
          tagType={"companyTags"}
          sectionName={MessageTagOptions.PlannerTag.Name}
          sectionKVDate={MessageTagOptions.PlannerTag.KVDate}
          sectionKVCompany={MessageTagOptions.PlannerTag.KVCompany}
          sectionKVEnabled={MessageTagOptions.PlannerTag.KVEnabled}
          styles={{ backgroundColor: theme.palette.message.tag.bgPlanner }}
        />
        <DateTag
          messageTagSet={item.messageTagSet}
          label={"reminder"}
          mode={"datetime"}
          tagType={"userTags"}
          sectionName={MessageTagOptions.ReminderTag.Name}
          sectionKVDate={MessageTagOptions.ReminderTag.KVDate}
          sectionKVEnabled={MessageTagOptions.ReminderTag.KVEnabled}
          styles={{ backgroundColor: theme.palette.message.tag.bgReminder }}
        />
        <DateTag
          messageTagSet={item.messageTagSet}
          label={"scheduled"}
          mode={"datetime"}
          tagType={"userTags"}
          sectionName={MessageTagOptions.ScheduleTag.Name}
          sectionKVDate={MessageTagOptions.ScheduleTag.KVDate}
          sectionKVEnabled={MessageTagOptions.ScheduleTag.KVEnabled}
          styles={{ backgroundColor: theme.palette.message.tag.bgScheduled }}
        />
        <ReactionTag
          messageTagSet={item.messageTagSet}
          handleReactionRemove={handleReactionRemove}
        />
        {renderEditedTag()}
      </Box>
    );
  };

  const getAvatarInitials = (createdById) => {
    const user = findUser(userList, createdById);
    return user === undefined
      ? ""
      : (user.firstName.length > 0
          ? user.firstName.substring(0, 1).toUpperCase()
          : "") +
          (user.lastName.length > 0
            ? user.lastName.substring(0, 1).toUpperCase()
            : "");
  };

  const getDisplayName = (createdById) => {
    const user = findUser(userList, createdById);
    return user === undefined
      ? ""
      : (
          (user.firstName.length > 0 ? user.firstName : "") +
          " " +
          (user.lastName.length > 0 ? user.lastName : "")
        ).trim();
  };

  const markUnread = () => {
    if (
      message.createdDate <= lastViewTime ||
      message.owner === currentUser.id
    ) {
      return;
    }
    updateMessagesRead();
  };

  const updateMessagesRead = async () => {
    if (message === undefined) {
      return;
    }

    // Newly added or dynamic may not exist in member list
    const spaceMember = activeSpace.members.find((member) => {
      return member.memberId === currentUser.id;
    });
    if (spaceMember) {
      // Update date
      spaceMember.lastAccessed = message.createdDate;
    } else {
      activeSpace.members.push({
        ...SpaceMemberStruct,
        id: Guid(),
        memberId: currentUser.id,
        doShow: true,
        lastAccessed: message.createdDate,
        messageSpaceId: activeSpace.id,
      });
    }
    updateLastViewTime(moment.utc(message.createdDate));
    setShowHighlightedNew(false);
    await handleSpaceMessagesRead(message, currentUser);
    await api.markMsgNotificationsAsRead(activeSpace.id);

    updateRecord(message.id);
  };

  const handleMessageSchedule = (scheduleTagSet) => {
    const newUserTag = scheduleTagSet.userTags.map((userTag) => {
      return { ...userTag, messageTagSetMessageRecordId: item.id };
    });

    const findScheduled = scheduleTagSet.userTags.find(
      (tag) =>
        tag.name === MessageTagOptions.ScheduleTag.Name &&
        tag.createdBy === currentUser.id
    );
    const updatedDate =
      findScheduled &&
      findScheduled.metaData.find(
        (kv) => kv.key === MessageTagOptions.ScheduleTag.KVDate
      ).value;

    const otherTags = messageTagSet.userTags.filter(
      (tag) => tag.name !== MessageTagOptions.ScheduleTag.Name
    );

    const updatedMessage = {
      ...item,
      createdUtc: new Date(updatedDate),
      messageTagSet: {
        ...messageTagSet,
        userTags: [...otherTags, ...newUserTag],
      },
    };

    updateMessage(updatedMessage);
    setShowScheduleMsgModal(false);
  };

  const showUnread = () => {
    if (
      message.createdDate <= lastViewTime ||
      message.owner === currentUser.id
    ) {
      return <></>;
    }
    return (
      <Grid item>
        <Circle
          sx={{
            color: theme.palette.message.unread,
            fontSize: "12px",
            marginLeft: "3px",
            marginRight: "6px",
          }}
        />
      </Grid>
    );
  };

  const showNewDivider = (propHighlight = false, stateHighlight = false) => {
    // console.log(`Message ${message.createdDate} - [${propHighlight} | ${stateHighlight}] - Last View = ${lastViewTime}`);
    if (
      (stateHighlight && propHighlight) ||
      (!stateHighlight && propHighlight)
    ) {
      return (
        <Grid item sx={{ width: "100%" }}>
          <Divider
            sx={{
              color: theme.palette.message.unread,
              fontSize: FontSizes.RegularText,
            }}
          >
            New
          </Divider>
        </Grid>
      );
    }
    return <></>;
  };

  const renderMessageContent = (
    propHighlight = false,
    stateHighlight = false
  ) => {
    const displayName = getDisplayName(message.owner);
    const messageDate = message.createdDate;
    return (
      <>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          sx={{ marginTop: "2px" }}
        >
          {showNewDivider(propHighlight, stateHighlight)}
          <Grid item>
            <Avatar
              variant="rounded"
              sx={{
                backgroundColor: StringToColor(displayName, prefersDarkMode),
              }}
              alt={displayName}
            >
              {getAvatarInitials(message.owner)}
            </Avatar>
          </Grid>
          <Grid item sx={{ marginLeft: "5px", maxWidth: "90%" }}>
            <Grid
              container
              onMouseEnter={() => {
                setShowOptionsButton(true);
                markUnread();
              }}
              onMouseLeave={() => setShowOptionsButton(false)}
            >
              <Grid
                item
                container
                direction="row"
                sx={{ alignItems: "flex-start", marginY: "0px" }}
              >
                <Grid item>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: FontSizes.HeadingLabel,
                      color: StringToColor(displayName, prefersDarkMode),
                    }}
                  >
                    {displayName}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      color: "text.secondary",
                      fontSize: FontSizes.RecordDateText,
                      marginX: "5px",
                      marginTop: "2px",
                      paddingX: "5px",
                    }}
                  >
                    {messageDateFormat(messageDate)}
                  </Typography>
                </Grid>
                {showUnread()}
                <Grid
                  item
                  sx={{
                    visibility: showOptionsButton ? "visible" : "hidden",
                    cursor: "pointer",
                    height: "24px",
                  }}
                >
                  <MoreVert
                    sx={{ size: "10px" }}
                    onClick={handleOptionsDialogClick}
                  />
                </Grid>
              </Grid>
              <Grid item container direction="row">
                <Grid
                  xs="auto"
                  item
                  container
                  direction="column"
                  style={{
                    display: "flex",
                    width: "fit-content",
                    marginY: 0,
                    paddingY: 0,
                  }}
                >
                  <Grid
                    item
                    sx={{ paddingY: "0px", fontSize: FontSizes.RegularText }}
                  >
                    <MessageTag
                      key={`${messageDate.getTime()}-${recordIndex}`}
                      objectData={message.content}
                      itemIndex={recordIndex}
                      messageTime={messageDate.getTime()}
                      loadedCallback={(isTagLoaded = false) =>
                        handleTagCallback(isTagLoaded)
                      }
                    />
                    {renderDeleteDialog(false, displayName)}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                container
                direction="row"
                sx={{ alignItems: "flex-start" }}
              >
                <Grid item>{renderTags()}</Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  const renderSkeleton = () => {
    return (
      <>
        <ListItem>
          <Box>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              sx={{ marginTop: "2px" }}
            >
              <Grid item>
                <Skeleton height={75}>
                  <Avatar variant="rounded" />
                </Skeleton>
              </Grid>
              <Grid item sx={{ marginLeft: "5px", maxWidth: "90%" }}>
                <Grid container>
                  <Grid
                    item
                    container
                    direction="row"
                    sx={{ alignItems: "flex-start", marginY: "0px" }}
                  >
                    <Grid item>
                      <Skeleton width={100} height={30}>
                        <Typography />
                      </Skeleton>
                    </Grid>
                    <Grid item>
                      <Skeleton width={80} height={30} sx={{ marginX: "15px" }}>
                        <Typography />
                      </Skeleton>
                    </Grid>
                  </Grid>
                  <Grid item container direction="row">
                    <Grid
                      xs="auto"
                      item
                      container
                      direction="column"
                      style={{
                        display: "flex",
                        width: "fit-content",
                        marginBottom: 0,
                        paddingBottom: 0,
                        marginTop: 0,
                        paddingTop: 0,
                      }}
                    >
                      <Grid
                        item
                        sx={{
                          paddingY: "0px",
                          fontSize: FontSizes.RegularText,
                        }}
                      >
                        <Skeleton variant="rounded" width={300} height={50} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </ListItem>
      </>
    );
  };

  const renderMessageRecord = (
    propHighlight = false,
    stateHighlight = false
  ) => {
    if (activeSpace.id === "") {
      return renderSkeleton();
    }
    if (!message) {
      console.log(`Message Not Loaded`);
      return null;
    }
    return (
      <>
        <ListItem
          id={`ele${item.id}`}
          key={`li-${item.id}`}
          ref={messageRef}
          disableGutters
          sx={{
            p: 0,
            m: 0,
            width: "95%",
            display: "inline-block",
            "&:last-child": { pb: "16px" },
          }}
        >
          <Box
            key={`box-${item.id}`}
            sx={{
              pl: "0px",
              pr: "0px",
              py: "4px",
              display: "flex",
              flexGrow: 1,
              flexDirection: "row",
            }}
          >
            {renderMessageContent(propHighlight, stateHighlight)}
            {renderPopover()}
            <CalendarPlannerModal
              show={showDailyPlannerModal}
              hideModal={() => setShowDailyPlannerModal(false)}
              onSave={handlePlannerCalendarSave}
            />
            <CalendarReminderModal
              show={showReminderModal}
              hideModal={() => setShowReminderModal(false)}
              onSave={handleReminderCalendarSave}
            />
            <ScheduleMessageModal
              show={showScheduleMsgModal}
              hideModal={() => setShowScheduleMsgModal(false)}
              onSave={handleMessageSchedule}
            />
            <EditMessageModal
              message={item}
              show={showMessageEditModal}
              hideModal={() => setShowMessageEditModal(false)}
              onSave={(newMessage) => {
                handleMessageEdit(newMessage);
              }}
            />
            {renderEmojiPicker()}
          </Box>
        </ListItem>
      </>
    );
  };

  return renderMessageRecord(highlightUnread, showHighlightedNew);
}

function areEqual(prevProps, nextProps) {
  // Need to make sure when reactions are added, we refresh the message
  // console.log(`Before - ${JSON.stringify(prevProps)}\r\nAfter - ${JSON.stringify(nextProps)}`);
  return !nextProps.shouldUpdate;
}

export default React.memo(MessageRecord, areEqual);
