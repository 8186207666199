import ReactPlayer from "react-player/youtube";
import {
  ComponentRef,
  getComponentRef,
  getFieldNumber,
  getFieldString,
  isObject,
  renderMessageText,
} from "./MessageTagUtil";
import { Box, Typography } from "@mui/material";
import {useEffect} from "react";

const MessageYouTubeTagDataTemplate = {
  title: "",
  height: 170,
  videoId: "",
  caption: "",
};

export const MessageYouTubeTagTemplate = {
  component: "MessageYouTubeTag",
  data: { ...MessageYouTubeTagDataTemplate },
};

/**
 * {
 *     "component": "MessageYouTubeTag",
 *     "data": {
 *          "title": "Office Space",
 *          "height": 200,
 *          "link": "https://youtu.be/N9wsjroVlu8",
 *          "caption": "... legendary!"
 *     }
 * }
 */
export function MessageYouTubeTag(props) {
  const { objectData, handleSubComponentLoaded = (subComponent = {...ComponentRef}, isLoaded = false) => {}, messageTime, itemIndex, level } = props;

  useEffect(() => {
    handleSubComponentLoaded(getComponentRef("MessageYouTubeTag [YouTube]", itemIndex, level, messageTime), true);
  }, []);

  const getVideoId = (link) => {
    if (!link) return "";
    const regex = /[a-zA-Z\d_-]{11}$/g;
    const result = link.match(regex);
    return typeof result === "object" ? result[result.length - 1] : result;
  };

  const renderVideoObject = (data = MessageYouTubeTagDataTemplate) => {
    const link = objectData.link;
    // Check to see if we have a video id or link
    const videoId = objectData.videoId ? objectData.videoId : getVideoId(link);

    //const youTubeRef = `https://www.youtube.com/embed/${videoId}?autoplay=0&controls=0&showinfo=0&playsinline=1`;

    const title = getFieldString(objectData.title);
    const caption = getFieldString(objectData.caption);
    const height = getFieldNumber(objectData.height, 170);
    if (videoId) {
      return (
        <>
          {title.length > 0 ? (
            <Typography
              key={`youtube-title-${messageTime}-${itemIndex}-${level}`}
              variant="h5"
            >
              {title}
            </Typography>
          ) : null}
          <ReactPlayer
            wrapper="div"
            key={`youtube-player-${messageTime}-${itemIndex}-${level}`}
            url={`https://www.youtube.com/embed/${videoId}`}
            config={{
              youtube: {
                playerVars: {
                  playsInline: 1,
                },
              },
            }}
            width={Math.round((16 / 9) * height)}
            height={height}
            playsinline={true}
          />
          {caption.length > 0 ? (
            <Box
              key={`youtube-caption-${messageTime}-${itemIndex}-${level}`}
              sx={{ width: "100%", textAlign: "right" }}
            >
              <Typography variant="caption">{caption}</Typography>
            </Box>
          ) : null}
        </>
      );
    }
    return <></>;
  };

  const renderTag = () => {
    if (isObject(objectData)) {
      return renderVideoObject(objectData);
    }
    renderMessageText(objectData)
  }

  return renderTag();
}
