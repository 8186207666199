import React, {useEffect} from "react";
import { PropTypes } from "prop-types";
import { Box, Typography } from "@mui/material";
import { FontSizes } from "../../constants";
import {ComponentRef, getComponentRef} from "../../message/components/records/MessageTagUtil";

const Paragraph = ({ id, label, value, handleSubComponentLoaded = (subComponent = {...ComponentRef}, isLoaded = false) => {},
                   itemIndex, level, messageTime }) => {

    useEffect(() => {
        handleSubComponentLoaded(getComponentRef("Paragraph", itemIndex, level, messageTime), true);
    }, []);

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Typography id={id} sx={{ fontWeight: "bold", fontSize: FontSizes.HeadingLabel }}>
        {label}
      </Typography>
      <Typography sx={{ marginBottom: 1, fontSize: FontSizes.RegularText }} id={id}>
        {value}
      </Typography>
    </Box>
  );
};

Paragraph.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
};

export default Paragraph;
