import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ImageList,
  ImageListItem,
  Typography,
} from "@mui/material";
import { AccountDetailsContext } from "../../models/AccountDetailsContext";
import { SpaceContext } from "../../contexts/SpaceContext";
import { getFileNameExtension } from "../../../util/FilesHelper";
import { api } from "../../../../App";
import { MessageImageTagTemplate } from "../records/MessageImageTag";

const FileModal = ({ show, hideModal, onSave }) => {
  const { accountDetails } = useContext(
    AccountDetailsContext.AccountDetailsContext
  );
  const { activeSpace } = useContext(SpaceContext.ActiveSpaceContext);

  const [images, setImages] = useState([]);

  const handleOnSave = () => {
    images.map(async (img) => {
      const { file, base64 } = img;
      const filename = file.name;

      const attachmentPayload = {
        companyId: `${accountDetails.axial.company.id}`,
        accountId: `${accountDetails.axial.account.id}`,
        spaceId: `${activeSpace.id}`,
        name: filename,
        extension: getFileNameExtension(filename),
        contentData: base64.split(",")[1],
      };

      const res = await api.uploadMessageDocument(attachmentPayload);
      if (res.status === 200) {
        const imageLink = res.data.result.link;
        const tag = {
          ...MessageImageTagTemplate,
          data: {
            link: imageLink,
            requiresToken: true,
          },
        };
        onSave(`${filename}-${file.size}`, tag);
      }
    });
    cleanForm();
    handleOnClose();
  };

  const handleOnClose = () => {
    hideModal();
  };

  const cleanForm = () => {
    setImages([]);
  };

  const handleOnFilesSelection = (files) => {
    const filesArray = Array.from(files);
    filesArray.map((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        setImages((prevState) => [
          ...prevState,
          {
            file,
            src: URL.createObjectURL(file),
            base64: reader.result,
            link: "",
          },
        ]);
      };
      reader.readAsDataURL(file);
    });
  };

  return (
    <Dialog open={show} onClose={handleOnClose} maxWidth="md" fullWidth={true}>
      <DialogTitle>{`Image Selector`}</DialogTitle>
      <DialogContent>
        <Box>
          <input
            type={"file"}
            accept=".jpg,.png,.jpeg"
            onChange={(evt) => handleOnFilesSelection(evt.target.files)}
            multiple
          />
        </Box>
        <Box sx={{ border: "1px solid #212121", marginTop: 2, padding: 1 }}>
          {images.length > 0 ? (
            <ImageList
              sx={{ width: "100%", height: 450 }}
              cols={3}
              rowHeight={164}
            >
              {images.map((img) => (
                <ImageListItem key={img.file.name}>
                  <img
                    src={`${img.src}`}
                    style={{ width: 164, height: 164, objectFit: "contain" }}
                    alt={img.file.name}
                    loading="lazy"
                  />
                </ImageListItem>
              ))}
            </ImageList>
          ) : (
            <Box
              sx={{
                height: 450,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography>{"No images selected"}</Typography>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={cleanForm}
          disabled={images.length === 0}
        >
          {"Clear"}
        </Button>
        <Button
          variant="contained"
          onClick={handleOnSave}
          disabled={images.length === 0}
        >
          {"Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

FileModal.propTypes = {
  show: PropTypes.bool,
  hideModal: PropTypes.func,
  onSave: PropTypes.func,
};

export default FileModal;
