export const MessageTagModel = {
  id: "",
  messageTagSetMessageRecordId: "",
  name: "",
  createdOn: 0, // timestamp
  createdBy: "",
  metaData: [{ id: "", messageTagId: "", key: "", value: "" }],
};

export const MessageTagSetModel = {
  systemTags: [MessageTagModel],
  accountTags: [MessageTagModel],
  companyTags: [MessageTagModel],
  userTags: [MessageTagModel],
  reactionTags: [MessageTagModel],
  atTags: [MessageTagModel],
};

export const MessageTagOptions = {
  AtTag: {
    Name: "at-person",
  },
  ReactionTag: {
    Name: "reaction",
    KVReaction: "emoji",
  },
  PlannerTag: {
    Name: "planner",
    KVEnabled: "enabled",
    KVDate: "display-date",
    KVCompany: "company-id",
  },
  TagForLaterTag: {
    Name: "tag-for-later",
    KVEnabled: "enabled",
  },
  ReminderTag: {
    Name: "reminder",
    KVEnabled: "enabled",
    KVDate: "reminder-date",
  },
  ScheduleTag: {
    Name: "scheduled",
    KVEnabled: "enabled",
    KVDate: "scheduled-date",
  },
};

export const HistoricalMessageModel = {
  id: "",
  messageRecordId: "",
  message: "",
  modifiedUtc: new Date(),
};

export const MessageRecordModel = {
  id: "",
  createdUtc: new Date(),
  accountId: "",
  companyId: "",
  spaceId: "",
  createdById: "",
  message: "",
  isVisible: false,
  historicalMessages: [{ ...HistoricalMessageModel }],
  messageTagSet: MessageTagSetModel,
};

export const MessageRecordListModel = {
  accountId: "",
  companyId: "",
  spaceId: "",
  messageList: [{ ...MessageRecordModel }],
};
