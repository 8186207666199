import React, { useContext, useEffect, useState } from "react";
import { Box, CircularProgress, Dialog } from "@mui/material";
import { Buffer } from "buffer";
import {
  ComponentRef,
  getComponentRef,
} from "../../message/components/records/MessageTagUtil";
import { useTheme } from "@emotion/react";
import { AccountDetailsContext } from "../../message/models/AccountDetailsContext";
import axios from "axios";
import { proxyGetOptionsWithToken } from "../../util/ProxyOptions";
import { Check, Error, FileOpen } from "@mui/icons-material";
import { SpaceContext } from "../../message/contexts/SpaceContext";
import { getFileNameExtension } from "../../util/FilesHelper";
import { Guid } from "../../util/Helpers";
import { api } from "../../../App";

const IMAGE_STATE = {
  UNSELECTED: "UNSELECTED",
  UPLOADING: "UPLOADING",
  DOWNLOADING: "DOWNLOADING",
  COMPLETED: "COMPLETED",
  FAILED: "FAILED",
};

const FileUploadInput = ({
  label,
  id,
  readOnly,
  placeholderText,
  required,
  onChange,
  multiline,
  value = "",
  isImageOnly,
  extension,
  handleSubComponentLoaded = (
    subComponent = { ...ComponentRef },
    isLoaded = false
  ) => {},
  itemIndex,
  level,
  messageTime,
}) => {
  const theme = useTheme();

  const { accountDetails } = useContext(
    AccountDetailsContext.AccountDetailsContext
  );
  const { activeSpace } = useContext(SpaceContext.ActiveSpaceContext);

  const [internalValue, setInternalValue] = useState(value);
  const [fileData, setFileData] = useState();
  const [imageStatus, setImageStatus] = useState(IMAGE_STATE.UNSELECTED);
  const [showBigImage, setShowBigImage] = useState(false);

  const handleOnChange = (newValue, extension) => {
    setInternalValue(newValue);
    onChange(id, newValue, extension);
  };

  const uploadImageContent = async (filePayload) => {
    setImageStatus(IMAGE_STATE.UPLOADING);
    const response = await api.uploadMessageDocument(filePayload);

    const downloadLink = response.data.result.link;
    setImageStatus(IMAGE_STATE.COMPLETED);
    handleOnChange(downloadLink, filePayload.extension);
  };

  const handleOnInputChange = (value) => {
    if (value[0]) {
      const reader = new FileReader();
      reader.readAsDataURL(value[0]);
      reader.onload = ({ target: { result } }) => {
        const extension = getFileNameExtension(value[0].name);

        if (isImageOnly && !isImage(extension)) {
          setImageStatus(IMAGE_STATE.FAILED);
          return;
        }

        const base64Data = result.split(",")[1];
        const filePayload = {
          companyId: `${accountDetails.axial.company.id}`,
          accountId: `${accountDetails.axial.account.id}`,
          spaceId: `${activeSpace.id}`,
          name: `file-${Guid()}.${extension}`,
          extension: extension,
          contentData: base64Data,
        };

        uploadImageContent(filePayload);
      };
    }
  };

  const getFileData = () => {
    const imageLink = `${accountDetails.apiUrl}${internalValue}`;
    axios
      .get(imageLink, proxyGetOptionsWithToken(accountDetails.token))
      .then((response) => {
        const contentType = response.headers["content-type"];
        const imageBase64Data = Buffer.from(response.data).toString("base64");
        const imageLink = `data:${contentType};charset=utf-8;base64,${imageBase64Data}`;
        setFileData(imageLink);
      });
  };

  const isImage = (fileExtension) => {
    const _extension = fileExtension ? fileExtension : extension;
    return (
      _extension &&
      (_extension === "png" || _extension === "jpg" || _extension === "jpeg")
    );
  };

  useEffect(() => {
    if (readOnly && internalValue) {
      getFileData();
    }
  }, [internalValue]);

  useEffect(() => {
    if (value) {
      setInternalValue(value);
    }
  }, [value]);

  useEffect(() => {
    handleSubComponentLoaded(
      getComponentRef("FileUploadInput", itemIndex, level, messageTime),
      true
    );
  }, []);

  const renderReadOnly = () => {
    if (!internalValue || internalValue.length === 0) {
      return null;
    }
    
    return (
      <>
        <p>{`${label}: `}</p>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {isImage() ? (
            fileData && (
              <>
                <img
                  alt=""
                  height={120}
                  width={120}
                  src={fileData}
                  onClick={() => setShowBigImage(true)}
                />
                {showBigImage && (
                  <Dialog
                    open={showBigImage}
                    onClose={() => setShowBigImage(false)}
                  >
                    <img
                      alt=""
                      height={"100%"}
                      width={"100%"}
                      src={fileData}
                      onClick={() => setShowBigImage(false)}
                    />
                  </Dialog>
                )}
              </>
            )
          ) : (
            <a
              href={fileData}
              download={"file"}
              target="_blank"
              rel="noreferrer"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FileOpen style={{ width: 40, height: 40, marginRight: 4 }} />
              <p>Download file</p>
            </a>
          )}
        </Box>
      </>
    );
  };

  const renderElement = () => {
    if (readOnly) {
      return renderReadOnly();
    }

    return (
      <Box>
        <label style={{ padding: 5 }}>{`${label}: `}</label>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <input
            type="file"
            accept={isImageOnly ? ".png, .jpg, .jpeg" : "*"}
            onChange={({ target: { files } }) => handleOnInputChange(files)}
          />
          <Box>
            {imageStatus === IMAGE_STATE.UPLOADING && (
              <CircularProgress
                color="error"
                size={20}
                style={{ marginLeft: 10 }}
              />
            )}
            {imageStatus === IMAGE_STATE.COMPLETED && <Check color="success" />}
            {imageStatus === IMAGE_STATE.FAILED && (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Error color="error" />
                <label style={{ marginLeft: 5 }}>Is image only</label>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    );
  };

  return renderElement();
};

export default FileUploadInput;
