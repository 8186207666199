import {createContext} from 'react';

import {SpaceHeadings, SpaceStruct} from "../data/SpaceData";

const ActiveSpace = createContext({
    activeSpace: {...SpaceStruct}, setActiveSpace: (space) => {
    }
});

const SpaceList = createContext({
    spaceList: [{...SpaceStruct}], spaceListDispatch: (state, action) => {
    }
});

const SpaceHeadingList = createContext({
    spaceHeadings: SpaceHeadings, setSpaceHeadings: (heading) => {
    }
});

export const SpaceContext = {
    ActiveSpaceContext: ActiveSpace,
    SpaceListContext: SpaceList,
    SpaceHeadingsContext: SpaceHeadingList
};
