import React, {useEffect} from "react";
import {Divider} from "@mui/material";
import {ComponentRef, getComponentRef} from "../../message/components/records/MessageTagUtil";

const LineBreak = ({ id, handleSubComponentLoaded = (subComponent = {...ComponentRef}, isLoaded = false) => {},
                       itemIndex, level, messageTime }) => {

    useEffect(() => {
        handleSubComponentLoaded(getComponentRef("LineBreak", itemIndex, level, messageTime), true);
    }, []);

  return <>
      <Divider  sx={{marginY: "5px"}} />
  </>;
};

export default LineBreak;
