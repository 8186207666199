import React from "react";
import Header from "./Header";
import LineBreak from "./LineBreak";
import TextInput from "./TextInput";
import SingleSelectInput from "./SingleSelectInput";
import Paragraph from "./Paragraph";
import CheckBoxInput from "./CheckBoxInput";
import DateTimeInput from "./DateTimeInput";
import DateInput from "./DateInput";
import TimeInput from "./TimeInput";
import FileUploadInput from "./FileUploadInput";

const getElementToRender = (settings, onChange, initialValue) => {
  if (settings === undefined) {
    console.error("No Element Settings Found");
  }

  switch (settings && settings.formElement) {
    case "Header":
      return (
        <Header {...settings} onChange={onChange} initialValue={initialValue} />
      );
    case "LineBreak":
      return (
        <LineBreak
          {...settings}
          onChange={onChange}
          initialValue={initialValue}
        />
      );
    case "TextInput":
      return (
        <TextInput
          {...settings}
          onChange={onChange}
          initialValue={initialValue}
        />
      );
    case "SingleSelectInput":
      return (
        <SingleSelectInput
          {...settings}
          onChange={onChange}
          initialValue={initialValue}
        />
      );
    // case 'MultiSelectInput':
    //   return <MultiSelectInput {...settings} onChange={onChange} initialValue={initialValue} />;
    case "Paragraph":
      return (
        <Paragraph
          {...settings}
          onChange={onChange}
          initialValue={initialValue}
        />
      );
    case "CheckBox":
      return (
        <CheckBoxInput
          {...settings}
          onChange={onChange}
          initialValue={initialValue}
        />
      );
    case "DateTimeInput":
      return (
        <DateTimeInput
          {...settings}
          onChange={onChange}
          initialValue={initialValue}
        />
      );
    case "DateInput":
      return (
        <DateInput
          {...settings}
          onChange={onChange}
          initialValue={initialValue}
        />
      );
    case "TimeInput":
      return (
        <TimeInput
          {...settings}
          onChange={onChange}
          initialValue={initialValue}
        />
      );
    case "FileUploadInput":
      return (
        <FileUploadInput
          {...settings}
          onChange={onChange}
          initialValue={initialValue}
        />
      );
    default:
      console.log("Missing Element Definition", settings.formElement);
      return null;
  }
};

export { getElementToRender };
