export const IsTimeWithinHours = (utcStart, hours) => {
  const now = new Date();
  let diff = (now.getTime() - utcStart.getTime()) / 1000;
  diff /= 60 * 60;
  return Math.abs(Math.round(diff)) <= hours;
};

export const StringToColor = (string, useDarkMode = true) => {
  const minDarkness = useDarkMode ? 128 : 32;

  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = minDarkness + ((hash >> (i * 8)) & 0x7f);
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */
  return color;
};

export const InvertColor = (hex, bw) => {
  if (hex.indexOf("#") === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    throw new Error("Invalid HEX color.");
  }
  let r = parseInt(hex.slice(0, 2), 16),
    g = parseInt(hex.slice(2, 4), 16),
    b = parseInt(hex.slice(4, 6), 16);
  if (bw) {
    // https://stackoverflow.com/a/3943023/112731
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#000000" : "#FFFFFF";
  }
  // invert color components
  r = (255 - r).toString(16);
  g = (255 - g).toString(16);
  b = (255 - b).toString(16);
  // pad each with zeros and return
  return "#" + padZero(r) + padZero(g) + padZero(b);
};

const padZero = (str, len) => {
  len = len || 2;
  const zeros = new Array(len).join("0");
  return (zeros + str).slice(-len);
};

export const GetTruncatedName = (name, allowedLength) => {
  if (name.length > allowedLength) {
    return name.substring(0, allowedLength - 2) + String.fromCharCode(0x02026);
  }
  return name;
};

export const checksum = (srcString = "") => {
  let chk = 0x12345678;
  const len = srcString.length;
  for (let i = 0; i < len; i++) {
    chk += (srcString.charCodeAt(i) * (i + 1));
  }
  return (chk & 0xffffffff).toString(16);
}

export const Guid = () => {
  // the 4 is fixed and the y must be numeric 8, 9, a, b
  const GUID_PATTERN = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx";
  return GUID_PATTERN.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0; // eslint-disable-line no-bitwise
    const v = c === "x" ? r : (r & 0x3) | 0x8; // eslint-disable-line no-bitwise
    return v.toString(16);
  });
};

/**
 * Checks if any of the data has a match with the ids.
 * @param {Array} firstArray Array of data to be searched (requires id).
 * @param {Array} secondArray Array of ids to match.
 */
export const checkAnyMatchInArrays = (firstArray, secondArray, key = "id") => {
  if (!firstArray || !secondArray) {
    return false;
  }
  for (let i = 0; i < firstArray.length; i++) {
    for (let j = 0; j < secondArray.length; j++) {
      if (firstArray[i][key] === secondArray[j]) {
        return true;
      }
    }
  }
  return false;
};
