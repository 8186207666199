import { useContext } from "react";
import { useNavigate } from "react-router";

import {
  Box,
  ButtonBase,
  Chip,
  Grid,
  IconButton,
  Skeleton,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import logoWhite from "../../../../images/axial-shift-logo-horizontal-white.png";
import logoBlack from "../../../../images/axial-shift-logo-horizontal-black.png";

import { KeyboardArrowLeft } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import SettingsIcon from "@mui/icons-material/Settings";
import SearchIcon from "@mui/icons-material/Search";

import { EdgeTrigger } from "@mui-treasury/layout";

import { UserContext } from "../../models/UserContext";
import { SpaceContext } from "../../contexts/SpaceContext";
import { AccountDetailsContext } from "../../models/AccountDetailsContext";
import { GetTruncatedName } from "../../../util/Helpers";
import { AppContext } from "../../contexts/AppContext";
import { SpaceStruct } from "../../data/SpaceData";

function MessageHeaderContainer(props) {
  const { accountDetails } = useContext(
    AccountDetailsContext.AccountDetailsContext
  );
  const { showSearchScreen, setShowSearchScreen } = useContext(
    AppContext.ShowSearchScreenContext
  );
  const { currentUser } = useContext(UserContext.UserContext);
  const { activeSpace, setActiveSpace } = useContext(
    SpaceContext.ActiveSpaceContext
  );

  const navigate = useNavigate();

  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const logo = prefersDarkMode ? logoWhite : logoBlack;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const { data } = props;

  const gotoSettings = () => {
    navigate("/settings");
  };

  const goToSearch = () => {
    setActiveSpace(SpaceStruct);
    setShowSearchScreen(true);
  };

  const renderChannelName = (isMatch = false) => {
    if (isMatch) {
      if (activeSpace.id === "") {
        return <Skeleton variant="rounded" width={75} height={"15"} />;
      }
      return (
        <Chip
          color="primary"
          variant="outlined"
          size="small"
          label={GetTruncatedName(activeSpace.name, 14)}
        />
      );
    }
    return null;
  };

  return (
    <>
      <Toolbar sx={{ position: "sticky", top: 0 }}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container direction="row" alignItems="center">
            {data.header ? (
              <EdgeTrigger target={{ anchor: "left", field: "open" }}>
                {(open, setOpen) => (
                  <Grid item xs={1}>
                    <Box
                      sx={{
                        justifyContent: "flex-end",
                        display: "flex",
                        ml: 2,
                      }}
                    >
                      <IconButton onClick={() => setOpen(!open)} edge="end">
                        {open ? <KeyboardArrowLeft /> : <MenuIcon />}
                      </IconButton>
                    </Box>
                  </Grid>
                )}
              </EdgeTrigger>
            ) : (
              <EdgeTrigger target={{ anchor: "left", field: "open" }}>
                {(open, setOpen) => (
                  <Grid item xs={1}>
                    <Box
                      sx={{
                        justifyContent: "flex-end",
                        display: "flex",
                        ml: 2,
                      }}
                    >
                      <IconButton onClick={() => setOpen(!open)} edge="end">
                        {open ? <KeyboardArrowLeft /> : <MenuIcon />}
                      </IconButton>
                    </Box>
                  </Grid>
                )}
              </EdgeTrigger>
            )}
            <Grid
              item
              xs
              sx={{
                flexGrow: 1,
                justifyContent: "flex-start",
                display: "flex",
              }}
            >
              {!matches ? (
                <>
                  {currentUser.isAdmin ? (
                    <ButtonBase sx={{ mr: 2 }} onClick={gotoSettings}>
                      <SettingsIcon />
                    </ButtonBase>
                  ) : null}
                  <ButtonBase sx={{ mr: 2 }} onClick={goToSearch}>
                    <SearchIcon />
                  </ButtonBase>
                  <Typography variant="h5">
                    {accountDetails.axial.account.name}
                  </Typography>
                </>
              ) : null}
              {renderChannelName(matches)}
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{
                  width: "100%",
                  justifyContent: "flex-end",
                  display: "flex",
                }}
              >
                <Box
                  component="img"
                  src={logo}
                  sx={{
                    height: "auto",
                    width: "100%",
                    maxWidth: "400px",
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Toolbar>
    </>
  );
}

export default MessageHeaderContainer;
