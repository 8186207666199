import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { NearMe } from "@mui/icons-material";
import MessageTag from "../records/MessageTag";
import { AppContext } from "../../contexts/AppContext";
import { UserListContext, findUser } from "../../models/UserListContext";
import { StringToColor } from "../../../util/Helpers";
import { FontSizes } from "../../../constants";
import { getMessageObject, isCustomForm } from "../records/MessageTagUtil";
import { FormRenderer } from "../../../CustomForms";

const EditMessageModal = ({ show, hideModal, message, onSave }) => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const { snackbar, setSnackbar } = useContext(AppContext.SnackbarContext);
  const { userList } = useContext(UserListContext.UserListContext);

  const formRendererRef = useRef();
  const [newMessage, setNewMessage] = useState(message.message);
  const [msgContentObj, setMsgContentObj] = useState();
  const [formData, setFormData] = useState([]);

  const isSendDisabled = useMemo(() => {
    return message.message === newMessage || newMessage === "";
  }, [newMessage]);

  const isRegularMessage = useMemo(() => {
    if (message && message.message) {
      return !isCustomForm(message.message);
    }
    return false;
  }, [message]);

  const displayName = useMemo(() => {
    const user = findUser(userList, message.createdById);
    return user === undefined
      ? ""
      : (
          (user.firstName.length > 0 ? user.firstName : "") +
          " " +
          (user.lastName.length > 0 ? user.lastName : "")
        ).trim();
  }, [message, userList]);

  const avatarInitials = useMemo(() => {
    const user = findUser(userList, message.createdById);
    return user === undefined
      ? ""
      : (user.firstName.length > 0
          ? user.firstName.substring(0, 1).toUpperCase()
          : "") +
          (user.lastName.length > 0
            ? user.lastName.substring(0, 1).toUpperCase()
            : "");
  }, [message, userList]);

  const messageDate = useMemo(() => {
    return message.createdUtc ? new Date(message.createdUtc) : new Date();
  }, [message]);

  const handleOnCustomFormSave = () => {
    if (formRendererRef) {
      const formSaveResult = formRendererRef.current.isFormValid();

      if (!formSaveResult.isValid) {
        setSnackbar({
          ...snackbar,
          open: true,
          severity: "error",
          message: "Some fields missing!",
        });
        return;
      }
    }

    const mergedResults = getMergedFormTemplateAndValues();
    const prevCustomForm = msgContentObj.data.content.find(
      ({ component }) =>
        component === "MessageLogTag" || component === "MessageStaffNoteTag"
    );
    const otherContent = msgContentObj.data.content.filter(
      ({ component }) =>
        component !== "MessageLogTag" && component !== "MessageStaffNoteTag"
    );

    const newMessageContent = {
      ...msgContentObj,
      data: {
        content: [
          ...otherContent,
          {
            component: prevCustomForm.component,
            data: {
              ...prevCustomForm.data,
              formData: mergedResults,
            },
          },
        ],
      },
    };

    const updatedMessage = {
      ...message,
      message: JSON.stringify(newMessageContent),
      previousMessage: message.message,
      isEdited: true, //Flag set to make validation in api. This value won't be saved on DB object.
    };
    onSave(updatedMessage);

    setSnackbar({
      ...snackbar,
      open: true,
      severity: "success",
      message: "Message updated!",
    });
  };

  const handleOnMessageUpdate = () => {
    const updatedMessage = {
      ...message,
      message: newMessage,
      previousMessage: message.message,
      isEdited: true, //Flag set to make validation in api. This value won't be saved on DB object.
    };
    onSave(updatedMessage);

    setSnackbar({
      ...snackbar,
      open: true,
      severity: "success",
      message: "Message updated!",
    });
  };

  const handleOnClose = () => {
    setNewMessage(undefined);
    hideModal();
  };

  const getMergedFormTemplateAndValues = () => {
    const formValues = formRendererRef.current.getFormData();
    const mergedResults = formData.map((templateEntry) => {
      const formValueData = formValues.find(
        (formEntry) => templateEntry.id === formEntry.id
      );

      return {
        ...templateEntry,
        value: formValueData?.value,
        valueLabel: formValueData?.valueLabel,
      };
    });

    return mergedResults;
  };

  useEffect(() => {
    if (message && message.message) {
      const json = getMessageObject(message.message);
      if (json) {
        const { component, data } = json;
        if (component === "MessageCompositeTag" && data.content.length > 0) {
          const messageData = data.content.find(
            (value) =>
              value.component === "MessageStaffNoteTag" ||
              value.component === "MessageLogTag"
          );
          if (messageData) {
            setMsgContentObj(json);
            setFormData(messageData.data.formData);
          }
        }
      }
    }
  }, [message]);

  const renderRegularMessageEdit = () => {
    return (
      <>
        <Paper
          elevation={3}
          sx={{
            display: "flex",
            marginBottom: "5%",
            overflowY: "auto",
            flexWrap: "nowrap",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            minHeight: 100,
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent={"center"}
            alignItems="center"
            sx={{ marginTop: "2px" }}
          >
            <Grid item>
              <Avatar
                variant="rounded"
                sx={{
                  backgroundColor: StringToColor(displayName, prefersDarkMode),
                }}
                alt={displayName}
              >
                {avatarInitials}
              </Avatar>
            </Grid>
            <Grid item sx={{ marginLeft: "5px", maxWidth: "90%" }}>
              <Grid container>
                <Grid
                  item
                  container
                  direction="row"
                  sx={{ alignItems: "flex-start", marginY: "0px" }}
                >
                  <Grid item>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: FontSizes.HeadingLabel,
                        color: StringToColor(displayName, prefersDarkMode),
                      }}
                    >
                      {displayName}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container direction="row">
                  <Grid
                    xs="auto"
                    item
                    container
                    direction="column"
                    style={{
                      display: "flex",
                      width: "fit-content",
                      marginY: 0,
                      paddingY: 0,
                    }}
                  >
                    <Grid
                      item
                      sx={{ paddingY: "0px", fontSize: FontSizes.RegularText }}
                    >
                      <MessageTag
                        key={`${messageDate.getTime()}`}
                        objectData={message.message}
                        messageTime={messageDate.getTime()}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Box
          sx={{
            display: "flex",
            position: "relative",
            //marginX: `${getMargin()}`,
            overflowY: "visible",
            borderRadius: "30px",
            backgroundColor: "background.paperDark",
            minWidth: 400,
          }}
        >
          <div
            style={{
              fontSize: 17,
              minHeight: 38,
              fontWeight: "normal",
              lineHeight: 17,
            }}
          >
            <textarea
              rows={1}
              style={{
                display: "block",
                position: "absolute",
                padding: 9,
                paddingLeft: "10%",
                top: 0,
                left: 0,
                bottom: 0,
                margin: 0,
                height: 38,
                resize: "none",
                border: "1px solid transparent",
                outline: "none",
                backgroundColor: "transparent",
                fontFamily: "inherit",
                fontSize: "inherit",
                letterSpacing: "inherit",
                width: "90%",
                boxSizing: "border-box",
                overflowY: "hidden",
              }}
              onChange={({ target: { value } }) => {
                setNewMessage(value);
              }}
              value={newMessage}
            />
          </div>
          <Box
            sx={{
              display: "flex",
              marginLeft: "auto",
              backgroundColor: "primary.main",
              borderRadius: 10,
            }}
          >
            <IconButton
              onClick={handleOnMessageUpdate}
              size={"medium"}
              disabled={isSendDisabled}
            >
              <NearMe fontSize={"small"} />
            </IconButton>
          </Box>
        </Box>
      </>
    );
  };

  const renderCustomFormEdit = () => {
    if (!formData) {
      return;
    }

    return (
      <>
        {formData.length > 0 && (
          <FormRenderer formTemplate={formData} ref={formRendererRef} />
        )}
        {formData.length > 0 && (
          <Button variant="contained" onClick={handleOnCustomFormSave}>
            {"Save"}
          </Button>
        )}
      </>
    );
  };

  return (
    <Dialog open={show} onClose={handleOnClose} maxWidth="md">
      <DialogTitle>{`Edit Message`}</DialogTitle>
      <DialogContent>
        {isRegularMessage ? renderRegularMessageEdit() : renderCustomFormEdit()}
      </DialogContent>
    </Dialog>
  );
};

EditMessageModal.propTypes = {
  show: PropTypes.bool,
  hideModal: PropTypes.func,
  message: PropTypes.object,
  onSave: PropTypes.func,
};

export default EditMessageModal;
