import {SignalRConnection} from "./SignalRConnection";
import {SpaceOperations} from "../data/SpaceOperations";
import {isDevelopment} from "../../../App";

export const SpaceConnection = (baseUrl, dispatchers) => {
    const HUB_NAME = "spaces";

    const signalRConnection = SignalRConnection(baseUrl, HUB_NAME);

    const getJsonObject = (postData) => {
        return JSON.parse(postData);
    }

    signalRConnection.then((connection) => {

        connection.on("messageRecordAdd", responseData => {
            if(isDevelopment) {
                console.log("Message added: [" + responseData + "]");
            }
            dispatchers.spaceList({type: SpaceOperations.ADD_MESSAGE,ref: getJsonObject(responseData)});
        });

        connection.on("messageSpaceDelete", responseData => {
            if(isDevelopment) {
                console.log("Space to be deleted: [" + responseData + "]");
            }
            dispatchers.spaceList({type: SpaceOperations.DELETE_SPACE, ref: getJsonObject(responseData)});
        });

        connection.on("messageSpaceUpdate", responseData => {
            if(isDevelopment) {
                console.log("Space to be updated: [" + responseData + "]");
            }
            dispatchers.spaceList({type: SpaceOperations.UPDATE_SPACE, ref: getJsonObject(responseData)});
        });

        connection.on("messageSpaceAdd", responseData => {
            if(isDevelopment) {
                console.log("Space to be added: [" + responseData + "]");
            }
            dispatchers.spaceList({type: SpaceOperations.ADD_SPACE, ref: getJsonObject(responseData)});
        });

        connection.on("spaceAddMember", responseData => {
            if(isDevelopment) {
                console.log("Space to be mapped : [" + responseData + "]");
            }
            dispatchers.spaceList({
                type: SpaceOperations.MAP_USER,
                ref: {mapInfo: getJsonObject(responseData)}
            });
        });

        connection.on("spaceRemoveMember", responseData => {
            if(isDevelopment) {
                console.log("Space to be unmapped : [" + responseData + "]");
            }
            dispatchers.spaceList({
                type: SpaceOperations.UNMAP_USER,
                ref: {mapInfo: getJsonObject(responseData)}
            });
        });
    });

    return signalRConnection;
}
