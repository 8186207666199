import { useContext, useState, useEffect } from "react";

import { SpaceContext } from "../../contexts/SpaceContext";

import { Box, List, Paper, Stack, useMediaQuery } from "@mui/material";

import { useTheme } from "@mui/material";

import MessageSpaceSection from "./MessageSpaceSection";
import SpaceTypes from "../../data/SpaceTypes";

function MessageSpaceSectionContainer(props) {
  const { collapsed, handleSidebar, sidebarState } = props;

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const [useCollapsedContent, setUseCollapsedContent] = useState(false);

  const { spaceHeadings } = useContext(SpaceContext.SpaceHeadingsContext);

  useEffect(() => {
    setUseCollapsedContent(collapsed && matches);
  }, [matches, collapsed]);

  const getCollapsedContent = () => {
    return <></>;
  };

  const getFullContent = () => {
    return (
      <>
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}
        >
          <Paper sx={{ overflowY: "auto", width: 1 }}>
            <List component="nav" dense={true}>
              {spaceHeadings.map((spaceHeading) => {
                if (spaceHeading.id !== SpaceTypes.DYNAMIC_GROUP) {
                  return (
                    <MessageSpaceSection
                      key={spaceHeading.id}
                      spaceHeading={spaceHeading}
                      sidebarState={sidebarState}
                      handleSidebar={handleSidebar}
                    />
                  );
                }
              })}
            </List>
          </Paper>
        </Stack>
      </>
    );
  };

  return (
    <Box sx={{ height: 0, width: 1 }}>
      {useCollapsedContent ? getCollapsedContent() : getFullContent()}
    </Box>
  );
}

export default MessageSpaceSectionContainer;
