import React from "react";
import PropTypes from "prop-types";
import { Box, CircularProgress } from "@mui/material";

const CircularLoading = ({ show, size }) => {
  return (
    <>
      {show && (
        <Box
          sx={{
            display: "flex",
            height: "5vh",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            mt: 2,
          }}
        >
          <CircularProgress color={"error"} size={size} />
        </Box>
      )}
    </>
  );
};

CircularLoading.propTypes = {
  show: PropTypes.bool,
  size: PropTypes.number,
};

export default CircularLoading;
