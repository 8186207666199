import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { api } from "../../../../App";
import { FormRenderer } from "../../../CustomForms";
import { Guid } from "../../../util/Helpers";
import { SpaceContext } from "../../contexts/SpaceContext";
import { AxiosErrorHandler } from "../../../util/AxiosHelper";

const LogEntryModal = ({
  show,
  formData,
  formRendererRef,
  isMissingFields,
  hideModal,
  setFormData,
  onSave,
}) => {
  const [allLogTypes, setAllLogTypes] = useState([]);
  const [logTypes, setLogTypes] = useState([]);
  const [selectedLogType, setSelectedLogType] = useState("");

  const { activeSpace } = useContext(SpaceContext.ActiveSpaceContext);

  const handleOnSave = () => {
    const formTemplateAndValues = getMergedFormTemplateAndValues();
    onSave(formTemplateAndValues);
    handleOnClose();
  };

  const handleOnClose = () => {
    cleanForm();
    hideModal();
  };

  const cleanForm = () => {
    setSelectedLogType("");
    setFormData([]);
  };

  const getMergedFormTemplateAndValues = () => {
    const formValues = formRendererRef.current.getFormData();
    const mergedResults = formData.map((templateEntry) => {
      const formValueData = formValues.find(
        (formEntry) => templateEntry.id === formEntry.id
      );

      let newFormEntry = {
        ...templateEntry,
        value: formValueData?.value,
        valueLabel: formValueData?.valueLabel,
      };
      if (formValueData.extension) {
        newFormEntry = {
          ...newFormEntry,
          extension: formValueData.extension,
        };
      }

      return newFormEntry;
    });

    return {
      id: Guid(),
      logTypeId: selectedLogType,
      logTypeName: logTypes.find((t) => t.value === selectedLogType)?.label,
      formData: mergedResults,
    };
  };

  const fetchCustomFormTemplate = (logType) => {
    const loader = async () => {
      await api.fetchCustomFormTemplateData(logType).then(
        (response) => setFormData(JSON.parse(response.data.result)),
        (error) => AxiosErrorHandler(error)
      );
    };
    loader().then(
      () => console.log(`Loaded Log Types`),
      (error) => console.log(`An error occurred loading log types - ${error}`)
    );
  };

  const getLogTypes = () => {
    const loader = async () => {
      await api.getDayOfBusinessLogTypes().then(
        (response) => setAllLogTypes(response.data.result.logTypes),
        (error) => AxiosErrorHandler(error)
      );
    };
    loader().then(
      () => console.log(`Loaded Log Types`),
      (error) => console.log(`An error occurred loading log types - ${error}`)
    );
  };

  useEffect(() => {
    if (activeSpace.id !== "" && allLogTypes.length > 0) {
      const _logTypes = allLogTypes.filter((logType) => {
        if (logType.hasRestrictions === false) {
          return true;
        }
        return logType.channelIds.includes(activeSpace.id);
      });
      setLogTypes(_logTypes);
    }
  }, [activeSpace, allLogTypes]);

  useEffect(() => {
    if (selectedLogType !== "") {
      fetchCustomFormTemplate(selectedLogType);
    }
  }, [selectedLogType]);

  /**
   * Loads custom form if just one log type loaded.
   */
  useEffect(() => {
    if (show && logTypes.length === 1) {
      setSelectedLogType(logTypes[0].value);
    }
  }, [show, logTypes]);

  useEffect(() => {
    getLogTypes();
  }, []);

  return (
    <Dialog open={show} onClose={handleOnClose} maxWidth="md" fullWidth={true}>
      <DialogTitle>{`New Log Entry`}</DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <div style={{ color: "red", paddingBottom: "1rem" }}>
            {isMissingFields ? "Please Fill Out All Required Fields" : ""}
          </div>
          <FormControl sx={{ justifySelf: "center", margin: 1 }}>
            <InputLabel>Log Type</InputLabel>
            <Select
              label={"Log Type"}
              value={selectedLogType}
              onChange={(e) => setSelectedLogType(e.target.value)}
              fullWidth
            >
              {logTypes.map(({ label, value }) => {
                return (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {formData.length > 0 && (
            <FormRenderer formTemplate={formData} ref={formRendererRef} />
          )}
          {formData.length > 0 && (
            <Button variant="contained" onClick={handleOnSave}>
              {"Save"}
            </Button>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

LogEntryModal.propTypes = {
  show: PropTypes.bool,
  formData: PropTypes.array,
  formRendererRef: PropTypes.any,
  hideModal: PropTypes.func,
  setFormData: PropTypes.func,
  setAttachedTags: PropTypes.func,
  onSave: PropTypes.func,
};

export default LogEntryModal;
