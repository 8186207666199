import { createContext } from "react";
import { SnackBarModel } from "../models/AppDataModel";

const ShowLoading = createContext({
  showLoading: false,
  setShowLoading: (showLoading) => {},
});

const ShowSnackbar = createContext({
  snackbar: { ...SnackBarModel },
  setSnackbar: (snackbar) => {},
});

const ShowSearchScreen = createContext({
  showSearchScreen: false,
  setShowSearchScreen: (showSearchScreen) => {},
});

export const AppContext = {
  ShowLoadingContext: ShowLoading,
  SnackbarContext: ShowSnackbar,
  ShowSearchScreenContext: ShowSearchScreen,
};
