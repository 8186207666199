import { createContext } from "react";

export const AccountDetailsStruct = {
  baseUrl: "",
  apiUrl: "",
  token: "",
  accountAccessKey: "access-key-number-1",
  axial: {
    account: {
      id: "",
      name: "",
    },
    company: {
      id: "",
      name: "",
    },
    user: {
      id: "",
      firstName: "",
      lastName: "",
    },
  },
  accounts: [],
  companies: [],
};

const AccountDetails = createContext(AccountDetailsStruct);

export const AccountDetailsContext = {
  AccountDetailsContext: AccountDetails,
};
