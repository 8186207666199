import {useContext, useState} from "react";

import {Button, ListItem, ListItemText, Typography} from "@mui/material";

import {SpaceOperationTask} from "../../controllers/SpaceController";
import {SpaceOperations} from "../../data/SpaceOperations";
import {AccountDetailsContext} from "../../models/AccountDetailsContext";
import {UserContext} from "../../models/UserContext";
import {SpaceContext} from "../../contexts/SpaceContext";
import {cleanSearchText} from "../../../util/RegexUtil";
import {canShowMember} from "../../controllers/UserController";

export default function InviteSpace(props) {
    const user = props.user;
    const selectedSpaceRef = props.selectedSpaceRef;
    const userSearchText = props.userSearchText;

    const {accountDetails} = useContext(AccountDetailsContext.AccountDetailsContext);
    const {currentUser} = useContext(UserContext.UserContext);
    const {spaceList} = useContext(SpaceContext.SpaceListContext);

    const [isDisabled, setDisabled] = useState(false);

    const mapUser = (user, space) => {
        console.log("Before Member Add => " + JSON.stringify(space));
        SpaceOperationTask(SpaceOperations.ADD_SPACE_MEMBER, {space: space, user: user});
        console.log("After Member Add => " + JSON.stringify(space));
        SpaceOperationTask(SpaceOperations.SEND_UPDATE_SPACE, {
            accountDetails: accountDetails,
            space: space
        });
    };

    const getSpace = (spaceId, spaceList) => {
        return spaceList.find((space) => space.id === spaceId);
    }

    const isSpaceMember = (user, checkSpace, spaceList) => {
        if (checkSpace === undefined) return false;
        if (user === undefined) return false;
        return spaceList.find((space) => checkSpace.id === space.id).members.find((member) => member.memberId === user.id) !== undefined;
    }

    const getRender = () => {
        const search = cleanSearchText(userSearchText);
        if (user.id !== currentUser.id &&
            canShowMember(currentUser, user) &&
            !isSpaceMember(user, getSpace(selectedSpaceRef, spaceList), spaceList) &&
            user.displayName.toLowerCase().match(search !== undefined ? search : "")) {
            return <ListItem>
                <Button
                    variant="contained"
                    size="small"
                    disabled={isDisabled}
                    sx={{borderRadius: 3, mr: 3}}
                    onClick={() => mapUser(user, getSpace(selectedSpaceRef, spaceList))}>
                    <Typography>Invite</Typography>
                </Button>
                <ListItemText>{user.displayName}</ListItemText>
            </ListItem>;
        }
        return <></>;
    }

    return (getRender());
}