import {useContext, useState} from "react";

import {SpaceContext} from "../../contexts/SpaceContext";
import SpaceTypes from "../../data/SpaceTypes";

import { AccountDetailsContext } from "../../models/AccountDetailsContext";
import {UserContext} from "../../models/UserContext";
import {UserListContext} from "../../models/UserListContext";
import {unmapUser} from "../../data/SpaceActions"

import InviteSpace from "./InviteSpace";

import {
    Box,
    Button, Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    MenuItem, Select,
    TextField,
    Typography,
    ListItem,
    ListItemText,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {cleanSearchText, makePrettySpaceName} from "../../../util/RegexUtil";
import {canShowMember} from "../../controllers/UserController";

export default function PrivateSpaceEditor(props) {
    const isEditOpen = props.isOpen;
    const toggleEditDialog = props.toggleOpen;

    const [selectedSpaceRef, setSelectedSpaceRef] = useState("");
    const [channelMembersSearchText, setchannelMembersSearchText] = useState("");
    const [userSearchText, setUserSearchText] = useState("");
    const { accountDetails } = useContext(
        AccountDetailsContext.AccountDetailsContext
    );

    const {currentUser} = useContext(UserContext.UserContext);
    const {spaceList} = useContext(SpaceContext.SpaceListContext);
    const {userList} = useContext(UserListContext.UserListContext);

    const resetDialog = () => {
        setchannelMembersSearchText("")
        setSelectedSpaceRef("");
        setUserSearchText("");
        toggleEditDialog();
    }

    const handleSearchText = (searchText) => {
        setUserSearchText(searchText);
    };

    const handleChannelMembersSearchText = (searchText) => {
        setchannelMembersSearchText(searchText);
    };

    const isSearchMatched = (rawSearch, textToMatch) => {
        const search = cleanSearchText(rawSearch);
        return (
          textToMatch.toLowerCase().match(search !== undefined ? search : "") !==
          null
        );
    };

    const findSpace = () => {
        return spaceList.find(space => space.id === selectedSpaceRef);
    }

    const getSpaceMembers = (spaceId) => {
        const returnSpaceMembers = [];
        spaceList.find(space => space.id === spaceId).members
            .forEach((member) => {
                if (member.doShow) {
                    const found = returnSpaceMembers.find(foundMember => foundMember.memberId === member.memberId);
                    if (!found) {
                        const user = userList.find(user => user.id === member.memberId);
                        if (user) {
                            returnSpaceMembers.push(user);
                        }
                    }
                }
            });
        return returnSpaceMembers;
    }

    const removeMember = (memberId) => {
        unmapUser(accountDetails, findSpace(), {id: memberId});
    }

    const getUserListItem = (user) => {
        if (user.id === currentUser.id) {
          return null;
        }
        const labelId = user.id;
        return (
            <ListItem key={user.id}>
                <Button
                    style={{ backgroundColor: "#DC143C" }}
                    variant="contained"
                    size="small"
                    disabled={false}
                    sx={{borderRadius: 3, mr: 3}}
                    onClick={() => removeMember(labelId)}>
                    <Typography>Remove</Typography>
                </Button>
                <ListItemText>{user.displayName}</ListItemText>
            </ListItem>
        );
      };

    return (
        <Dialog open={isEditOpen}
                onClose={() => toggleEditDialog()}
                scroll="paper"
                fullWidth
        >
            <DialogTitle id="scroll-dialog-title">Channel Editor</DialogTitle>
            <DialogContent dividers={true}>
                <Typography>Select a Channel</Typography>
                <Select id="space-select" label="Select a Channel" value={selectedSpaceRef}
                        onChange={(e) => setSelectedSpaceRef(e.target.value)} sx={{minWidth: 240}}>
                    {spaceList.map((space) => {
                        if (space.messageSpaceType === SpaceTypes.PRIVATE && space.members.find((member) => member.memberId === currentUser.id) !== undefined) {
                            return <MenuItem key={space.id}
                                             value={space.id}>{makePrettySpaceName(space.name)}</MenuItem>
                        }
                        return null
                    })}
                </Select>
                    {selectedSpaceRef.length > 0 ? <> 
                    <Box
                        sx={{ display: "flex", alignItems: "flex-end", height: "56px" }}
                            >
                    </Box>
                    <Box
                        sx={{
                        width: "100%",
                        height: "250px",
                        overflow: "auto",
                        overflowY: "auto",
                        }}
                    >
                    <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
                        <SearchIcon sx={{color: 'action.active', mr: 1, my: 0.5}}/>
                        <TextField id="filter-spaces" label="Filter Channel Members ..." variant="standard"
                                value={channelMembersSearchText}
                                onChange={(e) => handleChannelMembersSearchText(e.target.value)}/>
                    </Box>
                    <List dense={true}>
                        {getSpaceMembers(selectedSpaceRef).map((user) =>
                            isSearchMatched(channelMembersSearchText, user.displayName) ? getUserListItem(user) : null
                        )}
                    </List>
                </Box>
                </> : <></>}
                {selectedSpaceRef.length > 0 ? <Box
                sx={{
                    width: "100%",
                    height: "250px",
                    overflow: "auto",
                    overflowY: "auto",
                    }}>
                    <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
                        <SearchIcon sx={{color: 'action.active', mr: 1, my: 0.5}}/>
                        <TextField id="filter-spaces" label="Filter Members ..." variant="standard"
                                value={userSearchText}
                                onChange={(e) => handleSearchText(e.target.value)}/>
                    </Box>
                    <List dense={true}>
                        {userList.map((user) => canShowMember(currentUser, user) ?
                            <InviteSpace key={user.id} user={user} selectedSpaceRef={selectedSpaceRef}
                                        userSearchText={userSearchText}/> : null)}
                    </List>
                </Box> : <></>}
            </DialogContent>
            <DialogActions>
                <Button onClick={resetDialog} variant="contained">Close</Button>
            </DialogActions>
        </Dialog>
    );
}
