/**
 * Blank response data
 * @type {{result: {},
 * warningMessages: *[],
 * errorMessages: *[],
 * transient: null,
 * messages: *[],
 * infoMessages: *[],
 * hasWarning: boolean,
 * hasError: boolean,
 * allMessages: *[],
 * statusCode: number}}
 */
export const AxialResponseData = {
    transient: null,
    result: {},
    statusCode: 200,
    messages: [],
    hasWarning: false,
    hasError: false,
    infoMessages: [],
    warningMessages: [],
    errorMessages: [],
    allMessages: []
}

/**
 * Axial Company Response
 * value = id
 * @type {{name: string, label: string, value: string}}
 */
export const AxialCompany = {
    value: "",
    label: "",
    name: ""
}

export const AxialTokenResponse = {
    accessToken: "",
    expiresIn: 0,
    accountId: "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
    noActiveAccounts: false,
    isFreeTier: false,
    mustChooseAccount: false,
    accountsToChooseFrom: [],
    companies: [AxialCompany],
    sendToDashboard: false
}