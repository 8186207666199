/**
 * API Services available
 * @type {{string}}
 */
export const ApiEndPoints = {
  TOKEN: `token`,
  SESSION_STATUS: `status/session`,
  COMPANY_USERS: "employees/companyUsers",
  ACCOUNT_USERS: "employees/accountUsers",
};
