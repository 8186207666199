import { useContext, useEffect, useMemo, useRef, useState } from "react";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  SpaceOperationTask,
  validateSpaceName,
} from "../../controllers/SpaceController";
import { SpaceOperations } from "../../data/SpaceOperations";

import { AccountDetailsContext } from "../../models/AccountDetailsContext";
import { UserContext } from "../../models/UserContext";
import SpaceTypes from "../../data/SpaceTypes";
import { api } from "../../../../App";
import CircularLoading from "../loading/CircularLoading";
import AddIcon from "@mui/icons-material/Add";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import SwitchField from "../../../MaterialForms/SwitchField";
import { NEW_GUID } from "../../../constants";
import MultipleSelect from "../../../MaterialForms/MultipleSelectField";
import { UserListContext } from "../../models/UserListContext";
import {
  canShowMember,
  isUserAdmin,
  isUserOwner,
} from "../../controllers/UserController";
import { checkAnyMatchInArrays } from "../../../util/Helpers";
import { AppContext } from "../../contexts/AppContext";

const GROUP_TYPES = [
  { label: "Private Channel", value: "PRIVATE" },
  { label: "Channel by Job", value: "DYNAMIC" },
];

export default function AddSpace(props) {
  const isOpen = props.isOpen;
  const toggleOpen = props.toggleOpen;
  const spaceHeading = props.spaceHeading;

  const { snackbar, setSnackbar } = useContext(AppContext.SnackbarContext);
  const { accountDetails } = useContext(
    AccountDetailsContext.AccountDetailsContext
  );
  const { currentUser } = useContext(UserContext.UserContext);
  const { userList } = useContext(UserListContext.UserListContext);

  const userSearchField = "displayName";
  const [userPickerList, setUserPickerList] = useState([]);

  const [spaceName, setSpaceName] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedGroupType, setSelectedGroupType] = useState("PRIVATE");

  //Dynamic group options
  const [companyPickerList, setCompanyPickerList] = useState([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState(NEW_GUID);
  const [jobList, setJobList] = useState([]);
  const [jobsPickerList, setJobsPickerList] = useState([]);
  const [selectedGroupId, setSelectedGroupId] = useState(NEW_GUID);
  const [selectedDynamicGroups, setSelectedDynamicGroups] = useState([]);
  const [showRestrictStoresSelect, setShowRestrictStoresSelect] =
    useState(false); // On true, shows select with companies for authorizedStoresIds.
  const [companiesList, setCompaniesList] = useState([]);
  const [selectedStores, setSelectedStores] = useState([]);
  const [showRestrictUsersSelect, setShowRestrictUsersSelect] = useState(false); // On true, shows select with users for restrictedUsersIds values.
  const [selectedAuthorizedUsers, setAuthorizedSelectedUsers] = useState([]);
  const [defaultAuthorizedCompanyUsers, setDefaultAuthorizedCompanyUsers] =
    useState([]);
  const [defaultAuthUsers, setDefaultAuthUsers] = useState([]);
  const [storesPicker, setStoresPicker] = useState([]);
  const [authorizedUserPicker, setAuthorizedUserPicker] = useState([]);

  // Authorized users search
  const [authorizedUserData, setAuthorizedUserData] = useState([]);
  const [authUsersSearchText, setAuthUsersSearchText] = useState("");

  const storesMultipleSelectRef = useRef();
  const usersMultipleSelectRef = useRef();

  const isAddButtonDisabled = useMemo(() => {
    if (isLoading || isSaving) return true;
    if (selectedGroupType === "PRIVATE") {
      return spaceName === "";
    } else {
      return (
        spaceName === "" ||
        selectedDynamicGroups.length === 0 ||
        (showRestrictStoresSelect && selectedStores.length === 0) ||
        (showRestrictUsersSelect && selectedAuthorizedUsers.length === 0)
      );
    }
  }, [
    spaceName,
    selectedGroupType,
    selectedDynamicGroups,
    showRestrictStoresSelect,
    selectedStores,
    showRestrictUsersSelect,
    selectedAuthorizedUsers,
    isLoading,
    isSaving,
  ]);

  /**
   * Sets the default authorized users for the selected company.
   */
  useEffect(() => {
    if (selectedCompanyId !== NEW_GUID) {
      let authorizedUsers = [];
      const authorizedUsersIds = defaultAuthorizedCompanyUsers.find(
        ({ companyId }) => companyId === selectedCompanyId
      ).authorizedUsers;
      authorizedUsersIds.map((userId) => {
        const _user = userPickerList.find((u) => u.id === userId);
        if (_user !== undefined) {
          authorizedUsers.push(_user);
        }
      });

      const res = authorizedUsers.map(({ id, displayName }) => ({
        value: id,
        label: displayName,
      }));
      setSelectedDynamicGroups([]);
      showRestrictStoresSelect &&
        storesMultipleSelectRef.current.clearSelectedItems();
      showRestrictUsersSelect &&
        usersMultipleSelectRef.current.clearSelectedItems();
      setJobsPickerList(jobList);
      setDefaultAuthUsers(res);
      setAuthorizedUserData(res);
      setAuthorizedUserPicker(res);
    }
  }, [selectedCompanyId]);

  /**
   * Filters the users for the selected jobs and add them into the authorizedUserPicker.
   */
  useEffect(() => {
    let _users = [...defaultAuthUsers];
    userPickerList.map((user) => {
      const alreadyAdded = _users.find((u) => u.value === user.id);
      if (!alreadyAdded) {
        if (
          checkAnyMatchInArrays(
            user.jobs,
            selectedDynamicGroups.map((job) => job.jobId)
          )
        ) {
          _users.push({ value: user.id, label: user.displayName });
        }
      }
    });
    setAuthorizedUserData(_users);
    setAuthorizedUserPicker(_users);
  }, [selectedDynamicGroups]);

  /**
   * Filters store picker with every added job.
   */
  useEffect(() => {
    if (selectedDynamicGroups.length !== 0) {
      let companies = [];
      selectedDynamicGroups.map(({ companyIdsJobExistsAt }) => {
        companyIdsJobExistsAt.map((companyId) => {
          const company = companiesList.find(
            (_company) => _company.key === companyId
          );
          const alreadyAdded =
            companies.find((u) => u.value === companyId) !== undefined;
          if (!alreadyAdded && company !== undefined) {
            companies.push({ value: company.key, label: company.value });
          }
        });
      });
      setStoresPicker(companies);
    } else {
      setStoresPicker([]);
    }
  }, [selectedDynamicGroups]);

  const getJobsPickerList = async (spaceType) => {
    setIsLoading(true);
    if (spaceType === "DYNAMIC" && jobsPickerList.length === 0) {
      const res = await api.getMessagingSpacesGroupContents();
      if (res.status === 200) {
        if (res.data.hasError) {
          console.log(res.data.errorMessages);
        } else {
          setJobList(res.data.result.jobs);
          setJobsPickerList(res.data.result.jobs);
          setCompanyPickerList(res.data.result.companies);
          setCompaniesList(res.data.result.companies);
          setDefaultAuthorizedCompanyUsers(
            res.data.result.defaultAuthorizedCompanyUsers
          );
        }
      } else {
        console.log(res.problem);
      }
    }
    setIsLoading(false);
  };

  const handleOnUserSearch = (text) => {
    setAuthUsersSearchText(text);
    const searchedData = authorizedUserData.filter(({ label }) => {
      const searchValue = text.toLowerCase().trim();
      return label.toLowerCase().includes(searchValue);
    });
    setAuthorizedUserPicker(searchedData);
  };

  const getSpaceType = (spaceType) => {
    if (spaceType === SpaceTypes.PRIVATE) {
      if (selectedGroupType === "DYNAMIC") {
        return SpaceTypes.DYNAMIC_GROUP;
      }
    }
    return spaceType;
  };

  const addNewSpace = (spaceName) => {
    setIsSaving(true);
    let payload = {
      accountDetails: accountDetails,
      members: [currentUser],
      spaceName: spaceName,
      spaceType: getSpaceType(spaceHeading.id),
      dynamicObjectTypeIds: selectedDynamicGroups.map((group) => group.jobId),
    };
    if (showRestrictStoresSelect) {
      //If restricted stores toggle on true, add selected stores. Only for dynamic groups.
      payload = {
        ...payload,
        authorizedStoresIds: selectedStores.map((store) => store.key),
      };
    }
    if (showRestrictUsersSelect) {
      payload = {
        ...payload,
        authorizedUsersIds: selectedAuthorizedUsers.map((user) => user.value),
      };
    }
    SpaceOperationTask(SpaceOperations.ADD_NEW_SPACE, payload);
    setSnackbar({
      ...snackbar,
      open: true,
      severity: "success",
      message: "New Space created!",
    });
    toggleOpen();
    setSpaceName("");
    setIsSaving(false);
  };

  const handleJobAdd = (masterJobList, jobId) => {
    if (jobId === undefined || jobId === NEW_GUID) {
      return;
    }
    const job = masterJobList.find((u) => u.jobId === jobId);
    const alreadyAdded =
      selectedDynamicGroups.find((u) => u.jobId === jobId) !== undefined;
    if (!alreadyAdded && job !== undefined) {
      setSelectedDynamicGroups((previous) => [...previous, job]);
      setJobsPickerList(jobsPickerList.filter((u) => job.jobId !== u.jobId));
    }
    setSelectedGroupId(NEW_GUID);
  };

  const handleJobRemove = (jobId) => {
    if (jobId === undefined || jobId === NEW_GUID) {
      return;
    }
    const job = jobList.find((u) => u.jobId === jobId);
    const filteredJobList = selectedDynamicGroups.filter(
      (u) => u.jobId !== jobId
    );
    setJobsPickerList((previous) => [...previous, job]);
    setSelectedDynamicGroups(filteredJobList);
  };

  const canShowUser = (userTest) => {
    return (
      currentUser.id !== userTest.id && canShowMember(currentUser, userTest)
    );
  };

  useEffect(() => {
    const users = [];
    userList.map((u) => {
      if (canShowUser(u) && !u.isSuperUser) {
        // callback filter function
        users.push(u);
      }
      return u;
    });
    users.sort((thisName, otherName) => {
      const thisSearchTerm = thisName[userSearchField].toString().toLowerCase();
      const otherSearchTerm = otherName[userSearchField]
        .toString()
        .toLowerCase();
      if (thisSearchTerm > otherSearchTerm) {
        // text property field
        return 1;
      }
      if (thisSearchTerm < otherSearchTerm) {
        // text property field
        return -1;
      }
      return 0;
    });
    setUserPickerList(users);
  }, [userList]);

  useEffect(() => {
    getJobsPickerList(selectedGroupType);
  }, [selectedGroupType]);

  const renderPrivateChannelForm = () => {
    return (
      <>
        <DialogContentText>
          Add a new channel for discussions. Make it meaningful
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Channel Name"
          value={spaceName}
          fullWidth
          variant="standard"
          onChange={(e) => setSpaceName(validateSpaceName(e.target.value))}
        />
      </>
    );
  };

  const renderDynamicChannelForm = () => {
    return (
      <>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Channel Name"
          value={spaceName}
          fullWidth
          variant="standard"
          onChange={(e) => setSpaceName(validateSpaceName(e.target.value))}
        />
        {!isLoading ? (
          <Box sx={{ width: 350 }}>
            <FormControl sx={{ marginTop: 2, marginBottom: 1 }} fullWidth>
              <InputLabel>Select Store</InputLabel>
              <Select
                label={"Select Store"}
                value={selectedCompanyId}
                onChange={(e) => setSelectedCompanyId(e.target.value)}
                fullWidth
              >
                {companyPickerList.map(({ key, value }) => {
                  return (
                    <MenuItem key={key} value={key}>
                      {value}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: 2,
                marginBottom: 1,
              }}
              fullWidth
            >
              <InputLabel>Select a Job</InputLabel>
              <Select
                label={"Select a Job"}
                value={selectedGroupId}
                onChange={(e) => setSelectedGroupId(e.target.value)}
                fullWidth
              >
                {jobsPickerList
                  .filter(
                    (t) =>
                      t.companyIdsJobExistsAt.filter(
                        (u) => u === selectedCompanyId
                      ).length > 0
                  )
                  .map((c) => (
                    <MenuItem key={c.jobId} value={c.jobId}>
                      {c.jobName}
                    </MenuItem>
                  ))}
              </Select>
              <IconButton
                sx={{
                  height: 20,
                  width: 20,
                  marginLeft: 2,
                }}
                onClick={() => handleJobAdd(jobList, selectedGroupId)}
              >
                <AddIcon color={"secondary"} />
              </IconButton>
            </FormControl>
            {selectedDynamicGroups.length > 0 && (
              <Box>
                <Typography>{`Selected Job Codes`}</Typography>
                <Box>
                  {selectedDynamicGroups.map((group) => (
                    <Box
                      key={group.jobId}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: 5,
                      }}
                    >
                      <IconButton onClick={() => handleJobRemove(group.jobId)}>
                        <IndeterminateCheckBoxIcon color={"error"} />
                      </IconButton>
                      <Typography
                        sx={{ marginLeft: 1 }}
                      >{`${group.jobName}`}</Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            )}
            <Box>
              <SwitchField
                label={"Restrict channel to specific stores?"}
                labelStyle={{ fontWeight: "bold" }}
                value={showRestrictStoresSelect}
                color={"primary"}
                toggle={() =>
                  setShowRestrictStoresSelect(!showRestrictStoresSelect)
                }
              />
              {showRestrictStoresSelect && (
                <MultipleSelect
                  ref={storesMultipleSelectRef}
                  data={storesPicker}
                  label={"Select a Store"}
                  onChange={(values) => setSelectedStores(values)}
                />
              )}
            </Box>
            <Box>
              <SwitchField
                label={"Restrict posting to specific people?"}
                labelStyle={{ fontWeight: "bold" }}
                value={showRestrictUsersSelect}
                color={"primary"}
                toggle={() =>
                  setShowRestrictUsersSelect(!showRestrictUsersSelect)
                }
              />
              {showRestrictUsersSelect && (
                <Box>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Search Authorized Users"
                    value={authUsersSearchText}
                    fullWidth
                    variant="standard"
                    onChange={(e) => handleOnUserSearch(e.target.value)}
                  />
                  <MultipleSelect
                    ref={usersMultipleSelectRef}
                    data={authorizedUserPicker}
                    label={"Select a User"}
                    onChange={(values) => setAuthorizedSelectedUsers(values)}
                  />
                </Box>
              )}
            </Box>
          </Box>
        ) : (
          <CircularLoading show={true} size={30} />
        )}
      </>
    );
  };

  return (
    <Dialog open={isOpen} onClose={() => toggleOpen()}>
      <DialogTitle>Add New Channel</DialogTitle>
      <DialogContent>
        {spaceHeading.id === SpaceTypes.PRIVATE ? (
          <FormControl sx={{ marginTop: 1, marginBottom: 1 }} fullWidth>
            <InputLabel>Channel Type</InputLabel>
            <Select
              label={"Group Type"}
              value={selectedGroupType}
              onChange={(e) => setSelectedGroupType(e.target.value)}
              fullWidth
            >
              {GROUP_TYPES.map(({ label, value }) => {
                if (
                  value === "DYNAMIC" &&
                  !currentUser.isSuperUser &&
                  !isUserAdmin(currentUser) &&
                  !isUserOwner(currentUser)
                ) {
                  return false;
                }
                return (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                );
              })}
            </Select>
            {selectedGroupType === "PRIVATE"
              ? renderPrivateChannelForm()
              : renderDynamicChannelForm()}
          </FormControl>
        ) : (
          renderPrivateChannelForm()
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => toggleOpen()} variant="contained">
          Cancel
        </Button>
        <Button
          onClick={() => addNewSpace(spaceName)}
          variant="contained"
          disabled={isAddButtonDisabled}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}
