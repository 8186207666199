import React, { useContext, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { api } from "../../../../App";
import { UserContext } from "../../models/UserContext";
import { SpaceContext } from "../../contexts/SpaceContext";
import { FormRenderer } from "../../../CustomForms";
import { Guid } from "../../../util/Helpers";
import { AxiosErrorHandler } from "../../../util/AxiosHelper";

const StaffNoteModal = ({
  show,
  formData,
  formRendererRef,
  isMissingFields,
  hideModal,
  setFormData,
  onSave,
}) => {
  const { currentUser } = useContext(UserContext.UserContext);
  const { activeSpace } = useContext(SpaceContext.ActiveSpaceContext);

  const [allStaffNoteTypes, setAllStaffNoteTypes] = useState([]);
  const [staffNoteTypes, setStaffNoteTypes] = useState([]);
  const [selectedStaffNoteType, setSelectedStaffNoteType] = useState("");

  //Staff Note Permissions.
  const hasLogBookPermission = useMemo(() => {
    const { permissions, isAdmin, isOwner } = currentUser;
    return permissions.logBook >= 1 || isAdmin || isOwner;
  }, [currentUser]);
  const canCreateLogBookStaffNoteTypes = useMemo(() => {
    const { permissions, isAdmin, isOwner } = currentUser;
    return permissions.logBook >= 3 || isAdmin || isOwner;
  }, [currentUser]);

  const handleOnSave = () => {
    const formTemplateAndValues = getMergedFormTemplateAndValues();
    onSave(formTemplateAndValues);
    handleOnClose();
  };

  const handleOnClose = () => {
    cleanForm();
    hideModal();
  };

  const cleanForm = () => {
    setSelectedStaffNoteType("");
    setFormData([]);
  };

  const getMergedFormTemplateAndValues = () => {
    const formValues = formRendererRef.current.getFormData();
    const mergedResults = formData.map((templateEntry) => {
      const formValueData = formValues.find(
        (formEntry) => templateEntry.id === formEntry.id
      );

      let newFormEntry = {
        ...templateEntry,
        value: formValueData?.value,
        valueLabel: formValueData?.valueLabel,
      };
      if (formValueData.extension) {
        newFormEntry = {
          ...newFormEntry,
          extension: formValueData.extension,
        };
      }

      return newFormEntry;
    });

    return {
      id: Guid(),
      staffNoteTypeId: selectedStaffNoteType,
      staffNoteTypeName: staffNoteTypes.find(
        (t) => t.value === selectedStaffNoteType
      )?.label,
      formData: mergedResults,
    };
  };

  const fetchCustomFormTemplate = (staffNoteType) => {
    const loader = async () => {
      await api
        .fetchCustomFormTemplateDataFromStaffNoteType(staffNoteType)
        .then(
          (response) => {
            const customFormResult = JSON.parse(response.data.result);

            const hasAnEmployeeSelectList = customFormResult.filter(
              (t) =>
                t.loadOptionsFromUrl &&
                t.optionsUrl === "/api/log-book/employees"
            );

            if (hasAnEmployeeSelectList.length === 0) {
              customFormResult.unshift({
                formElement: "SingleSelectInput",
                formElementDisplayName: "Single Drop Down",
                label: "Employee",
                placeholderText: "Choose an Employee...",
                icon: "fa fa-caret-square-o-down",
                required: true,
                canEditSettings: false,
                loadOptionsFromUrl: true,
                optionsUrl: "/api/log-book/employees",
                options: [],
              });
            }
            setFormData(customFormResult);
          },
          (error) => AxiosErrorHandler(error)
        );
    };
    loader().then(
      () => console.log(`Loaded Log Types`),
      (error) =>
        console.log(`An error occurred fetching custom forms - ${error}`)
    );
  };

  const getStaffNoteTypes = () => {
    const loader = async () => {
      await api.getDayOfBusinessStaffNoteTypes().then(
        (response) => setAllStaffNoteTypes(response.data.result.staffNoteTypes),
        (error) => AxiosErrorHandler(error)
      );
    };
    loader().then(
      () => console.log(`Loaded Log Types`),
      (error) =>
        console.log(`An error occurred loading staff note types - ${error}`)
    );
  };

  useEffect(() => {
    if (activeSpace.id !== "" && allStaffNoteTypes.length > 0) {
      const _staffNoteTypes = allStaffNoteTypes.filter((staffNoteType) => {
        if (staffNoteType.hasRestrictions === false) {
          return true;
        }
        return staffNoteType.channelIds.includes(activeSpace.id);
      });
      setStaffNoteTypes(_staffNoteTypes);
    }
  }, [activeSpace, allStaffNoteTypes]);

  useEffect(() => {
    if (selectedStaffNoteType !== "") {
      fetchCustomFormTemplate(selectedStaffNoteType);
    }
  }, [selectedStaffNoteType]);

  /**
   * Loads custom form if just one staff note type loaded.
   */
  useEffect(() => {
    if (show && staffNoteTypes.length === 1) {
      setSelectedStaffNoteType(staffNoteTypes[0].value);
    }
  }, [show, staffNoteTypes]);

  useEffect(() => {
    getStaffNoteTypes();
  }, []);

  return (
    <Dialog open={show} onClose={handleOnClose} maxWidth="md" fullWidth={true}>
      <DialogTitle>{`New Staff Note Entry`}</DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <div style={{ color: "red", paddingBottom: "1rem" }}>
            {isMissingFields ? "Please Fill Out All Required Fields" : ""}
          </div>
          <FormControl sx={{ justifySelf: "center", margin: 1 }}>
            <InputLabel>Staff Note Type</InputLabel>
            <Select
              label={"Staff Note Type"}
              value={selectedStaffNoteType}
              onChange={(e) => setSelectedStaffNoteType(e.target.value)}
              fullWidth
            >
              {staffNoteTypes.map(({ label, value }) => {
                return (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {!hasLogBookPermission && (
            <Box>
              <Typography>
                You do not have permission to add to the Log Book
              </Typography>
            </Box>
          )}
          {staffNoteTypes.length === 0 && (
            <Box sx={{ marginTop: 2 }}>
              <Typography style={{ fontWeight: "bold", color: "#726EFF" }}>
                No Log Book Staff Note Types Exist
              </Typography>
              {canCreateLogBookStaffNoteTypes && (
                <Button
                  style={{
                    border: "1px solid",
                    color: "white",
                    marginTop: 5,
                  }}
                  onClick={() =>
                    window.open(
                      "https://app.axialshift.com/log-book/staff-note-types"
                    )
                  }
                >
                  <Typography>Add Staff Note Types Here!</Typography>
                </Button>
              )}
              {!canCreateLogBookStaffNoteTypes && (
                <Typography>
                  Contact your admin to add a new staff note type.
                </Typography>
              )}
            </Box>
          )}
          {formData.length > 0 && (
            <FormRenderer formTemplate={formData} ref={formRendererRef} />
          )}
          {formData.length > 0 && (
            <Button variant="contained" onClick={handleOnSave}>
              {"Save"}
            </Button>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

StaffNoteModal.propTypes = {
  show: PropTypes.bool,
  formData: PropTypes.array,
  formRendererRef: PropTypes.any,
  isMissingFields: PropTypes.bool,
  hideModal: PropTypes.func,
  setFormData: PropTypes.func,
  onSave: PropTypes.func,
};

export default StaffNoteModal;
