import React, { Component } from "react";
import PropTypes from "prop-types";
import { Calendar } from "react-date-range";
import moment from "moment";
import { Input, InputLabel, InputAdornment, FormControl } from "@mui/material";
import { PermContactCalendar } from "@mui/icons-material";
import { Guid } from "../util/Helpers";

// Must of the code was copied from axial project, some minor changes had to be made to fix library and components
// issues. Styling and classname has to be checked, they may not exist in this project.
class DateField extends Component {
  containerRef;

  constructor(props) {
    super(props);

    let _date = moment({ hour: 0, minute: 0, seconds: 0 }).format("YYYY-MM-DD");

    let originalValue;
    let dateAlreadySelected = false;

    if (props.date) {
      _date = moment(props.date).format("YYYY-MM-DD");
      originalValue = _date;
      dateAlreadySelected = true;
    } else if (
      props.formField &&
      props.formField.value &&
      props.formField.value !== null
    ) {
      _date = moment(props.formField.value).format("YYYY-MM-DD");
      originalValue = _date;
      dateAlreadySelected = true;
    } else {
      originalValue = "";
    }

    let id;
    if (props.id) {
      id = props.id;
    } else if (props.label) {
      id = props.label.replace(/\s/g, "");
    } else {
      id = Guid();
    }

    this.state = {
      id: id,
      showContent: false,
      contentStyle: {
        display: "none",
      },
      date: _date,
      originalDateValue: originalValue,
      dateAlreadySelected: dateAlreadySelected,
    };

    this.label = `${moment(_date).format("YYYY/MM/DD")}`;
    this.showContent = this.showContent.bind(this);
    this.hideContent = this.hideContent.bind(this);
    this.toggleShowContent = this.toggleShowContent.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.containerRef && !this.containerRef.contains(event.target)) {
      this.hideContent();
    }
  }

  handleSelect(date) {
    const { formField, onChange } = this.props;
    let formattedDate;

    if (date) {
      formattedDate = moment(date).format("YYYY-MM-DDT00:00:00");
    } else {
      formattedDate = undefined;
    }

    if (formField) {
      formField.onChange(formattedDate);
    }

    if (onChange) {
      onChange(formattedDate);
    }

    this.setState({
      ...this.state,
      date: formattedDate,
      dateAlreadySelected: formattedDate != undefined,
      showContent: false,
      contentStyle: {
        display: "none",
      },
    });
  }

  renderHeader() {
    return (
      <div className="row" style={{ paddingBottom: ".1rem" }}>
        <div className="col-12">
          <div className="row mar-btm-sm">
            <div className="col-4">
              <button
                type="button"
                style={{ width: "100%" }}
                className="btn btn-danger"
                onClick={() => this.handleSelect(undefined)}
              >
                Clear
              </button>
            </div>
            <div className="col-4">
              <button
                type="button"
                style={{ width: "100%" }}
                className="btn btn-warning"
                onClick={() =>
                  this.handleSelect(
                    this.state.originalDateValue
                      ? moment(this.state.originalDateValue)
                      : undefined
                  )
                }
              >
                Reset
              </button>
            </div>
            <div className="col-4">
              <button
                type="button"
                style={{ width: "100%" }}
                className="btn btn-info"
                onClick={() =>
                  this.handleSelect(moment({ hour: 0, minute: 0, seconds: 0 }))
                }
              >
                Today
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { readOnly, maxDate, minDate, className, label } = this.props;

    let inputTextValue = moment(this.state.date).format("YYYY-MM-DD");
    if (!this.state.dateAlreadySelected) {
      inputTextValue = "";
    }

    return (
      <div
        className={["date-field", className]}
        ref={(c) => (this.containerRef = c)}
      >
        <input
          type="hidden"
          value={moment(this.state.date).format("YYYY-MM-DDT00:00:00")}
        />

        <FormControl fullWidth={true}>
          <InputLabel htmlFor={this.state.id}>{label}</InputLabel>
          <Input
            id={this.state.id}
            value={inputTextValue}
            disabled={readOnly}
            inputProps={{ onClick: () => this.toggleShowContent() }}
            startAdornment={
              <InputAdornment position="start">
                <PermContactCalendar />
              </InputAdornment>
            }
          />
        </FormControl>

        <div
          style={this.state.contentStyle}
          className="date-field-content current-content"
        >
          {this.renderHeader()}
          <div className="row">
            <div className="col-12">
              <Calendar
                onChange={(payload) => this.handleSelect(payload)}
                disabled={readOnly}
                maxDate={maxDate ? moment(maxDate).toDate() : undefined}
                minDate={maxDate ? moment(minDate).toDate() : undefined}
                date={moment(this.state.date).toDate()}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  showContent() {
    this.setState({
      ...this.state,
      showContent: true,
      contentStyle: {
        display: "block",
      },
    });
  }

  hideContent() {
    this.setState({
      ...this.state,
      showContent: false,
      contentStyle: {
        display: "none",
      },
    });
  }

  toggleShowContent() {
    if (this.state.showContent) {
      this.hideContent();
    } else {
      this.showContent();
    }
  }
}

DateField.muiName = "FormControl";

DateField.propTypes = {
  formField: PropTypes.object,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  date: PropTypes.any,
  className: PropTypes.string,
  maxDate: PropTypes.any,
  minDate: PropTypes.any,
  label: PropTypes.string,
  id: PropTypes.any,
};

export default DateField;
