export const NEW_GUID = "00000000-0000-0000-0000-000000000000";

// REGEX
export const LETTERS_AND_NUMBERS_REGEX = /^[A-Za-z0-9]*$/;

// Messaging
export const MESSAGING_PAGE_SIZE = 15;

/**
 * Divisor used to convert milliseconds to hours.
 * 3 600 000 = 60 * 60 * 1000
 */
export const MILLI_TO_HOURS_DIVISOR = 3600000;

export const PageType = {
  TopPage: 0,
  InternalPage: 1,
  BottomPage: 2,
  TopBottomPage: 3,
};

// Forms and Text
export const FontSizes = {
  HeadingLabel: "15px",
  RegularText: "14px",
  RecordDateText: "12px",
};
