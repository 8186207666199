import React, { Component } from "react";
import PropTypes from "prop-types";
import { TextField, InputAdornment } from "@mui/material";
import { AccessTime } from "@mui/icons-material";
import { Guid } from "../util/Helpers";

// Must of the code was copied from axial project, some minor changes had to be made to fix library and components
// issues. Styling and classname has to be checked, they may not exist in this project.
class TimePickerField extends Component {
  constructor(props) {
    super(props);

    const value =
      props.date ||
      (props.formField ? props.formField.value : props.value) ||
      "0001-01-01T00:00:00";

    this.state = {
      id: props.id || Guid(),
      value: value,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  getTimeValue() {
    let hour = parseInt(this.state.value.slice(11, 13), 10);
    let minute = parseInt(this.state.value.slice(14, 16), 10);

    if (`${hour}` === "NaN") {
      hour = 0;
    }

    if (`${minute}` === "NaN") {
      minute = 0;
    }

    if (hour < 10) {
      hour = `0${hour}`;
    }

    if (minute < 10) {
      minute = `0${minute}`;
    }

    return `${hour}:${minute}`;
  }

  render() {
    const {
      className,
      inputProps,
      readOnly,
      formField,
      value,
      ...otherProps // eslint-disable-line
    } = this.props;

    const classLevelInputProps = {
      startAdornment: (
        <InputAdornment position="start">
          <AccessTime />
        </InputAdornment>
      ),
    };

    return (
      <TextField
        {...otherProps}
        id={this.state.id}
        className={`input-field ${className}`}
        value={this.getTimeValue()}
        onChange={(e) => this.handleChange(e.target.value)}
        margin="none"
        InputProps={{ ...classLevelInputProps, ...inputProps }}
        disabled={readOnly}
        type="time"
      />
    );
  }

  handleChange = (value) => {
    const formattedTime = `0001-01-01T${value.slice(0, 2)}:${value.slice(
      3,
      5
    )}:00`;

    if (this.props.formField) {
      this.props.formField.onChange(formattedTime);
    }

    if (this.props.onChange) {
      this.props.onChange(formattedTime);
    }

    this.setState({ ...this.state, value: formattedTime });
  };
}

TimePickerField.muiName = "Input";

TimePickerField.propTypes = {
  id: PropTypes.string,
  formField: PropTypes.object,
  value: PropTypes.any,
  label: PropTypes.string.isRequired,
  icon: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.object,
  ]),
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  inputProps: PropTypes.object,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  initialValue: PropTypes.any,
};

export default TimePickerField;
