import { useContext, useState, useEffect } from "react";

import { UserContext } from "../../models/UserContext";
import { UserListContext } from "../../models/UserListContext";

import {
  SpaceOperationTask,
  validateSpaceName,
} from "../../controllers/SpaceController";
import { SpaceOperations } from "../../data/SpaceOperations";
import SpaceTypes from "../../data/SpaceTypes";
import { SpaceContext } from "../../contexts/SpaceContext";
import { SpaceMemberStruct } from "../../data/SpaceData";

import { AccountDetailsContext } from "../../models/AccountDetailsContext";

import { cleanSearchText, makePrettySpaceName } from "../../../util/RegexUtil";

import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { canShowMember } from "../../controllers/UserController";
const newSpaceAdded = {
  members: [],
};
export default function DirectSpaceEditor(props) {
  const isEditOpen = props.isOpen;
  const toggleEditDialog = props.toggleOpen;
  const spaceHeading = props.spaceHeading;


  const [userSearchText, setUserSearchText] = useState("");
  const [selectedSpaceRef, setSelectedSpaceRef] = useState("");

  const [isModifyChecked, setModifyChecked] = useState([0]);
  const [isAddChecked, setAddChecked] = useState([0]);

  const { currentUser, setCurrentUser } = useContext(UserContext.UserContext);
  const { userList } = useContext(UserListContext.UserListContext);
  const { spaceList } = useContext(SpaceContext.SpaceListContext);
  const { accountDetails } = useContext(
    AccountDetailsContext.AccountDetailsContext
  );
  const { activeSpace, setActiveSpace } = useContext(
    SpaceContext.ActiveSpaceContext
  );

  useEffect(() => {
    if (selectedSpaceRef.length > 0) {
      const newChecks = [0];
      const selectedSpace = spaceList.find(
        (space) => space.id === selectedSpaceRef
      );
      selectedSpace.members.map((member) => {
        if (member.memberId !== currentUser.id) {
          newChecks.push(member.memberId);
        }
        return null;
      });
      setModifyChecked(newChecks);
    }
  }, [selectedSpaceRef]);

  useEffect(() => {
    if (newSpaceAdded.members.length > 0) {
      const members = [...newSpaceAdded.members];
      const spaceFound = spaceList.find((space) => {
        if (space.messageSpaceType === SpaceTypes.DIRECT) {
            const onlyShownMembers = space.members.filter((visibleMember) => visibleMember.doShow);
            if (onlyShownMembers.length === members.length + 1) {
                return [currentUser, ...members].every((member) => {
                    return space.members.find((spaceMember) => spaceMember.memberId === member.id);
                });
            }
        }
      });
      setActiveSpace(spaceFound);
      setCurrentUser({ ...currentUser, lastSpaceId: spaceFound.id });
      newSpaceAdded.members = [];
    }
  }, [spaceList]);

  const modifyMembers = () => {
    const membersArray = [{ ...SpaceMemberStruct, memberId: currentUser.id }];
    isModifyChecked.map((userId) => {
      if (userId === undefined || isNaN(userId) || userId < 1) {
        return membersArray;
      }
      console.log(`Adding member [${userId}]`);
      return membersArray.push({ ...SpaceMemberStruct, memberId: userId });
    });
    const spaceToUpdate = spaceList.find(
      (space) => space.id === selectedSpaceRef
    );
    spaceToUpdate.members = membersArray;
    setSelectedSpaceRef("");
    setModifyChecked([0]);
    SpaceOperationTask(SpaceOperations.SEND_UPDATE_SPACE, {
      accountDetails: accountDetails,
      space: spaceToUpdate,
    });
  };

  const addChat = () => {
    const membersArray = [];
    isAddChecked.map((userId) => {
      if (userId !== 0) {
        const _user = {
          ...userList.find((user) => user.id === userId),
          doShow: true,
        };
        membersArray.push(_user);
      }
      return null;
    });
    const spaceFound = spaceList.find((space) => {
      if (space.messageSpaceType === SpaceTypes.DIRECT) {
          const onlyShownMembers = space.members.filter((visibleMember) => visibleMember.doShow);
          if (onlyShownMembers.length === membersArray.length + 1) {
              return [currentUser, ...membersArray].every((member) => {
                  return space.members.find((spaceMember) => spaceMember.memberId === member.id);
              });
          }
      }
    });
    if (spaceFound) {
      setAddChecked([0]);
      setActiveSpace(spaceFound);
      setCurrentUser({ ...currentUser, lastSpaceId: spaceFound.id });
    } else {
        newSpaceAdded.members = [...membersArray];
        addNewSpace(membersArray);
    }
    toggleEditDialog();
  };

  const addNewSpace = (additionalMembers) => {
    setAddChecked([0]);
    SpaceOperationTask(SpaceOperations.ADD_NEW_SPACE, {
      accountDetails: accountDetails,
      members: [currentUser, ...additionalMembers],
      spaceName: getSpaceName([currentUser, ...additionalMembers]),
      spaceType: SpaceTypes.DIRECT,
    });
  };

  const getSpaceName = (members) => {
    let counter = 0;
    let spaceName = "";
    members.map((user) => {
      if (counter > 0) {
        spaceName += "-";
      }
      counter++;
      return (spaceName += validateSpaceName(user.displayName));
    });
    return spaceName;
  };

  const isSpaceMember = (user, checkSpace, spaceList) => {
    if (checkSpace === undefined) return false;
    if (user === undefined) return false;
    return (
      spaceList
        .find((space) => checkSpace.id === space.id)
        .members.find((member) => member.memberId === user.id) !== undefined
    );
  };

  const handleExistingToggle = (value, checkArray, setCheckFunction) => {
    const currentIndex = checkArray.indexOf(value);
    const newChecked = [...checkArray];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckFunction(newChecked);
  };

  const handleSearchText = (searchText) => {
    setUserSearchText(searchText);
  };

  const isSearchMatched = (rawSearch, textToMatch) => {
    const search = cleanSearchText(rawSearch);
    return (
      textToMatch.toLowerCase().match(search !== undefined ? search : "") !==
      null
    );
  };

  const getUserListItem = (user, checkArray, setCheckFunction) => {
    if (user.id === currentUser.id) {
      return null;
    }
    const labelId = user.id;
    return (
      <ListItem
        key={user.id}
        disablePadding
        secondaryAction={
          <Checkbox
            edge="end"
            aria-labelledby={labelId}
            onChange={() =>
              handleExistingToggle(labelId, checkArray, setCheckFunction)
            }
            checked={checkArray.indexOf(labelId) !== -1}
          />
        }
      >
        <ListItemButton>
          <ListItemAvatar sx={{ minWidth: "40px" }}>
            <Avatar
              sx={{
                backgroundColor: "primary.main",
                color: "text.primary",
                width: "27.5px",
                height: "27.5px",
                fontSize: "small",
              }}
            >
              {user.displayName.substring(0, 1).toUpperCase()}
            </Avatar>
          </ListItemAvatar>
          <ListItemText id={labelId} primary={user.displayName} />
        </ListItemButton>
      </ListItem>
    );
  };

  return (
    <Dialog
      open={isEditOpen}
      onClose={() => toggleEditDialog()}
      scroll="paper"
      fullWidth
    >
      <DialogTitle id="scroll-dialog-title">
        Direct Messages
        <Typography fontSize="small">Keeping it personal</Typography>
      </DialogTitle>
      <DialogContent dividers={true}>
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="stretch"
        >
          <Grid item>
            <Box
              sx={{ display: "flex", alignItems: "flex-end", height: "56px" }}
            >
              <SearchIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
              <TextField
                id="filter-spaces"
                label="Filter Members ..."
                variant="standard"
                value={userSearchText}
                onChange={(e) => handleSearchText(e.target.value)}
              />
            </Box>
            <Box
              sx={{ width: "100%", height: "150px", my: 2, overflowY: "auto" }}
            >
              <List dense={true}>
                {userList.map((user) =>
                  isSearchMatched(userSearchText, user.displayName) &&
                  canShowMember(currentUser, user)
                    ? getUserListItem(user, isAddChecked, setAddChecked)
                    : null
                )}
              </List>
            </Box>
            <Grid container justifyContent="flex-end">
              <Button
                size="small"
                variant="contained"
                disabled={isAddChecked < 2}
                onClick={addChat}
              >
                Chat
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => toggleEditDialog()} variant={"contained"}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
