import React from 'react';
import {
    ComponentRef,
    getComponentRef,
    getFieldNumber,
    getFieldString,
    isObject,
    renderMessageText
} from './MessageTagUtil';
import {Link} from "@mui/material";

export const MessageFileTagTemplate = {
    component: 'MessageFileTag',
    data: {
        name: '',
        ext: '',
        link: '',
        size: 0,
        requiresToken: false,
    },
};

export function MessageFileTag(props) {
    const {objectData = {...MessageFileTagTemplate.data}, handleSubComponentLoaded = (subComponent = {...ComponentRef}, isLoaded = false) => {}, messageTime = 0, itemIndex = 0, level = 0} = props;

    const renderFileObject = () => {
        const requiresToken = objectData.requiresToken ? objectData.requiresToken : false;
        const fileLink = requiresToken ? objectData.link.substring(1) : objectData.link;
        const name = getFieldString(objectData.name);
        const size = getFieldNumber(objectData.size, 0);

        return <>
            <Link key={`file-${messageTime}-${itemIndex}-${level}`} href={fileLink}>{name} {size}</Link>
        </>;
    };

    const renderTag = () => {
        if (isObject(objectData)) {
            handleSubComponentLoaded(getComponentRef("MessageFileTag [File]", itemIndex, level, messageTime), true);
            return renderFileObject()
        }
        handleSubComponentLoaded(getComponentRef("MessageFileTag [Text]", itemIndex, level, messageTime), true);
        return renderMessageText(JSON.stringify(objectData));
    }

    return renderTag();
}
