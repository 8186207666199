import React, { useMemo } from "react";
import { useTheme } from "@mui/material";
import { MessageTagSetModel } from "../../../models/MessageRecordModel";
import Tag from "./Tag";

const DateTag = ({
  messageTagSet = MessageTagSetModel,
  label = "",
  mode = "date",
  companyId = "",
  tagType = "userTags",
  sectionName = "",
  sectionKVDate = "",
  sectionKVCompany = "",
  sectionKVEnabled = "",
  styles = {},
}) => {
  const theme = useTheme();

  const tagDates = useMemo(() => {
    if (
      !messageTagSet ||
      !messageTagSet[tagType] ||
      messageTagSet[tagType].length === 0
    ) {
      return [];
    }

    const dateTags = messageTagSet[tagType].filter(
      (tag) => tag.name === sectionName
    );
    if (dateTags.length === 0) {
      return [];
    }
    const tagDates = dateTags
      .filter((tag) => {
        const isCompanyMatch = tag.metaData.find(
          (kv) => kv.key === sectionKVCompany && kv.value === companyId
        );
        const isEnabled = tag.metaData.find(
          (kv) => kv.key === sectionKVEnabled
        );
        const hasDate = tag.metaData.find((kv) => kv.key === sectionKVDate);
        /*const showOnlyForMe =
          tagType !== "userTags" ||
          (tag.createdBy === userId && tagType === "userTags");*/
        if (
          isEnabled &&
          isEnabled.value &&
          hasDate &&
          hasDate.value /*&&
          showOnlyForMe*/
        ) {
          if (sectionKVCompany.length > 0) {
            if (isCompanyMatch && isCompanyMatch.value) {
              return true;
            }
          } else {
            return true;
          }
          return true;
        }
        return false;
      })
      .map((tag) => {
        const hasDate = tag.metaData.find((kv) => kv.key === sectionKVDate);
        return hasDate.value;
      });
    return tagDates;
  }, [messageTagSet]);

  return (
    tagDates.length > 0 &&
    tagDates.map((tagDate, pIndex) => (
      <Tag
        key={`planner-${pIndex}`}
        mode="date"
        label={label}
        date={tagDate}
        styles={styles}
      />
    ))
  );
};

export default DateTag;
