import { UserOperations } from "../data/UserOperations";
import axios from "axios";
import { proxyOptions, proxyOptionsWithToken } from "../../util/ProxyOptions";
import {
  EmploymentStatus,
  UserCompanyMap,
  UserStruct,
} from "../models/UserContext";
import { AxiosErrorHandler } from "../../util/AxiosHelper";
import { ApiEndPoints } from "../../../data/ApiEndPoints";

export const UserOperationTask = (action, data) => {
  switch (action) {
    case UserOperations.LOAD_USERS:
      //const userListNoPerms = LoadInternalUsers(data.accountDetails);
      return LoadApiUsers(data.accountDetails, data.setCurrentUser);
    default:
      return;
  }
};

const LoadApiUsers = async (accountDetails, setCurrentUser) => {
  const users = [];
  const loadApiUserList = async () => {
    return await axios.get(
      `${accountDetails.apiUrl}/${ApiEndPoints.ACCOUNT_USERS}/${accountDetails.axial.account.id}`,
      proxyOptionsWithToken(accountDetails.token)
    );
  };
  await loadApiUserList()
    .then((response) =>
      response.data.result.companyUserMappingList.map((company) => {
        company.companyUsers.map((axialUser) => {
          /*
            const msgUser = users.find((user) => user.axialId === axialUser.id);
            msgUser.isAdmin = axialUser.isAdmin;
            msgUser.isOwner = axialUser.isOwner;
            msgUser.status = axialUser.status;
        */
          const existingUser = users.find((u) => u.id === axialUser.id);
          if (existingUser !== undefined) {
            existingUser.companies.push({
              ...UserCompanyMap,
              companyId: company.companyId,
              isAdmin: axialUser.isAdmin,
              isOwner: axialUser.isOwner,
              status: axialUser.status,
            });
            const existingIndex = users.findIndex(
              (u) => u.id === existingUser.id
            );
            users.splice(existingIndex, 1, existingUser);
          } else {
            const addUser = {
              ...UserStruct,
              id: axialUser.id,
              activeCompanyId: accountDetails.axial.company.id,
              companies: [
                {
                  ...UserCompanyMap,
                  companyId: company.companyId,
                  isAdmin: axialUser.isAdmin,
                  isOwner: axialUser.isOwner,
                  status: axialUser.status,
                },
              ],
              firstName: axialUser.firstName,
              lastName: axialUser.lastName,
              displayName: `${axialUser.lastName}, ${axialUser.firstName}`,
              lastSpaceId: "",
              isAdmin: axialUser.isAdmin,
              isOwner: axialUser.isOwner,
              status: axialUser.status,
              isSuperUser: axialUser.isSuperUser,
              jobs: axialUser.jobs,
              doShow: axialUser.doShow,
            };
            users.push(addUser);
          }
          return axialUser;
        });
      })
    )
    .catch((error) => {
      AxiosErrorHandler(error);
    });
  return users;
};

/*
const LoadInternalUsers = (accountDetails) => {
    const users = [];
    const loadUserList = async () => {
        console.log("Kicked off the user load");
        return await axios.post(accountDetails.baseUrl + "/users", {
            accountId: accountDetails.accountId,
            companyId: accountDetails.companyId
        }, proxyOptions);
    }
    loadUserList()
        .then((response) =>
            response.data.map((mapData) => {
                const mapping = mapData.mapping; // Contains last space id
                const user = mapData.user; // Contains user info
                const addUser = {
                    ...UserStruct,
                    id: user.id,
                    axialId: user.sourceId,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    displayName: user.displayName,
                    lastSpaceId: mapping.lastSpaceRef
                }
                users.push(addUser);
                return mapData;
            }))
        .catch((error) => {
            AxiosErrorHandler(error);
        });
    return users;
}
*/

/*export const canShowMember = (currentUser, user) => {
  if (user.status !== EmploymentStatus.current) return false;
  if (currentUser.isAdmin) return true;
  if (currentUser.isOwner) return !user.isAdmin;
  return !(user.isAdmin || user.isOwner);
};*/

const UserModel = {
  id: "",
  activeCompanyId: "",
  companies: [UserCompanyMap],
  firstName: "",
  lastName: "",
  displayName: "",
  thumbnail: "",
  lastSpaceId: "",
  isSuperUser: false,
  doShow: false,
};

export const isUserAdmin = (testUser = UserModel) => {
  const user = testUser.companies.find(
    (c) => c.companyId === testUser.activeCompanyId
  );
  return user && user.isAdmin;
};

export const isUserOwner = (testUser = UserModel) => {
  const user = testUser.companies.find(
    (c) => c.companyId === testUser.activeCompanyId
  );
  return user && user.isOwner;
};

export const canShowMember = (currentUser, user) => {
  const isStatusCurrent =
    user.companies.find((c) => c.status === EmploymentStatus.current) !==
    undefined;
  if (!isStatusCurrent) {
    return false;
  }
  if (isUserAdmin(currentUser)) {
    return true;
  }
  if (isUserOwner(user)) {
    return !isUserAdmin(user);
  }
  return !(isUserAdmin(user) || isUserOwner(user));
};
