import { createContext } from "react";

export const EmploymentStatus = {
  current: 1,
  inactive: 2,
  pending: 3,
  quit: 4,
  suspended: 5,
  terminated: 6,
};

export const UserCompanyMap = {
  companyId: "",
  isAdmin: 0,
  isOwner: 0,
  status: EmploymentStatus.current,
};

export const UserStruct = {
  id: "",
  activeCompanyId: "",
  companies: [UserCompanyMap],
  firstName: "",
  lastName: "",
  displayName: "",
  thumbnail: "",
  lastSpaceId: "",
  isSuperUser: false,
  isAdmin: false,
  isOwner: false,
  status: EmploymentStatus.current,
  hasAnyPermissions: false,
  doShow: true,
};

const User = createContext({
  currentUser: { ...UserStruct },
  setCurrentUser: (user) => {},
});

export const UserContext = {
  UserContext: User,
};
