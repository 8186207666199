export const AxiosErrorHandler = (error, callback) => {
    if (error.response) {
        console.log(`Error Response Status: ${error.response.status}`);
        for (const key in error.response.headers) {
            console.log(`Header [${key}]: ${error.response.headers[key]}`);
        }
        if (callback) {
            callback(error.response.status);
        }
    } else if (error.request) {
        console.log(`Error Request: ${error.request}`);
    } else {
        console.log(`Error Message: ${error.message}`);
    }
    console.log(`Error Config: (${error.config.method}) -> ${error.config.url}`);
}