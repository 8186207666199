import {ComponentRef, getComponentRef, isObject, renderMessageText} from "./MessageTagUtil";
import {Link} from "@mui/material";

const MessageLinkTagDataTemplate = {
    link: "https://app.axialshift.com",
    display: "Axial Shift"
}

export const MessageLinkTagTemplate = {
    component: "MessageLinkTag",
    data: {...MessageLinkTagDataTemplate}
}
/**
 * {
 *     "component": "MessageLinkTag",
 *     "data": {
 *          "link": "https://app.axialshift.com",
 *          "display": "Axial Shift"
 *     }
 * }
 */
export function MessageLinkTag(props) {
    const {objectData, handleSubComponentLoaded = (subComponent = {...ComponentRef}, isLoaded = false) => {}, messageTime, itemIndex, level} = props;

    const renderLinkObject = (data = MessageLinkTagDataTemplate) => {
        const link = data.link;
        const display = data.display && data.display.length > 0 ? data.display : link;
        if (link) {
            return <Link key={`link-${messageTime}-${itemIndex}-${level}`} href={link} target="_blank" rel="noopener noreferrer">{display}</Link>;
        }
        return <></>;
    }

    const renderTag = () => {
        handleSubComponentLoaded(getComponentRef("MessageLinkTag", itemIndex, level, messageTime), true);
        if (isObject(objectData)) {
            return renderLinkObject(objectData);
        }
        return renderMessageText(objectData)
    }

    return renderTag();
}
