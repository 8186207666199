import {ThemeProvider, useTheme} from "@mui/material";
import {styled} from "@mui/material/styles";

const RecordListStyleWrapper = (styled("div")(({theme}) => ({
    [theme.breakpoints.down("sm")]: {
        padding: 0,
        paddingTop: "10px",
        margin: 0
    },
    [theme.breakpoints.up("sm")]: {
        paddingTop: "10px",
        paddingLeft: "20px",
        paddingRight: "20px",
    },
    [theme.breakpoints.up("md")]: {
        paddingTop: "10px",
        paddingLeft: "60px",
        paddingRight: "60px",
    },
    [theme.breakpoints.up("lg")]: {
        paddingTop: "10px",
        paddingLeft: "100px",
        paddingRight: "100px",
    }
})));

export default function RecordListWrapper(props) {
    const theme = useTheme();

    return (<ThemeProvider theme={theme}>
        <RecordListStyleWrapper>{props.children}</RecordListStyleWrapper>
    </ThemeProvider>);
}

