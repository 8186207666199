import axios from "axios";
import moment from "moment";

import { SpaceOperations } from "../data/SpaceOperations";
import { SpaceMemberStruct, SpaceStruct } from "../data/SpaceData";
import { proxyOptions } from "../../util/ProxyOptions";
import { AxiosErrorHandler } from "../../util/AxiosHelper";
import SpaceTypes from "../data/SpaceTypes";
import { isUserAdmin, isUserOwner } from "../controllers/UserController";
import { checkAnyMatchInArrays, Guid } from "../../util/Helpers";

export const SpaceListReducer = (state, action) => {
  console.log("SpaceListReducer [" + action.type + "]");
  switch (action.type) {
    case SpaceOperations.INITIALIZE:
      return action.ref;
    case SpaceOperations.ADD_MESSAGE:
      return updateUnreadSpace(state, action.ref);
    case SpaceOperations.UPDATE_SPACE:
      return updateSpace(state, action.ref);
    case SpaceOperations.DELETE_SPACE:
      return deleteSpace(state, action.ref);
    case SpaceOperations.ADD_SPACE:
      return addSpace(state, action.ref);
    case SpaceOperations.MAP_USER:
      return mapUser(state, action.ref);
    case SpaceOperations.UNMAP_USER:
      return unmapUser(state, action.ref);
    case SpaceOperations.SET_SPACE_UNREAD_MESSAGES_COUNT:
      return setSpaceUnreadMessagesCount(state, action.ref);
    default:
      return state;
  }
};

export const SpaceOperationTask = async (action, data) => {
  console.log(`SpaceOperations :: ${action}`);
  switch (action) {
    case SpaceOperations.SEND_UPDATE_SPACE:
      await sendUpdateSpace(data.accountDetails, data.space);
      return;
    case SpaceOperations.SEND_DELETE_SPACE:
      await sendDeleteSpace(data.accountDetails, data.space);
      return;
    case SpaceOperations.SEND_ADD_SPACE:
      await sendNewSpace(data.accountDetails, data.space);
      return;
    case SpaceOperations.ADD_SPACE_MEMBER:
      return addSpaceMember(data.space, data.user);
    case SpaceOperations.REMOVE_SPACE_MEMBER:
      return removeSpaceMember(data.space, data.user);
    case SpaceOperations.ADD_NEW_SPACE:
      addNewSpace(
        data.accountDetails,
        data.members,
        data.spaceName,
        data.spaceType,
        data.dynamicObjectTypeIds,
        data.dynamicGroupType,
        data.authorizedStoresIds,
        data.authorizedUsersIds
      );
      return;
    default:
      return;
  }
};

export const SpaceListLoader = (accountDetails, dispatcher) => {
  const loadSpaces = async () => {
    return await axios.post(
      accountDetails.baseUrl + "/SpaceListV2",
      {
        accountId: accountDetails.axial.account.id,
        companyId: accountDetails.axial.company.id,
      },
      proxyOptions
    );
  };
  loadSpaces()
    .then((response) => {
      console.log("Passing loaded data to dispatcher");
      dispatcher({
        type: SpaceOperations.INITIALIZE,
        ref: response.data,
      });
    })
    .catch((error) => {
      AxiosErrorHandler(error);
    });
};

export const UnreadMessageLoader = (accountDetails) => {
  const loadUnreadAsync = async () => {
    return await axios.post(
      `${accountDetails.baseUrl}/UnreadSpaceListV2`,
      {
        accountId: accountDetails.axial.account.id,
        companyId: accountDetails.axial.company.id,
        userId: accountDetails.axial.user.id,
      },
      proxyOptions
    );
  };
  return loadUnreadAsync();
};

export const findSpace = (spaceList, spaceRef, user) => {
  if (spaceList.length > 0) {
    if (
      !spaceRef ||
      spaceRef.length === 0 ||
      spaceRef === "00000000-0000-0000-0000-000000000000"
    ) {
      const spaceItemChk = spaceList.find((space) =>
        space.members.find((member) => member.memberId === user.id)
      );
      console.log(
        `findSpace: passed Space Ref is blank, assigning to ${spaceItemChk}`
      );
      if (spaceItemChk !== undefined && spaceItemChk !== null)
        return spaceItemChk;
    }

    const spaceItem = spaceList.find((space) => space.id === spaceRef);
    if (spaceItem === undefined || spaceItem === null) {
      console.log("Space was probably removed, reassigning");
      return spaceList.find((space) =>
        space.members.find((member) => member.memberId === user.id)
      );
    }

    return spaceItem;
  }
  throw new Error("Space List or User Map have no elements");
};

export const validateSpaceName = (name) => {
  const regexSpacePattern = /\s/;
  const regexDoubleDashPattern = /--/;
  const regexIllegalCharPattern = /[^a-zA-Z0-9-]+/;
  name = name.replace(regexSpacePattern, "-");
  name = name.replace(regexDoubleDashPattern, "-");
  name = name.replace(regexIllegalCharPattern, "");
  return name.toLowerCase();
};

export const isSpaceMember = (space, user) => {
  if (space.messageSpaceType !== SpaceTypes.DYNAMIC_GROUP) {
    const hasMembers = space.members;
    if (hasMembers) {
      return (
        space.members.find((member) => member.memberId === user.id) !==
        undefined
      );
    }
    return false;
  } else {
    const { ownJobs, companies, isSuperUser } = user;

    if (ownJobs) {
      const hasCompany = validateCompany(space, companies);
      if (hasCompany) {
        if (
          isSuperUser ||
          isUserAdmin(user) ||
          (isUserOwner(user) &&
            companies.find(({ companyId }) => companyId === space.companyId))
        ) {
          return true;
        }

        return checkAnyMatchInArrays(ownJobs, space.dynamicObjectTypeIds);
      }
    }
    return false;
  }
};

/**
 * Checks if the dynamic space needs a company validation. If need it, then checks the values.
 * @param {*} space Space to be checked.
 * @param {*} companies List of companies.
 * @returns
 */
const validateCompany = (space, companies) => {
  if (space.authorizedStoresIds) {
    return checkAnyMatchInArrays(
      companies,
      space.authorizedStoresIds,
      "companyId"
    );
  } else {
    return true;
  }
};

const convertUserArrayToMembersArray = (
  spaceId = "",
  members = [{ ...SpaceMemberStruct }]
) => {
  const membershipArray = [];
  members.map((user) => {
    const member = {
      ...SpaceMemberStruct,
      id: Guid(),
      memberId: user.id,
      doShow: user.doShow,
      lastAccessed: moment.utc(),
      messageSpaceId: spaceId,
    };
    return membershipArray.push(member);
  });
  return membershipArray;
};

const addSpaceMember = (space, user) => {
  if (!isSpaceMember(space, user)) {
    const hasMemberArray = space.members;
    const newMembership = {
      ...SpaceMemberStruct,
      id: Guid(),
      memberId: user.id,
      doShow: true,
      lastAccessed: moment.utc(),
      messageSpaceId: space.id,
    };
    if (hasMemberArray === undefined || hasMemberArray === null) {
      space.members = [newMembership];
    } else {
      space.members = [...space.members, newMembership];
    }
  }
  console.log(`Membership of this space [${user.displayName}/${space.name}]`);
  return space;
};

const removeSpaceMember = (space, user) => {
  if (isSpaceMember(space, user)) {
    space.members = space.members.filter(
      (member) => member.memberId !== user.id
    );
  }
  console.log(`Membership of this space [${user.displayName}/${space.name}]`);
  return space;
};

const sendUpdateSpace = async (accountDetails, space) => {
  const sendSpaceDetailsAsync = async () => {
    console.log("Sending updated space details : " + space.name);
    await axios.post(
      accountDetails.baseUrl + "/updateSpace",
      space,
      proxyOptions
    );
  };
  sendSpaceDetailsAsync()
    .then(() => console.log("Sent updated space details"))
    .catch((error) => {
      AxiosErrorHandler(error);
    });
};

const updateSpace = (spaceList, updatedSpace) => {
  if (
    spaceList[0].accountId !== updatedSpace.accountId ||
    spaceList[0].company !== updatedSpace.company
  ) {
    return spaceList;
  }
  spaceList = spaceList.filter((space) => space.id !== updatedSpace.id);
  spaceList = [...spaceList, updatedSpace];
  spaceList.sort((thisSpace, otherSpace) => {
    if (thisSpace.name > otherSpace.name) return 1;
    if (thisSpace.name < otherSpace.name) return -1;
    return 0;
  });
  return spaceList;
};

const updateUnreadSpace = (spaceList, newMessage) => {
  console.log(`What space is this new message? ${JSON.stringify(newMessage)}`);
  const messageSpace = spaceList.find(
    (space) => space.id === newMessage.spaceId
  );
  if (messageSpace !== undefined) {
    if (messageSpace.unreadCount !== null) {
      messageSpace.unreadCount++;
    } else {
      messageSpace.unreadCount = 1;
    }
    return updateSpace(spaceList, messageSpace);
  }
  return spaceList;
};

const sendDeleteSpace = async (accountDetails, space) => {
  const sendSpaceDetails = async () => {
    console.log("Sending delete space details : " + space.name);
    await axios.post(
      accountDetails.baseUrl + "/deleteSpace",
      {
        accountId: accountDetails.axial.account.id,
        companyId: accountDetails.axial.company.id,
        id: space.id,
      },
      proxyOptions
    );
  };
  sendSpaceDetails().then((response) => console.log(response));
};

const deleteSpace = (spaceList, space) => {
  console.log("Deleting space item : " + space.id);
  const removeIndex = spaceList.findIndex((element) => {
    return element.id === space.id;
  });
  console.log("space element index to remove : " + removeIndex);
  if (removeIndex < 0) return spaceList;
  return spaceList.filter((item, index) => index !== removeIndex);
};

const addNewSpace = (
  accountDetails,
  members,
  spaceName,
  spaceType,
  dynamicObjectTypeIds,
  dynamicGroupType,
  authorizedStoresIds,
  authorizedUsersIds,
  setSpace
) => {
  const spaceId = Guid();
  // Assumes first member is the person creating the space
  console.log(`New space details: ${spaceName}/${spaceType}/${members[0].id}`);
  const initiatedSpace = {
    ...SpaceStruct,
    id: spaceId,
    accountId: accountDetails.axial.account.id,
    companyId: accountDetails.axial.company.id,
    createdBy: members[0].id,
    messageSpaceType: spaceType,
    name: spaceName,
    members: convertUserArrayToMembersArray(spaceId, members),
    dynamicObjectType: spaceType !== SpaceTypes.DYNAMIC_GROUP ? null : 0, //Will change when the first dropdown works.
    dynamicObjectTypeIds:
      spaceType !== SpaceTypes.DYNAMIC_GROUP ? null : dynamicObjectTypeIds,
    dynamicGroupType: dynamicGroupType,
    authorizedStoresIds:
      spaceType !== SpaceTypes.DYNAMIC_GROUP ? null : authorizedStoresIds,
    authorizedUsersIds:
      spaceType !== SpaceTypes.DYNAMIC_GROUP ? null : authorizedUsersIds,
  };
  console.log("initiatedSpace: ", initiatedSpace);
  console.log(
    `About to push new space details => ${JSON.stringify(initiatedSpace)}`
  );
  SpaceOperationTask(SpaceOperations.SEND_ADD_SPACE, {
    accountDetails: accountDetails,
    space: initiatedSpace,
    setSpace,
  });
};

const sendNewSpace = async (accountDetails, space, setSpace) => {
  const sendSpaceDetails = async () => {
    console.log("Sending add new space details : " + space.name);
    await axios.post(
      accountDetails.baseUrl + "/createSpace",
      space,
      proxyOptions
    );
  };
  sendSpaceDetails().then(() => console.log(`Sent new space -> ${space.name}`));
};

const addSpace = (spaceList, space) => {
  if (
    spaceList[0].accountId !== space.accountId ||
    spaceList[0].company !== space.company
  ) {
    return spaceList;
  }
  spaceList = [...spaceList, space];
  spaceList.sort((thisSpace, otherSpace) => {
    if (thisSpace.name > otherSpace.name) return 1;
    if (thisSpace.name < otherSpace.name) return -1;
    return 0;
  });
  return spaceList;
};

/**
 * Add user to members array
 * @param spaceList
 * @param data
 * @returns {*}
 */
const mapUser = (spaceList, data) => {
  const spaceCheck = spaceList.find(
    (space) => space.id === data.mapInfo.spaceId
  );
  if (spaceCheck === undefined || spaceCheck === null) {
    throw new Error("Cannot add space mapping.  Requested space not found");
  }
  return spaceList;
};

/**
 * Remove user from members array - unless its direct, make invisible, or if owner of private space
 * @param spaceList
 * @param data
 * @returns {*}
 */
const unmapUser = (spaceList, data) => {
  const spaceCheck = spaceList.find(
    (space) => space.id === data.mapInfo.spaceId
  );
  if (spaceCheck === undefined || spaceCheck === null) {
    throw new Error("Cannot remove space mapping.  Requested space not found");
  }
  return spaceList;
};

/**
 * Get space name for space group. If space type is dynamic then returns other member's name.
 */
export const getSpaceName = (space, userList, currentUser) => {
  if (
    space.messageSpaceType !== SpaceTypes.DIRECT ||
    (userList.length === 1 && userList[0].id === "")
  ) {
    return space.name;
  } else {
    const members = space.members.filter(
      ({ memberId, doShow }) => memberId !== currentUser.id && doShow
    );
    if (members.length > 0) {
      let membersArray = members.map(({ memberId }) => {
        const user = userList.find(({ id }) => id === memberId);
        if (user === undefined) {
          return space.name;
        }

        return `${user.firstName} ${user.lastName}`;
      });

      return membersArray.join(", ");
    } else {
      return space.name;
    }
  }
};

const setSpaceUnreadMessagesCount = (spaceList, data) => {
  const { spaceId, unreadMessagesCount } = data;
  const updatedSpace = spaceList.find((space) => space.id === spaceId);
  if (updatedSpace !== undefined) {
    updatedSpace.unreadCount = unreadMessagesCount;
  }
  spaceList = spaceList.filter((space) => space.id !== updatedSpace.id);
  spaceList = [...spaceList, updatedSpace];
  spaceList.sort((thisSpace, otherSpace) => {
    if (thisSpace.name > otherSpace.name) return 1;
    if (thisSpace.name < otherSpace.name) return -1;
    return 0;
  });
  return spaceList;
};
