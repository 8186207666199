import {Divider, List, ListItemButton, ListItemIcon, ListItemText, Toolbar} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person"
import ManageUserListSettings from "./ManageUserListSettings";

export default function SettingsMenu(props) {

    const {setContent} = props;

    return (<>
        <Toolbar>Please be careful when modifying</Toolbar>
        <Divider/>
        <List>
            <ListItemButton onClick={() => setContent(ManageUserListSettings)}>
                <ListItemIcon><PersonIcon/></ListItemIcon>
                <ListItemText primary="Manage Users"/>
            </ListItemButton>
        </List>
    </>);
}