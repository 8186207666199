import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Box, Checkbox, Grid, Typography, useTheme} from "@mui/material";
import {ComponentRef, getComponentRef} from "../../message/components/records/MessageTagUtil";
import {FontSizes} from "../../constants";

const CheckBoxInput = ({
  label,
  id,
  readOnly,
  required,
  onChange,
  value,
    handleSubComponentLoaded = (subComponent = {...ComponentRef}, isLoaded = false) => {},
                       itemIndex, level, messageTime,
}) => {
  const theme = useTheme();

  const [internalValue, setInternalValue] = useState(value);

  useEffect(() => {
    handleSubComponentLoaded(getComponentRef("CheckBoxInput", itemIndex, level, messageTime), true);
  }, []);

  const handleOnInputChange = (value) => {
    setInternalValue(value);
    onChange(id, value);
  };

  if (readOnly) {
    if (!internalValue) {
      return <></>;
    }
    const formattedText = internalValue ? "Yes" : "No";
    return <>
      <Grid item container sx={{marginBottom: "1px"}}>
        <Grid item sx={{marginRight: "10px"}}><Typography sx={{fontWeight: "bold", color: theme.palette.forms.label, fontSize: FontSizes.RegularText}}>{label}:</Typography></Grid>
        <Grid item><Typography sx={{color: theme.palette.forms.regular, fontSize: FontSizes.RegularText}}>{formattedText}</Typography></Grid>
      </Grid>
    </>;
  }

  return (
    <Box>
      <Checkbox
        id={id}
        onChange={(e) => handleOnInputChange(e.target.value)}
        value={internalValue}
        label={label}
        readOnly={readOnly}
        required={required}
      />
      {label}
    </Box>
  );
};

CheckBoxInput.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  placeholderText: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.any,
};

export default CheckBoxInput;
