import { useState, useMemo, useReducer } from "react";
import { Route, MemoryRouter, Routes } from "react-router-dom";

import MessageContainer from "./components/message/components/MessageContainer";

import {
  AccountDetailsStruct,
  AccountDetailsContext,
} from "./components/message/models/AccountDetailsContext";
import { SpaceContext } from "./components/message/contexts/SpaceContext";
import { MessageContext } from "./components/message/contexts/MessageContext";
import { UserListContext } from "./components/message/models/UserListContext";
import {
  UserContext,
  UserStruct,
} from "./components/message/models/UserContext";

import {
  SpaceHeadings,
  SpaceStruct,
} from "./components/message/data/SpaceData";
import { SpaceListReducer } from "./components/message/controllers/SpaceController";

import {
  Box,
  CircularProgress,
  CssBaseline,
  ThemeProvider,
  useMediaQuery,
} from "@mui/material";
import { createTheme } from "@mui/material";

import Auth from "./components/auth/components/Auth";
import Settings from "./components/settings/components/Settings";
import { MessageItemReducer } from "./components/message/controllers/MessageController";
import { MessageItemListStruct } from "./components/message/data/MessageData";
import Api from "./data/Api";
import { SnackBarModel } from "./components/message/models/AppDataModel";
import { AppContext } from "./components/message/contexts/AppContext";

export const isDevelopment = process.env.NODE_ENV === "development";
export const functionDomain = isDevelopment
  ? "http://localhost:7071"
  : "https://axialmessaging-functions.azurewebsites.net";
export const apiDomain = isDevelopment
  ? "http://localhost:7071/api/proxy"
  : "https://api.axialcommerce.com";

export const api = Api.create();

function App() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  // AppContext
  const [showLoading, setShowLoading] = useState(false);
  const [snackbar, setSnackbar] = useState(SnackBarModel);
  const [showSearchScreen, setShowSearchScreen] = useState(false);

  const [accountDetails, setAccountDetails] = useState({
    ...AccountDetailsStruct,
    baseUrl: `${functionDomain}/api`,
    apiUrl: `${apiDomain}`,
  });

  const [spaceList, spaceListDispatch] = useReducer(
    SpaceListReducer,
    [SpaceStruct],
    undefined
  );

  const [messageItemList, messageItemDispatcher] = useReducer(
    MessageItemReducer,
    MessageItemListStruct,
    undefined
  );

  /* Message reload in MessageRecordContainer */
  const [spaceHeadings, setSpaceHeadings] = useState(SpaceHeadings);
  const [activeSpace, setActiveSpace] = useState(SpaceStruct);

  const [userList, setUserList] = useState([UserStruct]);
  const [currentUser, setCurrentUser] = useState(UserStruct);

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? "dark" : "light",
          message: {
            unread: prefersDarkMode ? "#d32020" : "#d32020",
            tag: {
              text: prefersDarkMode ? "#5199cc" : "#28303b",
              bgPlanner: prefersDarkMode ? "#3a4656" : "#dbe7ef",
              bgReminder: prefersDarkMode ? "#3a4656" : "#dbe7ef",
              bgScheduled: prefersDarkMode ? "#3a4656" : "#dbe7ef",
            },
          },
          forms: {
            heading: prefersDarkMode ? "#f4f4f4" : "#28303b",
            label: prefersDarkMode ? "#94c25f" : "#6f86a4",
            regular: prefersDarkMode ? "#efeaff" : "#455365",
          },
          channel: {
            editText: prefersDarkMode ? "#dbe7ef" : "#dbe7ef",
            editChannel: prefersDarkMode ? "#212b38" : "#28303b",
            editTextHover: prefersDarkMode ? "#f7fcff" : "#f7fcff",
            editChannelHover: prefersDarkMode ? "#212b38" : "#455365",
          },
          common: {
            black: prefersDarkMode ? "#212b38" : "#000",
            white: prefersDarkMode ? "#fff" : "#fff",
          },
          primary: {
            main: prefersDarkMode ? "#726eff" : "#1976d2", // previous main: prefersDarkMode ? '#4644a3' : '#1976d2',
            light: prefersDarkMode ? "#efeaff" : "#42a5f5", // previous light: prefersDarkMode ? '#afaefc' : '#42a5f5',
            dark: prefersDarkMode ? "#4644a3" : "#1565c0", // previous dark: prefersDarkMode ? '#726eff' : '#1565c0'
          },
          secondary: {
            main: prefersDarkMode ? "#0adabc" : "#9c27b0",
            light: prefersDarkMode ? "#c6fff7" : "#ba68c8",
            dark: prefersDarkMode ? "#5affe7" : "#7b1fa2",
          },
          background: {
            paper: prefersDarkMode ? "#37465b" : "#f4f4f4",
            text: prefersDarkMode ? "#37465b" : "#37465b",
            paperDark: prefersDarkMode ? "#2b3747" : "#ebeaef",
            default: prefersDarkMode ? "#212b38" : "#efefef",
          },
        },
      }),
    [prefersDarkMode]
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      {showLoading && (
        <Box
          sx={{
            position: "absolute",
            zIndex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <CircularProgress size={80} />
        </Box>
      )}
      <div className="App">
        <AppContext.ShowLoadingContext.Provider
          value={{ showLoading, setShowLoading }}
        >
          <AppContext.SnackbarContext.Provider
            value={{ snackbar, setSnackbar }}
          >
            <AppContext.ShowSearchScreenContext.Provider
              value={{ showSearchScreen, setShowSearchScreen }}
            >
              <AccountDetailsContext.AccountDetailsContext.Provider
                value={{ accountDetails, setAccountDetails }}
              >
                <SpaceContext.SpaceHeadingsContext.Provider
                  value={{ spaceHeadings, setSpaceHeadings }}
                >
                  <SpaceContext.SpaceListContext.Provider
                    value={{ spaceList, spaceListDispatch }}
                  >
                    <SpaceContext.ActiveSpaceContext.Provider
                      value={{ activeSpace, setActiveSpace }}
                    >
                      <UserListContext.UserListContext.Provider
                        value={{ userList, setUserList }}
                      >
                        <UserContext.UserContext.Provider
                          value={{ currentUser, setCurrentUser }}
                        >
                          <MessageContext.MessageRecordMgr.Provider
                            value={{ messageItemList, messageItemDispatcher }}
                          >
                            <MemoryRouter>
                              <Routes>
                                <Route
                                  path="/"
                                  exact={true}
                                  element={<Auth />}
                                />
                                <Route
                                  path="/home"
                                  exact={true}
                                  element={<MessageContainer />}
                                />
                                <Route
                                  path="/settings"
                                  exact={true}
                                  element={<Settings />}
                                />
                              </Routes>
                            </MemoryRouter>
                          </MessageContext.MessageRecordMgr.Provider>
                        </UserContext.UserContext.Provider>
                      </UserListContext.UserListContext.Provider>
                    </SpaceContext.ActiveSpaceContext.Provider>
                  </SpaceContext.SpaceListContext.Provider>
                </SpaceContext.SpaceHeadingsContext.Provider>
              </AccountDetailsContext.AccountDetailsContext.Provider>
            </AppContext.ShowSearchScreenContext.Provider>
          </AppContext.SnackbarContext.Provider>
        </AppContext.ShowLoadingContext.Provider>
      </div>
    </ThemeProvider>
  );
}

export default App;
