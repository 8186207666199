import {
  useState,
  useEffect,
  useRef,
  useContext,
  useLayoutEffect,
} from "react";
import { useNavigate } from "react-router-dom";

/**
 * Layout
 */
import {
  Root,
  Header,
  EdgeTrigger,
  EdgeSidebar,
  SidebarContent,
  Content,
} from "@mui-treasury/layout";

import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LogoutIcon from "@mui/icons-material/Logout";

/**
 * Visual Components
 */
import MessageHeaderContainer from "./header/MessageHeaderContainer";
import MessageSpaceSectionContainer from "./spaces/MessageSpaceSectionContainer";
import MessageRecordContainer from "./records/MessageRecordContainer";
import Search from "../../search/components/Search";
import MessageFooterContainer from "./footer/MessageFooterContainer";

/**
 * Account Info
 */
import {
  AccountDetailsContext,
  AccountDetailsStruct,
} from "../models/AccountDetailsContext";

/**
 * Space Section
 */
import { SpaceConnection } from "../connect/SpaceConnection";
import { SpaceListLoader, findSpace } from "../controllers/SpaceController";
import { SpaceContext } from "../contexts/SpaceContext";

/**
 * User Data
 */
import { UserListContext } from "../models/UserListContext";
import { UserContext, UserStruct } from "../models/UserContext";

import { Box, ButtonBase, CircularProgress } from "@mui/material";
import { UserOperationTask } from "../controllers/UserController";
import { UserOperations } from "../data/UserOperations";
import { MessageConnection } from "../connect/MessageConnection";
import { MessageContext } from "../contexts/MessageContext";
import { MessageItemLoader } from "../controllers/MessageController";
import { AppContext } from "../contexts/AppContext";

export default function MessageContainer() {
  const navigate = useNavigate();

  const [data, setData] = useState({
    header: true,
    nav: true,
    content: true,
    footer: true,
  });

  const { accountDetails, setAccountDetails } = useContext(
    AccountDetailsContext.AccountDetailsContext
  );
  const { spaceList, spaceListDispatch } = useContext(
    SpaceContext.SpaceListContext
  );
  const { messageItemList, messageItemDispatcher } = useContext(
    MessageContext.MessageRecordMgr
  );

  const { activeSpace, setActiveSpace } = useContext(
    SpaceContext.ActiveSpaceContext
  );
  const { userList, setUserList } = useContext(UserListContext.UserListContext);
  const { currentUser, setCurrentUser } = useContext(UserContext.UserContext);

  const { showSearchScreen, setShowSearchScreen } = useContext(
    AppContext.ShowSearchScreenContext
  );
  const renderCount = useRef(0);

  const [featureCount, setFeatureCount] = useState(0);
  const featureCountRef = useRef(featureCount);

  const spaceConnection = useRef(null);
  const messageConnection = useRef(null);

  const incrementLoadingItem = () => {
    featureCountRef.current++;
    console.log(`incrementLoadingItem ${featureCountRef.current}`);
    setFeatureCount(featureCountRef.current);
  };

  const logout = (details) => {
    setAccountDetails({
      ...AccountDetailsStruct,
      baseUrl: details.baseUrl,
      apiUrl: details.apiUrl,
    });
    setCurrentUser(UserStruct);
    window.history.replaceState(null, null, "/");
    navigate("/");
  };

  useEffect(() => {
    if (renderCount.current === 0) {
      return;
    }
    console.log("Need to reload the user");
  }, [currentUser]);

  useEffect(() => {
    if (renderCount.current === 0) {
      return;
    }
    console.log(
      "changes made to the space list, new length = " + spaceList.length
    );
  }, [spaceList]);

  useEffect(() => {
    if (spaceList.length > 0) {
      incrementLoadingItem();
      console.log("SpaceList has loaded");
    }
  }, [spaceList]);

  useEffect(() => {
    if (messageItemList.spaceId !== activeSpace.id) {
      console.log(
        `Loading messages from: ${accountDetails.baseUrl}/messageList => ${activeSpace.name} [${activeSpace.id}]`
      );

      MessageItemLoader(
        accountDetails,
        activeSpace,
        currentUser,
        messageItemDispatcher
      );
    }
  }, [accountDetails, activeSpace]);

  useEffect(() => {
    if (
      spaceList.length > 0 &&
      userList.length > 0 &&
      currentUser.id !== undefined
    ) {
      // Validation for initialized struct in context.
      if (spaceList.length === 1 && spaceList[0].id === "") {
        return;
      }

      const selectedSpace = findSpace(
        spaceList,
        currentUser.lastSpaceId,
        currentUser
      );
      console.log(`Checking space list changes: ${selectedSpace.name}`);
      if (selectedSpace && selectedSpace.id !== activeSpace.id) {
        console.log(
          `Checking space list changes - change active space: ${selectedSpace.name}`
        );
        setActiveSpace(selectedSpace);
      }
    }
  }, [userList, currentUser]);

  useEffect(() => {
    if (activeSpace.id !== "") {
      setShowSearchScreen(false);
    }
  }, [activeSpace]);

  /**
   * Initialization
   *
   * UserStruct = {
   *     id: "",
   *     firstName: "",
   *     lastName: "",
   *     displayName: "",
   *     thumbnail: "",
   *     lastSpaceId: ""
   * }
   */
  useEffect(() => {
    if (accountDetails.accountId === 0) {
      console.log(`Waiting for account details to be set`);
    }
    console.log(
      `Loading users - currently selected user id = ${accountDetails.currentUserId}`
    );

    UserOperationTask(UserOperations.LOAD_USERS, {
      accountDetails: accountDetails,
      setCurrentUser: setCurrentUser,
    }).then((response) => {
      const user = response.find(
        (user) => user.id === accountDetails.axial.user.id
      );
      setCurrentUser({ ...currentUser, companies: [...user.companies] }); //Update companies from loadApiUser data.
      setUserList(response);
    });

    console.log(`=================================================`);
    console.log(`\t\tReloading Connections to SignalR`);
    console.log(`=================================================`);
    /* Connection to Spaces Hub */
    SpaceConnection(accountDetails.baseUrl, {
      spaceList: spaceListDispatch,
    }).then((connection) => (spaceConnection.current = connection));

    /* Connection to Messages Hub */
    MessageConnection(accountDetails.baseUrl, {
      messageItemList: messageItemDispatcher,
    }).then((connection) => (messageConnection.current = connection));

    /* Load Initial Space Data */
    SpaceListLoader(accountDetails, spaceListDispatch);
  }, [accountDetails]);

  /**
   * Tear down - component will unmount
   */
  useLayoutEffect(() => {
    return () => {
      // Need to supply signalr stops etc.
      console.log("MessageContainer - ComponentWIllUnmount");
      const closeConnection = async () => {
        // Spaces
        if (spaceConnection.current !== null) {
          try {
            await spaceConnection.current.stop();
            spaceConnection.current = null;
          } catch (error) {
            console.error(error);
          }
        }
        // Messages
        if (messageConnection.current !== null) {
          try {
            await messageConnection.current.stop();
            messageConnection.current = null;
          } catch (error) {
            console.error(error);
          }
        }
      };
      closeConnection().then((data) => {
        return data;
      });
    };
  }, []);

  const render = () => {
    if (accountDetails.axial.account.id.length > 0) {
      return (
        <>
          <Root
            scheme={{
              header: {
                config: {
                  md: {
                    clipped: true,
                    height: 64,
                    position: "relative",
                  },
                  xs: {
                    position: "sticky",
                    height: 56,
                  },
                },
              },
              leftEdgeSidebar: {
                autoCollapse: "sm",
                config: {
                  sm: {
                    collapsedWidth: 64,
                    collapsible: true,
                    variant: "permanent",
                    width: 256,
                    headerMagnetEnabled: true,
                  },
                  xs: {
                    variant: "temporary",
                    width: 256,
                  },
                },
              },
            }}
          >
            {({ setOpen }) => (
              <>
                <Header>
                  <MessageHeaderContainer data={data} />
                </Header>
                <EdgeSidebar anchor="left">
                  {({ state }) => (
                    <>
                      <SidebarContent>
                        {data.nav && (
                          <MessageSpaceSectionContainer
                            handleSidebar={setOpen}
                            sidebarState={state}
                            collapsed={state.leftEdgeSidebar?.collapsed}
                          />
                        )}
                      </SidebarContent>
                      <EdgeTrigger
                        target={{ anchor: "left", field: "collapsed" }}
                      >
                        {(collapsed, setCollapsed) => (
                          <>
                            <ButtonBase
                              onClick={() => logout(accountDetails)}
                              sx={{ flexGrow: 0.5, height: 48 }}
                            >
                              {collapsed ? <LogoutIcon /> : "Logout"}
                            </ButtonBase>
                            <ButtonBase
                              onClick={() => setCollapsed(!collapsed)}
                              sx={{ flexGrow: 0.5, height: 48 }}
                            >
                              {collapsed ? (
                                <KeyboardArrowRight />
                              ) : (
                                <KeyboardArrowLeft />
                              )}
                            </ButtonBase>
                          </>
                        )}
                      </EdgeTrigger>
                    </>
                  )}
                </EdgeSidebar>
                <Content>
                  {showSearchScreen ? <Search /> : <MessageRecordContainer />}
                </Content>
                {!showSearchScreen && <MessageFooterContainer data={data} />}
              </>
            )}
          </Root>
        </>
      );
    } else {
      return (
        <>
          <Box
            sx={{
              display: "flex",
              height: "100vh",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              mt: 2,
            }}
          >
            <CircularProgress size={80} />
          </Box>
        </>
      );
    }
  };

  return render();
}
