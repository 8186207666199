import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import Calendar from "react-calendar";
import TimePickerField from "./TimePickerField";

const DateTimePicker = ({ onChange }) => {
  const [calendarValue, setCalendarValue] = useState();
  const [timerValue, setTimerValue] = useState();

  useEffect(() => {
    //It only works for every case if the state is a completly new object.
    //For that reason we have to recreate the object every time.
    let value = calendarValue ? new Date(calendarValue.getTime()) : new Date();
    if (timerValue !== undefined) {
      const timeDate = new Date(timerValue);
      value.setHours(timeDate.getHours());
      value.setMinutes(timeDate.getMinutes());
      value.setSeconds(0);
    }
    onChange(value);
  }, [calendarValue, timerValue]);

  return (
    <CalendarContainer>
      <Calendar
        onChange={(value) => setCalendarValue(value)}
        value={calendarValue}
      />
      <TimePickerField
        label={"Time"}
        className="col-12"
        date={timerValue}
        onChange={(val) => setTimerValue(val)}
        style={{ display: "flex", marginTop: 12 }}
      />
    </CalendarContainer>
  );
};

DateTimePicker.propTypes = {
  show: PropTypes.bool,
  hideModal: PropTypes.func,
  onSave: PropTypes.func,
};

export default DateTimePicker;

export const CalendarContainer = styled.div`
  /* container style */

  .react-calendar {
    margin-top: 15px;
    background-color: #212b38;
  }
  .react-calendar__month-view__days__day--weekend {
    font-weight: bold;
  }
  .react-calendar__tile--now {
    background-color: #726eff;
  }
  .react-calendar__tile:hover {
    background-color: grey;
  }
`;
