import React from "react";
import {
  ComponentRef, getComponentRef,
  isObject,
  renderMessageObject,
  renderMessageText,
} from "./MessageTagUtil";

export const MessageHashTagTemplate = {
  component: "MessageHashTag",
  data: {
    content: [],
  },
};

/**
 * {
 *     "component": "MessageHashTag",
 *     "data": {
 *          "content": [
 *              objects or strings
 *          ]
 *     }
 * }
 */
export const MessageHashTag = (props) => {
  const { objectData, handleSubComponentLoaded = (subComponent = {...ComponentRef}, isLoaded = false) => {}, messageTime, itemIndex, level } = props;

  const renderHashTagObject = () => {
    const content = objectData.content;
    if (content) {
      return (
        <span
          key={`hashTag-${messageTime}-${itemIndex}-${level}`}
          style={{ fontWeight: "bold", color: "#efec8b" }}
        >
          {content.map((contentItem) => {
            if (isObject(contentItem)) {
              return renderMessageObject(contentItem); // if this really is an option, need to flesh it out
            }
            return contentItem;
          })}
        </span>
      );
    }
    return <></>;
  };

  const renderTag = () => {
    if (isObject(objectData)) {
      const preRender = renderHashTagObject();
      handleSubComponentLoaded(getComponentRef("MessageHashTag [Tag]", itemIndex, level, messageTime), true);
      return preRender;
    }
    handleSubComponentLoaded(getComponentRef("MessageHashTag [Text]", itemIndex, level, messageTime), true);
    return renderMessageText(objectData)
  }

  return renderTag();
};
