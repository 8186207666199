import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Calendar } from "react-date-range";
import { TextField } from "../Forms";
import { Input, InputLabel, InputAdornment, FormControl } from "@mui/material";
import { Guid } from "../util/Helpers";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

// Must of the code was copied from axial project, some minor changes had to be made to fix library and components
// issues. Styling and classname has to be checked, they may not exist in this project.
class DateTimeField extends Component {
  containerRef;

  constructor(props) {
    super(props);

    let _date = moment({ hour: 0, minute: 0, seconds: 0 });
    let dateAlreadySelected = false;

    let originalValue;

    if (props.date) {
      _date = moment(props.date);
      dateAlreadySelected = true;

      originalValue = _date;
    } else if (props.formField && props.formField.value) {
      _date = moment(props.formField.value);
      dateAlreadySelected = true;

      originalValue = _date;
    } else {
      originalValue = undefined;
    }

    this.state = {
      showContent: false,
      contentStyle: {
        display: "none",
      },
      date: _date,
      time: _date.format("HH:mm"),
      dateAlreadySelected: dateAlreadySelected,
      id: props.id || Guid.raw(),
      originalValue: originalValue,
    };

    this.showContent = this.showContent.bind(this);
    this.hideContent = this.hideContent.bind(this);
    this.toggleShowContent = this.toggleShowContent.bind(this);
    this.handleDateSelect = this.handleDateSelect.bind(this);
    this.handleTimeSelect = this.handleTimeSelect.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.containerRef && !this.containerRef.contains(event.target)) {
      this.hideContent();
    }
  }

  changeYear(year) {
    const formattedDate = moment(this.state.date)
      .year(year)
      .format("YYYY-MM-DDT00:00:00");
    this.handleDateSelect(formattedDate);
  }

  renderHeader() {
    return (
      <div className="row" style={{ paddingBottom: ".1rem" }}>
        <div className="col-12">
          <div className="row mar-btm-sm">
            <div className="col-4">
              <button
                type="button"
                style={{ width: "100%" }}
                className="btn btn-danger"
                onClick={() => {
                  const { onChange, formField } = this.props;

                  if (onChange) {
                    onChange(undefined);
                  }

                  if (formField) {
                    formField.onChange(undefined);
                  }

                  this.setState({
                    ...this.state,
                    date: moment().startOf("day"),
                    dateAlreadySelected: this.state.originalValue != undefined,
                  });
                }}
              >
                Clear
              </button>
            </div>
            <div className="col-4">
              <button
                type="button"
                style={{ width: "100%" }}
                className="btn btn-warning"
                onClick={() => {
                  this.setState({
                    ...this.state,
                    date: this.state.originalValue || moment().startOf("day"),
                    dateAlreadySelected: false,
                  });
                }}
              >
                Reset
              </button>
            </div>
            <div className="col-4">
              <button
                type="button"
                style={{ width: "100%" }}
                className="btn btn-info"
                onClick={() => {
                  const date = moment().startOf("day");

                  const { onChange, formField } = this.props;

                  if (onChange) {
                    onChange(date);
                  }

                  if (formField) {
                    formField.onChange(date);
                  }

                  this.setState({
                    ...this.state,
                    date: date,
                    dateAlreadySelected: true,
                  });
                }}
              >
                Today
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  handleDateSelect(data) {
    const { formField, onChange } = this.props;

    const time = moment(this.state.date, "h:mm a");
    const date = moment(data).set({
      hour: time.hours(),
      minute: time.minutes(),
    });

    if (formField) {
      formField.onChange(date.format("YYYY-MM-DDTHH:mm:ss"));
    }

    if (onChange) {
      onChange(date);
    }

    this.setState({
      ...this.state,
      date: date,
      dateAlreadySelected: true,
    });
  }

  handleTimeSelect(data) {
    const { formField, onChange } = this.props;

    const time = moment(data, "HH:mm");
    const date = this.state.date.set({
      hour: time.hours(),
      minute: time.minutes(),
    });

    if (formField) {
      formField.onChange(date.format("YYYY-MM-DDTHH:mm:ss"));
    }

    if (onChange) {
      onChange(date);
    }

    this.setState({
      ...this.state,
      date: date,
      dateAlreadySelected: true,
    });
  }

  render() {
    const { formField, maxDate, minDate, readOnly, className, label } =
      this.props;

    const date =
      moment(this.state.date) || moment({ hour: 0, minute: 0, seconds: 0 });

    let inputTextValue = date.format("YYYY-MM-DD h:mm a");
    if (!this.state.dateAlreadySelected) {
      inputTextValue = "";
    }

    return (
      <div
        className={`date-field ${className}`}
        ref={(c) => (this.containerRef = c)}
        id={this.state.id}
      >
        <input
          type="hidden"
          name={formField ? formField.name : label}
          value={date.format("YYYY-MM-DDTHH:mm:ss")}
        />
        <FormControl fullWidth={true}>
          <InputLabel htmlFor={this.state.id}>{label}</InputLabel>
          <Input
            id={this.state.id}
            value={inputTextValue}
            disabled={readOnly}
            inputProps={{ onClick: () => this.toggleShowContent() }}
            startAdornment={
              <InputAdornment position="start">
                <PermContactCalendarIcon />
              </InputAdornment>
            }
          />
        </FormControl>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingBottom: ".1rem",
          }}
          className="date-field-content current-content"
        >
          {this.renderHeader()}
          <Calendar
            onChange={(payload) => this.handleDateSelect(payload)}
            disabled={readOnly}
            maxDate={maxDate ? moment(maxDate).toDate() : undefined}
            minDate={maxDate ? moment(minDate).toDate() : undefined}
            date={date.toDate()}
          />
          <TextField
            type="time"
            className="col-12"
            sx={{ marginTop: 2, marginBottom: 2 }}
            onChange={(e) => this.handleTimeSelect(e)}
            value={date.format("HH:mm")}
            readOnly={readOnly}
            isRequired={true}
          />
        </div>
      </div>
    );
  }

  showContent() {
    if (this.state.showContent) {
      return;
    }

    this.setState({
      ...this.state,
      showContent: true,
      contentStyle: {
        display: "block",
      },
    });
  }

  hideContent() {
    if (!this.state.showContent) {
      return;
    }

    this.setState({
      ...this.state,
      showContent: false,
      contentStyle: {
        display: "none",
      },
    });
  }

  toggleShowContent() {
    if (this.state.showContent) {
      this.hideContent();
    } else {
      this.showContent();
    }
  }
}

DateTimeField.muiName = "FormControl";

DateTimeField.propTypes = {
  id: PropTypes.string,
  formField: PropTypes.object,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  date: PropTypes.any,
  className: PropTypes.string,
  maxDate: PropTypes.any,
  minDate: PropTypes.any,
  label: PropTypes.string,
};

export default DateTimeField;
