const regexMapStruct = {
    ref: "a-reference",
    regex: /.*/g,
    tagStart: "<>",
    tagEnd: "</>"
}

const regexMappings = [
    {...regexMapStruct, ref: "bold", regex: /(\*)(?<content>[^*\r\n]+)(\*)/g, tagStart: "<b>", tagEnd: "</b>"},
    {...regexMapStruct, ref: "italic", regex: /(\+)(?<content>[^+]+)(\+)/g, tagStart: "<i>", tagEnd: "</i>"},
    {...regexMapStruct, ref: "underline", regex: /(_)(?<content>[^_]+)(_)/g, tagStart: "<u>", tagEnd: "</u>"},
    {...regexMapStruct, ref: "listItem", regex: /(\r?\n\*)(?<content>[^\r\n]+)/g, tagStart: "<li>", tagEnd: "</li>"},
    {...regexMapStruct, ref: "linebreak", regex: /(\r?\n)(?<content>.*)/g, tagStart: "<br/>", tagEnd: ""},
    {...regexMapStruct, ref: "uList", regex: /(?<content><li>.*<\/li>(?!<li>))/g, tagStart: "<ul>", tagEnd: "</ul>"},
];

function fixupMessage(message) {

    const debug = false;

    if (message === undefined || message === null || message.length === 0) {
        return "";
    }

    debug && console.log(`Raw item => ${message}`);

    regexMappings.map((regexItem) => {
        debug && console.log(`Processing lines => ${message}`);
        const results = message.matchAll(regexItem.regex);
        for (const result of results) {
            const {content} = result.groups;
            debug && console.log(`Processing ${regexItem.ref} => ${content}`);
            message = message.replace(result[0], regexItem.tagStart + content + regexItem.tagEnd);
        }
        return message;
    });
    debug && console.log(`Finished output => ${message}`);
    return message;
}

export default fixupMessage;

export const makePrettySpaceName = (name) => {
    if (name === undefined || name === null || name.length === 0) return;
    let prettyName = name.substring(0, 1).toUpperCase() + name.substring(1, name.length);
    prettyName = prettyName.replaceAll("-"," ");
    return prettyName;
}

export const cleanSearchText = (rawSearchText) => {
    const allowedRegex = /[a-zA-Z0-9-]*/g;
    const searchGroups = rawSearchText.toLowerCase().match(allowedRegex);
    return typeof searchGroups === "object" ? searchGroups[0] : searchGroups;
}