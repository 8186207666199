import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Calendar from "react-calendar";
import { CalendarContainer } from "../../../MaterialForms/DateTimePicker";

const CalendarPlannerModal = ({ show, hideModal, onSave }) => {
  const [plannerCalendarValue, setPlannerCalendarValue] = useState();

  const handleOnSave = () => {
    onSave(plannerCalendarValue);
  };

  const handleOnCancel = () => {
    setPlannerCalendarValue(undefined);
    hideModal();
  };

  return (
    <Dialog open={show} onClose={hideModal}>
      <DialogTitle>{`Daily Planner`}</DialogTitle>
      <DialogContent>
        {`Add this to Daily Planner`}
        {
          <CalendarContainer>
            <Calendar
              onChange={(value) => setPlannerCalendarValue(value)}
              value={plannerCalendarValue}
            />
          </CalendarContainer>
        }
      </DialogContent>
      <DialogActions>
        <Button
          style={{ color: "white", fontWeight: "bold" }}
          onClick={handleOnSave}
          disabled={!plannerCalendarValue}
        >{`Save`}</Button>
        <Button
          style={{ color: "black", fontWeight: "bold" }}
          onClick={handleOnCancel}
        >{`Cancel`}</Button>
      </DialogActions>
    </Dialog>
  );
};

CalendarPlannerModal.propTypes = {
  show: PropTypes.bool,
  hideModal: PropTypes.func,
  onSave: PropTypes.func,
};

export default CalendarPlannerModal;
