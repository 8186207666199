import React, {useEffect, useState} from "react";
import { Box } from "@mui/material";
import {ComponentRef, getComponentRef} from "./MessageTagUtil";
import {getElementToRender} from "../../../CustomForms/RenderElements/ElementDefinitions";

export const MessageStaffNoteTagTemplate = {
    component: "MessageStaffNoteTag",
};

/**
 * {
 *     "component": "MessageLogTag",
 *     "data": {
 *          formData: [{id: '', value: '', formElement: '', icon: '', label: '', formElementDisplayName: ''}],
 *          logTypeId: '',
 *          logTypeName: ''
 *     }
 * }
 */
const initializeState = (data) => {
    if (data) {
        return data.map((item, index) => {
            return {subIndex: index, isLoaded: false};
        });
    }
    return [];
}

export function MessageStaffNoteTag(props) {
    const { objectData, handleSubComponentLoaded = (subComponent = {...ComponentRef}, isLoaded = false) => {}, messageTime, itemIndex, level } = props;

    const [subComponents, setSubcomponents] = useState(initializeState(objectData.formData));

    useEffect(() => {
        if (objectData.formData) {
            // console.log(`MessageStaffNoteTag subcomponents changed`);
            const missingCount = subComponents.filter((c) => !c.isLoaded).length;
            if (missingCount === 0 && subComponents.length === objectData.formData.length) {
                handleSubComponentLoaded(getComponentRef("MessageStaffNoteTag [List]", itemIndex, level, messageTime), true);
            }
        }
    }, [subComponents]);

    const handleSubComponents = (subIndex = 0, subComponent = {...ComponentRef}, isLoaded = false) => {
        // console.log(`[MessageStaffNoteTag] Form Element Loading -> [${subIndex}] ${subComponent.componentName} = ${isLoaded}`);
        const findSubComponent = subComponents.find((c) => c.subIndex === subIndex);
        if (isLoaded && findSubComponent.isLoaded !== isLoaded) {
            setSubcomponents((prevState) => {
                const filteredState = prevState.filter((item) => item.subIndex !== subIndex);
                return [...filteredState, {subIndex: subIndex, isLoaded: isLoaded}];
            });
        }
    }

    const renderFormElements = () => {
        const content = objectData.formData;
        if (content) {
            return content.map((element, index) => {
                element.readOnly = true;
                element.handleSubComponentLoaded = (subComponent, isLoaded) => handleSubComponents(index, subComponent, isLoaded);
                element.itemIndex = itemIndex;
                element.level = level;
                element.messageTime = messageTime;
                element.key = `form-element-${messageTime}-${itemIndex}-${level}-${index}`;
                return getElementToRender(element, () => {}, "");
            });
        }
        return <></>;
    }

    const renderTag = () => {
        return <>
            <Box key={`log-${messageTime}-${itemIndex}-${level}`}>
                {renderFormElements()}
            </Box>
        </>;
    }

    return renderTag();
}
