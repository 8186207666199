import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import PropTypes from "prop-types";
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import { NEW_GUID } from "../constants";

const MultipleSelect = forwardRef(
  ({ data, label, disabled = false, onChange }, ref) => {
    const [selectedId, setSelectedId] = useState(NEW_GUID);
    const [pickerList, setPickerList] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);

    useImperativeHandle(ref, () => ({
      loadSelectedItems(itemId) {
        loadSelectedItems(itemId);
      },
      clearSelectedItems() {
        setSelectedItems([]);
      },
    }));

    const loadSelectedItems = (itemIds) => {
      let itemsSelected = [];
      let filteredItems = data;

      itemIds.map((_itemId) => {
        const item = data.find((u) => u.value === _itemId);
        filteredItems = filteredItems.filter((i) => i.value !== _itemId);
        itemsSelected.push(item);
      });

      setPickerList(filteredItems);
      setSelectedItems(itemsSelected);
      onChange(itemsSelected);
    };

    const handleItemAdd = (itemId) => {
      if (itemId === undefined || itemId === NEW_GUID) {
        return;
      }
      const item = data.find((u) => u.value === itemId);
      const alreadyAdded =
        selectedItems.find((u) => u.value === itemId) !== undefined;
      if (!alreadyAdded && item !== undefined) {
        setSelectedItems((previous) => [...previous, item]);
        setPickerList(pickerList.filter((u) => item.value !== u.value));
        onChange([...selectedItems, item]);
      }
      setSelectedId(NEW_GUID);
    };

    const handleItemRemove = (itemId) => {
      if (itemId === undefined || itemId === NEW_GUID) {
        return;
      }
      const item = data.find((u) => u.value === itemId);
      const filteredItemList = selectedItems.filter((u) => u.value !== itemId);
      setPickerList((previous) => [...previous, item]);
      setSelectedItems(filteredItemList);
      onChange(filteredItemList);
    };

    useEffect(() => {
      setPickerList(data);
    }, [data]);

    return (
      <Box>
        <FormControl
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: 2,
            marginBottom: 1,
          }}
          fullWidth
        >
          <InputLabel>{label}</InputLabel>
          <Select
            label={label}
            value={selectedId}
            onChange={(e) => setSelectedId(e.target.value)}
            fullWidth
          >
            {pickerList.map((c) => (
              <MenuItem key={c.value} value={c.value}>
                {c.label}
              </MenuItem>
            ))}
          </Select>
          <IconButton
            sx={{
              height: 20,
              width: 20,
              marginLeft: 2,
            }}
            onClick={() => handleItemAdd(selectedId)}
          >
            <AddIcon color={"secondary"} />
          </IconButton>
        </FormControl>
        {selectedItems.map((item) => (
          <Box
            key={item.value}
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: 5,
            }}
          >
            <IconButton onClick={() => handleItemRemove(item.value)}>
              <IndeterminateCheckBoxIcon color={"error"} />
            </IconButton>
            <Typography sx={{ marginLeft: 1 }}>{`${item.label}`}</Typography>
          </Box>
        ))}
      </Box>
    );
  }
);

MultipleSelect.propTypes = {
  data: PropTypes.array, // Array of objects with value and label parameters.
  label: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default MultipleSelect;
