import axios from "axios";

import { AuthActions } from "../data/AuthActions";
import {
  AxialResponseData,
  AxialTokenResponse,
} from "../../../models/AxialResponseData";
import { ApiEndPoints } from "../../../data/ApiEndPoints";
import { proxyOptions, proxyOptionsWithToken } from "../../util/ProxyOptions";
import { AxiosErrorHandler } from "../../util/AxiosHelper";

export const AuthReducer = (state, action) => {
  console.log("AuthReducer [" + action.type + "]");
  switch (action.type) {
    default:
      return state;
  }
};

export const AuthControllerTasks = (action, data) => {
  console.log(`AuthControllerTasks :: ${action}`);
  switch (action) {
    case AuthActions.SEND_AXIAL_AUTH_DETAILS:
      sendAxialAuthDetails(data.accountDetails, data.authData, data.callback);
      return;
    case AuthActions.GET_AXIAL_SESSION_DATA:
      getSessionInformation(data.accountDetails, data.token, data.callback);
      return;
    default:
      return;
  }
};

const sendAxialAuthDetails = (accountDetails, authData, callback) => {
  const sendDetails = async () => {
    return await axios.post(
      `${accountDetails.apiUrl}/${ApiEndPoints.TOKEN}`,
      { ...authData },
      proxyOptions
    );
  };
  sendDetails()
    .then((response) => {
      callback(response.status, authData, populateTokenResponse(response.data));
    })
    .catch((error) => {
      AxiosErrorHandler(error, callback);
    });
};

const populateTokenResponse = (data) => {
  return { ...AxialResponseData, result: { ...AxialTokenResponse }, ...data };
};

const getSessionInformation = (accountDetails, token, callback) => {
  const getSessionAsync = async () => {
    return await axios.get(
      `${accountDetails.apiUrl}/${ApiEndPoints.SESSION_STATUS}`,
      proxyOptionsWithToken(token)
    );
  };
  getSessionAsync()
    .then((response) => {
      if (response.data.result !== null) {
        callback(response.data.result, token);
      }
    })
    .catch((error) => {
      AxiosErrorHandler(error, callback);
    });
};

/*
const sendInternalAuthDetails = (accountDetails, callback) => {
    const sendSyncDetails = async () => {
        return await axios.post(`${accountDetails.baseUrl}/syncAccount`, {
            ...accountDetails.axial
        }, proxyOptionsWithToken(accountDetails.token));
    }
    sendSyncDetails()
        .then((response) => {
            // Update Form or Send to App
            console.log(`${JSON.stringify(response.data)}`)
            callback(response.status, response.data);
        })
        .catch((error) => {
                AxiosErrorHandler(error);
            }
        );
}
*/
