import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Box,
  Typography,
  DialogActions,
  Button,
} from "@mui/material";
import YouTubePlayer from "./YouTubePlayer";
import { MessageYouTubeTagTemplate } from "../records/MessageYouTubeTag";

const YouTubeModal = ({ show, hideModal, onSave }) => {
  const [youTubeLink, setYouTubeLink] = useState("");
  const [videoId, setVideoId] = useState("");

  const handleOnSave = () => {
    if (videoId !== "") {
      const youTubeTag = {
        ...MessageYouTubeTagTemplate,
        data: { videoId: videoId },
      };
      onSave(getTagKey, youTubeTag);
      handleOnClose();
    }
  };

  const handleOnClose = () => {
    cleanForm();
    hideModal();
  };

  const cleanForm = () => {
    setYouTubeLink("");
    setVideoId("");
  };

  const getTagKey = () => {
    return `youtube-${videoId}`;
  };

  const getVideoId = (link) => {
    if (!link) {
      return "";
    }
    //const regex = /[a-zA-Z\d_-]{11}$/g; // Old regex.
    const regex =
      /(?:[?&]v=|\/embed\/|\/1\/|\/v\/|https:\/\/(?:www\.)?youtu\.be\/)([^&\n?#]+)/;
    const result = link.match(regex);
    if (result === null) {
      return "";
    }
    return typeof result === "object" ? result[result.length - 1] : result;
  };

  useEffect(() => {
    if (
      youTubeLink &&
      youTubeLink.length > 11 &&
      youTubeLink.startsWith("https://")
    ) {
      const _videoId = getVideoId(youTubeLink);
      if (_videoId.length === 11) {
        setVideoId(_videoId);
      } else {
        setVideoId("");
      }
    } else {
      setVideoId("");
    }
  }, [youTubeLink]);

  return (
    <Dialog open={show} onClose={handleOnClose} maxWidth="md" fullWidth={true}>
      <DialogTitle>{`YouTube Selector`}</DialogTitle>
      <DialogContent>
        <TextField
          label={"Paste/Type Link"}
          value={youTubeLink}
          onChange={(e) => setYouTubeLink(e.target.value)}
          sx={{ marginTop: 1 }}
          fullWidth
        />
        {videoId !== "" ? (
          <YouTubePlayer style={{ marginTop: 2 }} embedId={videoId} />
        ) : (
          <Box
            sx={{
              height: 480,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#212121",
              marginTop: 2,
            }}
          >
            <Typography>{"No Video Selected"}</Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={handleOnSave}
          disabled={videoId === ""}
        >
          {"Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

YouTubeModal.propTypes = {
  show: PropTypes.bool,
  hideModal: PropTypes.func,
  onSave: PropTypes.func,
};

export default YouTubeModal;
