import {SignalRConnection} from "./SignalRConnection";
import {MessageOperations} from "../data/MessageData";
import {isDevelopment} from "../../../App";

export const MessageConnection = (baseUrl, dispatchers) => {
    const HUB_NAME = "messages";

    const signalRConnection = SignalRConnection(baseUrl, HUB_NAME);

    const getJsonObject = (postData) => {
        return JSON.parse(postData);
    }

    signalRConnection.then((connection) => {
        connection.on("messageRecordDelete", responseData => {
            if(isDevelopment) {
                console.log("Message to be deleted: [" + responseData + "]");
            }
            dispatchers.messageItemList({
                type: MessageOperations.DELETE_MESSAGE,
                ref: getJsonObject(responseData)});
        });

        connection.on("messageRecordAdd", responseData => {
            if(isDevelopment) {
                console.log("Message to be added: [" + responseData + "]");
            }
            dispatchers.messageItemList({
                type: MessageOperations.ADD_MESSAGE,
                ref: getJsonObject(responseData)
            });
        });

        connection.on("messageRecordUpdate", responseData => {
            if(isDevelopment) {
                console.log("Message to be updated: [" + responseData + "]");
            }
            dispatchers.messageItemList({
                type: MessageOperations.UPDATE_MESSAGE,
                ref: getJsonObject(responseData)
            });
        });
    });

    return signalRConnection;
};

