import { useEffect, useState } from "react";
import {
  ComponentRef,
  getComponentRef,
  renderMessageObject,
  renderMessageText,
} from "./MessageTagUtil";
import { Skeleton } from "@mui/material";

function MessageTag(props) {
  const {
    objectData,
    messageTime = 0,
    itemIndex = "",
    level = 0,
    loadedCallback = (isLoaded = false) => {},
  } = props;

  const [renderString, setRenderString] = useState(null);
  const [isComponentLoaded, setIsComponentLoaded] = useState(false);
  const [subTagsLoaded, setSubTagsLoaded] = useState(false);

  useEffect(() => {
    if (isComponentLoaded && subTagsLoaded) {
      loadedCallback(isComponentLoaded && subTagsLoaded);
    }
  }, [isComponentLoaded, subTagsLoaded]);

  const handleComponentLoaded = (isAlreadyLoaded = false, isLoaded = false) => {
    if (!isAlreadyLoaded && isLoaded) {
      setIsComponentLoaded(true);
    }
  };

  const handleSubComponentLoaded = (
    component = { ...ComponentRef },
    isLoaded = false
  ) => {
    if (isLoaded) {
      // console.log(`[MessageTag] [${messageTime}] SubComponent Loading -> ${component.componentName} = ${isLoaded}`);
      setSubTagsLoaded(true);
    }
  };

  useEffect(() => {
    checkJSONContent();
  }, [objectData]);

  const checkJSONContent = () => {
    // Initial Checks
    if (objectData === undefined || objectData === null) {
      handleSubComponentLoaded(
        getComponentRef(
          "MessageTag [Undefined]",
          itemIndex,
          level,
          messageTime
        ),
        true
      );
      return;
    }
    if (objectData.indexOf("{") < 0 || objectData.indexOf("}") < 0) {
      handleSubComponentLoaded(
        getComponentRef("MessageTag [Text]", itemIndex, level, messageTime),
        true
      );
      setRenderString(renderMessageText(objectData));
      return;
    }

    try {
      const jsonTest = JSON.parse(objectData);
      if (jsonTest.component && jsonTest.data) {
        setRenderString(
          renderMessageObject(
            jsonTest,
            handleSubComponentLoaded,
            messageTime,
            itemIndex,
            0
          )
        );
      }
    } catch (error) {}
  };

  const renderData = () => {
    if (renderString === null) {
      handleComponentLoaded(isComponentLoaded, false);
      return <Skeleton variant="rounded" width={210} height={60} />;
    }
    handleComponentLoaded(isComponentLoaded, true);
    return renderString;
  };

  return renderData();
}

export default MessageTag;

/**
 * EXAMPLE
 * {
 *     component: Name,
 *     data: {
 *
 *     }
 * }
 *
 * e.g. HTML - Here is some <b>bold</b> text and here is <b>bold and <i>italic</i></b>
 *
 * {
 *     component: MessageTextTag,
 *     data: {
 *         content: [
 *             "Here is some ",
 *             {
 *                 component: MessageBoldTag,
 *                 data: {
 *                     content: [
 *                         "bold"
 *                     ]
 *                 }
 *             },
 *             " text and here is ",
 *             {
 *                 component: MessageBoldTag,
 *                 data: {
 *                     content: [
 *                         "bold and ",
 *                         {
 *                             component: MessageItalicTag,
 *                             data: {
 *                                 content: [
 *                                     "italic"
 *                                 ]
 *                             }
 *                         }
 *                     ]
 *                 }
 *             },
 *             {
 *                 component: MessageListTag,
 *                 data: {
 *                     content: [
 *                         "Basic list item",
 *                         {
 *                             component: MessageTextTag,
 *                             data: {
 *                                 content: [
 *                                     "Still basic but with a dedicated tag"
 *                                 ]
 *                             }
 *                         },
 *                         {
 *                             component: MessageTextTag,
 *                             data: {
 *                                 content: [
 *                                     "A more complex ",
 *                                     {
 *                                         component: MessageBoldTag,
 *                                         data: {
 *                                             content: [
 *                                                 "and visually ",
 *                                                 {
 *                                                      component: MessageItalicTag,
 *                                                      data: {
 *                                                          content: [
 *                                                              "involved"
 *                                                          ]
 *                                                      }
 *                                                 },
 *                                                 " markup"
 *                                             ]
 *                                         }
 *                                     }
 *                                 ]
 *                             }
 *                         },
 *                         "... and back to basics"
 *                     ]
 *                 }
 *             }
 *         ]
 *     }
 * }
 */
