export const proxyOptions = {
    headers: {
        "Accept": "*/*",
        "Content-Type": "application/json",
        "x-api-version": "3.0"
    }
}

export const proxyOptionsWithToken = (token) => {
    return {
        headers: {
            "Accept": "*/*",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
            "x-api-version": "3.0"
        }
    }
}

export const proxyGetOptionsWithToken = (token) => {
    return {
        headers: {
            "Accept": "*/*",
            "Authorization": `Bearer ${token}`,
            "x-api-version": "3.0"
        },
        decompress: true,
        responseType: 'arraybuffer'
    }
}
