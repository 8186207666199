import {ComponentRef, getComponentRef, isObject, renderMessageObject, renderMessageText} from "./MessageTagUtil";

const MessageListTagDataTemplate = {
    listOrdered: true,
    content: []
}

export const MessageListTagTemplate = {
    component: "MessageListTag",
    data: {...MessageListTagDataTemplate}
}

/**
 * {
 *     "component": "MessageListTag",
 *     "data": {
 *          "listOrdered": true
 *          "content": [
 *              objects or strings
 *          ]
 *     }
 * }
 */
export function MessageListTag(props) {
    const {objectData, handleSubComponentLoaded = (subComponent = {...ComponentRef}, isLoaded = false) => {}, messageTime, itemIndex, level} = props;

    const handleListItemComponentLoading = (component = {...ComponentRef}, isLoaded = false) => {
        console.log(`handleListItemComponentLoading ${component.componentName} - ${isLoaded}`);
    }

    const getListContents = (content = []) => {
        return content.map((contentItem, index) => { // need to handle this
            if (isObject(contentItem)) {
                return <li key={index}>{renderMessageObject(contentItem, handleListItemComponentLoading, messageTime, itemIndex, level)}</li>;
            }
            return <li key={index}>{contentItem}</li>;
            }
        )
    }

    const renderListObject = (data = MessageListTagDataTemplate) => {
        const content = data.content;
        const isOrdered = data.listOrdered;
        if (content) {
            if (isOrdered) {
                return <ol key={`list-ol-${messageTime}-${itemIndex}-${level}`}>{getListContents(content)}</ol>
            } else {
                return <ul key={`list-ul-${messageTime}-${itemIndex}-${level}`}>{getListContents(content)}</ul>
            }
        }
        return <></>;
    }

    const renderTag = () => {
        handleSubComponentLoaded(getComponentRef("MessageListTag", itemIndex, level, messageTime), true);
        if (isObject(objectData)) {
            return renderListObject(objectData);
        }
        return renderMessageText(objectData)
    }

    return renderTag();
}
