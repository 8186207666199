export const MessageOperations = {
  INITIALIZE: "INITIALIZE",
  LOAD_MESSAGE_LIST: "LOAD_MESSAGE_LIST",
  ADD_TOP_MESSAGE_LIST: "ADD_TOP_MESSAGE_LIST",
  ADD_BOTTOM_MESSAGE_LIST: "ADD_BOTTOM_MESSAGE_LIST",
  ADD_MESSAGE: "ADD_MESSAGE",
  UPDATE_MESSAGE: "UPDATE_MESSAGE",
  DELETE_MESSAGE: "DELETE_MESSAGE",
  SEND_ADD_MESSAGE: "SEND_ADD_MESSAGE",
  SEND_DELETE_MESSAGE: "SEND_DELETE_MESSAGE",
  SEND_UPDATE_MESSAGE: "SEND_UPDATE_MESSAGE",
};

export const MessageItemStruct = {
  id: "",
  createdUtc: new Date(),
  accountId: "",
  companyId: "",
  spaceId: "",
  createdById: "",
  message: "",
  isVisible: false,
  marker: 0,
};

export const MessageItemListStruct = {
  accountId: "",
  companyId: "",
  spaceId: "",
  messageList: [{ ...MessageItemStruct }],
  deletedIds: [],
  firstPageLoaded: 0,
  startIndex: 0,
  isTopEndReached: false,
  isBottomEndReached: false,
  totalMessageCount: 0,
  pageCount: 0,
  lastPageLoaded: 0,
};
