import React, { useEffect, useRef, useContext, useState } from "react";
import {
  Alert,
  Grid,
  List,
  Paper,
  Snackbar,
  TextField,
  Box,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Divider,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment";

import { AppContext } from "../../message/contexts/AppContext";
import useWindowDimensions from "../../util/WindowDimensions";
import MultipleSelect from "../../MaterialForms/MultipleSelectField";
import { UserListContext } from "../../message/models/UserListContext";
import { UserContext } from "../../message/models/UserContext";
import { canShowMember } from "../../message/controllers/UserController";
import { SpaceContext } from "../../message/contexts/SpaceContext";
import { isSpaceMember } from "../../message/controllers/SpaceController";
import RecordListWrapper from "../../message/components/records/RecordListWrapper";
import { api } from "../../../App";
import { AxiosErrorHandler } from "../../util/AxiosHelper";

export default function Search() {
  const [searchResults, setSearchResults] = useState([]);
  const [keywords, setKeywords] = useState("");
  const [selectedSenders, setSelectedSenders] = useState([]);
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState(-1);
  const [channelsPicker, setChannelsPicker] = useState([]);
  const [authorizedUsersPicker, setAuthorizedUsersPicker] = useState([]);

  const { snackbar, setSnackbar } = useContext(AppContext.SnackbarContext);
  const { userList } = useContext(UserListContext.UserListContext);
  const { currentUser } = useContext(UserContext.UserContext);
  const { spaceList } = useContext(SpaceContext.SpaceListContext);

  const { height } = useWindowDimensions();

  const sendersMultipleSelectRef = useRef();
  const channelsMultipleSelectRef = useRef();

  useEffect(() => {
    const users = [];
    userList.map((u) => {
      if (canShowUser(u) && !u.isSuperUser) {
        users.push({
          value: u.id,
          label: u.displayName,
        });
      }
      return u;
    });
    users.sort((thisName, otherName) => {
      const thisSearchTerm = thisName.label.toString().toLowerCase();
      const otherSearchTerm = otherName.label.toString().toLowerCase();
      if (thisSearchTerm > otherSearchTerm) {
        return 1;
      }
      if (thisSearchTerm < otherSearchTerm) {
        return -1;
      }
      return 0;
    });
    setAuthorizedUsersPicker(users);
  }, [userList]);

  useEffect(() => {
    const formattedChannels = [];
    spaceList.map((item) => {
      if (isSpaceMember(item, currentUser)) {
        formattedChannels.push({ label: item.name, value: item.id });
      }
      return item;
    });
    formattedChannels.sort((a, b) => {
      const elementA = a.label.toString().toLowerCase();
      const elementB = b.label.toString().toLowerCase();
      if (elementA < elementB) {
        return -1;
      }
      if (elementA > elementB) {
        return 1;
      }
      return 0;
    });
    setChannelsPicker(formattedChannels);
  }, [spaceList]);

  const canShowUser = (userTest) => {
    return (
      currentUser.id !== userTest.id && canShowMember(currentUser, userTest)
    );
  };

  const getSearchElements = async () => {
    const payload = {
      keywords: keywords,
      sendersIds: selectedSenders.map((item) => item.value),
      spacesIds: selectedChannels.length
        ? selectedChannels.map((item) => item.value)
        : channelsPicker.map((item) => item.value),
      dates: selectedDateRange === -1 ? null : selectedDateRange,
    };

    const loader = async () => {
      setSnackbar({
        ...snackbar,
        open: true,
        severity: "info",
        message: "Searching for messages!",
      });
      await api.searchMessages(payload).then(
        (response) => setSearchResults(response.data.result),
        (error) => AxiosErrorHandler(error)
      );
    };
    loader().then(
      () => console.log("Loaded Search Results"),
      (error) =>
        console.log(`An error occurred loading search results - ${error}`)
    );
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: snackbar.vertical,
          horizontal: snackbar.horizontal,
        }}
        autoHideDuration={snackbar.duration}
        open={snackbar.open}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        key={snackbar.vertical + snackbar.horizontal}
      >
        <Alert severity={snackbar.severity}>{snackbar.message}</Alert>
      </Snackbar>
      <RecordListWrapper>
        <Paper elevation={3}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{ marginBottom: "8px", padding: "8px" }}
          >
            <Grid item container xs={2}>
              <Grid>
                <SearchIcon />
              </Grid>
              <Grid sx={{ width: "10px" }}> </Grid>
              <Grid>Search</Grid>
            </Grid>
          </Grid>
        </Paper>
        <Paper
          elevation={3}
          sx={{
            height: `${height - 60 - 56 - 45}px`,
            marginBottom: "10px",
            overflowY: "auto",
            flexWrap: "nowrap",
          }}
        >
          <Grid
            sx={{
              margin: "15px 15px 0px",
            }}
            direction="column"
          >
            <InputLabel>Keywords(s)</InputLabel>
            <TextField
              fullWidth
              id="outlined-basic"
              variant="outlined"
              placeholder="ex: invoices"
              onChange={(event) => {
                setKeywords(event.target.value);
              }}
            />
            <InputLabel
              sx={{
                marginTop: "15px",
              }}
            >
              From
            </InputLabel>
            <MultipleSelect
              ref={sendersMultipleSelectRef}
              data={authorizedUsersPicker}
              label={"Select senders"}
              onChange={(values) => setSelectedSenders(values)}
            />
            <InputLabel
              sx={{
                marginTop: "15px",
              }}
            >
              Channel
            </InputLabel>
            <MultipleSelect
              ref={channelsMultipleSelectRef}
              data={channelsPicker}
              label={"Select channels"}
              onChange={(values) => setSelectedChannels(values)}
            />
            <InputLabel
              sx={{
                marginTop: "15px",
              }}
            >
              Date
            </InputLabel>
            <Select
              value={selectedDateRange}
              onChange={(e) => setSelectedDateRange(e.target.value)}
              fullWidth
            >
              <MenuItem value={-1}>Any Time</MenuItem>
              <MenuItem value={0}>Today</MenuItem>
              <MenuItem value={1}>Yesterday</MenuItem>
              <MenuItem value={7}>Last 7 days</MenuItem>
              <MenuItem value={30}>Last 30 days</MenuItem>
              <MenuItem value={90}>Last 3 months</MenuItem>
              <MenuItem value={365}>Last 12 months</MenuItem>
            </Select>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              sx={{ marginTop: "10px", marginBottom: "10px" }}
            >
              <Button
                style={{
                  justifyContent: "center",
                  width: "100px",
                  borderRadius: "20px",
                  marginRight: "15px",
                }}
                variant="contained"
                onClick={() => setSearchResults([])}
              >
                Clear
              </Button>
              <Button
                style={{
                  justifyContent: "center",
                  width: "100px",
                  borderRadius: "20px",
                }}
                variant="contained"
                onClick={() => getSearchElements()}
              >
                Search
              </Button>
            </Grid>
            <Divider />
            <InputLabel
              sx={{
                marginTop: "5px",
              }}
            >
              Results
            </InputLabel>
          </Grid>
          <Grid
            sx={{
              margin: "0px 15px 15px",
            }}
            direction="column"
          >
            {searchResults.map(
              ({ message, spaceName, createdById, createdUtc }) => {
                const user = userList.find(
                  (element) => element.id === createdById
                );
                return (
                  <Box
                    sx={{
                      flexDirection: "column",
                      alignItems: "center",
                      marginVertical: 1,
                      width: "100%",
                      height: "auto",
                    }}
                  >
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-evenly"
                      alignItems="center"
                    >
                      <InputLabel
                        sx={{
                          marginTop: "15px",
                          width: "40%",
                        }}
                      >
                        {user ? user.displayName : ""}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          marginTop: "15px",
                          width: "40%",
                        }}
                      >
                        # {spaceName}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          marginTop: "15px",
                          width: "20%",
                        }}
                      >
                        {moment(createdUtc).format("MMM Do @ h:mm a")}
                      </InputLabel>
                    </Grid>
                    <Button
                      style={{
                        justifyContent: "left",
                        marginBottom: 1,
                        width: "100%",
                        borderRadius: "15px",
                        backgroundColor: "white",
                        color: "black",
                      }}
                      // onClick={() => }
                    >
                      {message}
                    </Button>
                  </Box>
                );
              }
            )}
          </Grid>
        </Paper>
      </RecordListWrapper>
    </>
  );
}
