import React from "react";
import { Box } from "@mui/material";

const Popup = ({ show, items, renderItem, style }) => {
  return (
    <>
      {show && (
        <Box
          sx={[
            {
              display: "flex",
              flexDirection: "column",
              marginBottom: 2,
              borderRadius: 2,
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
              backgroundColor: "#212b38",
              width: "25%",
            },
            style,
          ]}
        >
          {items.map((item, i) => {
            return <Box key={i}>{renderItem(item)}</Box>;
          })}
        </Box>
      )}
    </>
  );
};

export default Popup;
