import { useContext, useState, useEffect } from "react";
import {
  ComponentRef, getComponentRef,
  getFieldNumber,
  getFieldString,
  isObject,
  renderMessageText,
} from "./MessageTagUtil";
import { Box, Typography } from "@mui/material";
import { Buffer } from "buffer";
import { AccountDetailsContext } from "../../models/AccountDetailsContext";
import axios from "axios";
import { proxyGetOptionsWithToken } from "../../../util/ProxyOptions";

const MessageImageTagDataTemplate = {
  title: "",
  height: 150,
  link: "",
  hover: "",
  caption: "",
  requiresToken: false,
};

export const MessageImageTagTemplate = {
  component: "MessageImageTag",
  data: { ...MessageImageTagDataTemplate },
};

/**
 * {
 *     "component": "MessageImageTag",
 *     "data": {
 *          "title": "Matt",
 *          "height": 150,
 *          "link": "https://th.bing.com/th/id/R.3d60cc218f07184ce2f08e0d04f22d00?rik=7pHnmufJJo2b%2bg&pid=ImgRaw&r=0",
 *          "hover": "Matt Woodings",
 *          "caption": "... here's Matty!"
 *     }
 * }
 */
export function MessageImageTag(props) {
  const { objectData, handleSubComponentLoaded = (subComponent = {...ComponentRef}, isLoaded = false) => {}, messageTime = 0, itemIndex = "", level = 0} = props;

  const { accountDetails } = useContext(
    AccountDetailsContext.AccountDetailsContext
  );

  const [imageData, setImageData] = useState("");
  const [filename, setFilename] = useState("");
  const [contentType, setContentType] = useState("");

  useEffect(() => {
    const requiresToken = objectData.requiresToken
      ? objectData.requiresToken
      : false;
    if (requiresToken) {
      const imageLink = `${accountDetails.apiUrl}${objectData.link}`;
      console.log(`Image Link = ${imageLink}`);

      axios.get(imageLink, proxyGetOptionsWithToken(accountDetails.token)).then(
        (response) => {
          console.log(`Image Response: ${JSON.stringify(response.headers)}`);
          setFilename(
            response.headers["content-disposition"]
              ? getAltText(response.headers["content-disposition"])
              : "image"
          );
          setContentType(response.headers["content-type"]);
          const imageBase64Data = Buffer.from(response.data).toString("base64");
          const imageLink = `data:${contentType};charset=utf-8;base64,${imageBase64Data}`;
          setImageData(imageLink);
          handleSubComponentLoaded(getComponentRef("MessageImageTag [Image]", itemIndex, level, messageTime), true);
        },
        (error) => console.log(`Error occurred: ${error}`)
      );
    }
  }, []);

  const getAltText = (text) => {
    const regex = /filename=([^;]+);/;
    const regexResults = text.match(regex);
    return regexResults[regexResults.length - 1];
  };

  const getInternetImage = () => {
    return objectData.link;
  };

  const getAxialImage = () => {
    //console.log(`Image Tag = ${imageData.length}`);
    return imageData;
  };

  const getImage = (isAxial, width, height) => {
    return (
      <Box>
        <img
          alt={filename}
          height={height}
          src={isAxial ? getAxialImage() : getInternetImage()}
        />
      </Box>
    );
  };

  const renderImageObject = (objectData = MessageImageTagDataTemplate) => {
    const requiresToken = objectData.requiresToken
      ? objectData.requiresToken
      : false;
    const title = getFieldString(objectData.title);
    const caption = getFieldString(objectData.caption);
    const width = getFieldNumber(objectData.width, 120);
    const height = getFieldNumber(objectData.height, 120);

    if (imageData.length > 0) {
      return (
        <>
          {title.length > 0 ? (
            <Typography
              key={`image-title-${messageTime}-${itemIndex}-${level}`}
              variant="h5"
            >
              {title}
            </Typography>
          ) : null}
          {getImage(requiresToken, width, height)}
          {caption.length > 0 ? (
            <Box
              key={`image-caption-${messageTime}-${itemIndex}-${level}`}
              sx={{ width: "100%", textAlign: "right" }}
            >
              <Typography variant="caption">{caption}</Typography>
            </Box>
          ) : null}
        </>
      );
    }
    return <></>;
  };

  const renderTag = () => {
    if (isObject(objectData)) {
      return <>{renderImageObject(objectData)}</>;
    }
    handleSubComponentLoaded(getComponentRef("MessageImageTag [Text]", itemIndex, level, messageTime), true);
    return <>{renderMessageText(objectData)}</>
  }

  return renderTag();
}
