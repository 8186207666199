import {
    ComponentRef,
    getComponentRef,
    isObject,
    renderMessageObject,
    renderNormalText,
    TagContentTemplate
} from "./MessageTagUtil";
import React, {useEffect, useState} from "react";

export const MessageTextTagTemplate = {
    component: "MessageTextTag",
    data: {
        content: []
    }
}

/**
 * {
 *     "component": "MessageTextTag",
 *     "data": {
 *          "content": [
 *              objects or strings
 *          ]
 *     }
 * }
 */
const initializeState = (data) => {
    if (data) {
        return data.map((item, index) => {
            return {subIndex: index, isLoaded: false};
        });
    }
    return [];
}

export function MessageTextTag(props) {
    const {objectData, handleSubComponentLoaded = (subComponent = {...ComponentRef}, isLoaded = false) => {}, messageTime, itemIndex = "", level} = props;

    const [subComponents, setSubcomponents] = useState(initializeState(objectData.content));

    useEffect(() => {
        if (objectData.content) {
            // console.log(`MessageTextTag subcomponents changed`);
            const missingCount = subComponents.filter((c) => !c.isLoaded).length;
            if (missingCount === 0) {
                handleSubComponentLoaded(getComponentRef("MessageTextTag [List]", itemIndex, level, messageTime), true);
            }
        }
    }, [objectData.content, subComponents]);

    const handleSubComponents = (subIndex = 0, subComponent = {...ComponentRef}, isLoaded = false) => {
        // console.log(`[MessageTextTag] SubComponent Loading -> [${subIndex}] ${subComponent.componentName} = ${isLoaded}`);
        const findSubComponent = subComponents.find((c) => c.subIndex === subIndex);
        if (isLoaded && findSubComponent.isLoaded !== isLoaded) {
            setSubcomponents((prevState) => {
                if (!prevState[subIndex]) {
                    prevState[subIndex] = {subIndex: subIndex, isLoaded: isLoaded};
                } else {
                    prevState[subIndex].isLoaded = isLoaded;
                }
                return prevState;
            });
        }
    }

    const renderTextObject = (objectData = TagContentTemplate, messageTime = 0, itemIndex = "", level = 0) => {
        const content = objectData.content;
        if (content) {
            return content.map((contentItem, contentIndex) => {
                if (isObject(contentItem)) {
                    return renderMessageObject(contentItem, (subComponent, isLoaded) => handleSubComponents(contentIndex, subComponent, isLoaded), messageTime, itemIndex, level);
                }
                handleSubComponents(contentIndex, getComponentRef("MessageTextTag - Inner Text", itemIndex, level, messageTime), true);
                return contentItem;
            });
        }
        return <></>;
    }

    const renderTextTag = () => {
        if (isObject(objectData)) {
            return renderTextObject(objectData, messageTime, itemIndex, level)
        }
        handleSubComponentLoaded(getComponentRef("MessageTextTag", itemIndex, level, messageTime), true);
        return renderNormalText(objectData);
    }

    return renderTextTag();
}
