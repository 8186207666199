import {useNavigate} from "react-router";

import {ButtonBase} from "@mui/material";
import {Content, EdgeSidebar, EdgeTrigger, getStandardScheme, Header, Root, SidebarContent} from "@mui-treasury/layout";

import LogoutIcon from "@mui/icons-material/Logout";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import {useState} from "react";
import SettingsHeader from "./SettingsHeader";
import SettingsMenu from "./SettingsMenu";
import SettingsContent from "./SettingsContent";

export default function Settings() {

    const navigate = useNavigate();

    const [data, setData] = useState({
        header: true,
        nav: true,
        content: true,
        footer: true
    });

    const [activeContent, setActiveContent] = useState();

    const backToMessaging = () => {
        navigate("/home");
    }

    const logout = () => {

    }

    const setContent = (contentComponent) => {
        setActiveContent(contentComponent);
    }

    return (<>
        <Root scheme={getStandardScheme()}>
            {({setOpen}) => (<>
                    <Header>
                        <SettingsHeader data={data}/>
                    </Header>

                    <EdgeSidebar anchor="left">
                        {({state}) => (
                            <>
                                <SidebarContent>{data.nav && <SettingsMenu handleSidebar={setOpen}
                                                                           sidebarState={state}
                                                                           collapsed={state.leftEdgeSidebar?.collapsed}
                                                                            setContent={setContent}/>
                                }</SidebarContent>
                                <EdgeTrigger target={{anchor: "left", field: "collapsed"}}>
                                    {(collapsed, setCollapsed) => (
                                        <>
                                            <ButtonBase
                                                onClick={() => logout()}
                                                sx={{flexGrow: 0.5, height: 48}}
                                            >
                                                {collapsed ? <LogoutIcon /> : "Logout"}
                                            </ButtonBase>
                                            <ButtonBase
                                                onClick={() => setCollapsed(!collapsed)}
                                                sx={{flexGrow: 0.5, height: 48}}
                                            >
                                                {collapsed ? <KeyboardArrowRight/> :
                                                    <KeyboardArrowLeft/>}
                                            </ButtonBase>
                                        </>
                                    )}
                                </EdgeTrigger>
                            </>
                        )}
                    </EdgeSidebar>
                    <Content>
                        <SettingsContent content={activeContent}/>
                    </Content>
                </>
            )}
        </Root>
    </>);
}