import axios from "axios";
import { apiDomain, isDevelopment } from "../App";

const headers = {
  "Cache-Control": "no-cache",
  Accept: "application/json",
  "Content-Type": "application/json",
  "x-api-version": "3.0",
};

const create = (baseURL = apiDomain) => {
  const api = axios.create({
    baseURL,
    headers,
    // 140 second timeout because on the worlds worst mobile connection everything times out
    timeout: 140000,
  });

  axios.interceptors.request.use(function (response) {
    if (isDevelopment) {
      console.log(
        "[DEBUG] API REQUEST url:",
        response.url,
        "Request:",
        response
      );
    }

    return response;
  });

  axios.interceptors.response.use(function (response) {
    if (isDevelopment) {
      console.log(
        "[DEBUG] API RESPONSE url:",
        response.config?.url,
        "Request:",
        response
      );
    }

    return response;
  });

  console.log("API is using BASE_URL=", baseURL);

  const setAuthToken = (token) => {
    api.defaults.headers.common["Authorization"] = "";
    delete api.defaults.headers.common["Authorization"];

    if (token) {
      api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
  };

  const setAccountId = (accountId) => {
    api.defaults.headers.common["accountId"] = "";
    delete api.defaults.headers.common["accountId"];
    if (accountId) {
      api.defaults.headers.common["accountId"] = `${accountId}`;
    }
  };

  const setCompanyId = (companyId) => {
    api.defaults.headers.common["companyId"] = "";
    delete api.defaults.headers.common["companyId"];
    if (companyId) {
      api.defaults.headers.common["companyId"] = `${companyId}`;
    }
  };

  const getByUrl = (url) => api.get(url);

  const getEmployeesJobs = () => api.get("employees/own-jobs");

  const getMessagingSpacesGroupContents = () =>
    api.get(`messaging/spaces/groups-contents`);
  const getSpaceUnreadMessagesCount = (id) =>
    api.get(`messaging/spaces/${id}/unreadMessagesCount`);
  const uploadMessageDocument = (payload) =>
    api.post(`messaging/documents`, payload);

  const getDayOfBusinessLogTypes = () => api.get(`api/log-book/log-types`);
  const getDayOfBusinessStaffNoteTypes = () =>
    api.get(`api/log-book/staff-note-types`);
  const fetchCustomFormTemplateData = (id) =>
    api.get(`api/log-book/custom-forms/${id}`);
  const fetchCustomFormTemplateDataFromStaffNoteType = (id) =>
    api.get(`api/log-book/custom-forms/staff-note-type/${id}`);

  const markMsgNotificationsAsRead = (spaceId) =>
    api.post(`notifications/mark-msg-notifications-as-read`, { spaceId });

  const searchMessages = (payload) => api.post("search", payload);

  return {
    setAuthToken,
    setAccountId,
    setCompanyId,
    getByUrl,
    getEmployeesJobs,
    getMessagingSpacesGroupContents,
    getSpaceUnreadMessagesCount: getSpaceUnreadMessagesCount,
    uploadMessageDocument,
    getDayOfBusinessLogTypes,
    getDayOfBusinessStaffNoteTypes,
    fetchCustomFormTemplateData,
    fetchCustomFormTemplateDataFromStaffNoteType,
    markMsgNotificationsAsRead,
    searchMessages,
  };
};

export default { create };
