import React from "react";
import * as Tags from "./";

export const TagContentTemplate = {
  content: [],
};

export const TagTemplate = {
  component: "",
  data: {
    content: [],
  },
};

export const ComponentRef = {
  componentName: "",
  componentIndex: "",
  componentLevel: 0,
  msgTime: 0,
};

export const isObject = (data) => {
  return data && typeof data === "object" && data.constructor === Object;
};

export const getFieldString = (rawFieldData, defaultIfNotExists = "") => {
  return rawFieldData && rawFieldData.length > 0
    ? rawFieldData
    : defaultIfNotExists;
};

export const getFieldNumber = (rawFieldData, defaultIfNotExists = 0) => {
  return rawFieldData ? rawFieldData : defaultIfNotExists;
};

export const getComponentRef = (name, index, level, time) => {
  return {
    ...ComponentRef,
    componentName: name,
    componentIndex: index,
    componentLevel: level,
    msgTime: time,
  };
};

export const renderMessageText = (textContent = "") => {
  return textContent;
};

export const renderMessageObject = (
  objectContent = TagTemplate,
  handleSubComponentLoaded = (
    subComponent = { ...ComponentRef },
    isLoaded = false
  ) => {},
  messageTime = 0,
  itemIndex = "",
  level = 0
) => {
  const Component = Tags[objectContent.component];
  const data = objectContent.data;
  if (Component && data) {
    return (
      <Component
        key={`object-${objectContent.component}-${messageTime}-${itemIndex}-${level}`}
        handleSubComponentLoaded={handleSubComponentLoaded}
        itemIndex={itemIndex}
        level={level + 1}
        messageTime={messageTime}
        objectData={data}
      />
    );
  }
  return <></>;
};

export const renderNormalText = (text = "") => {
  return renderMessageText(text);
};

export const getMessageObject = (message) => {
  if (message.indexOf("{") === 0 && message.indexOf("}") > 0) {
    try {
      return JSON.parse(message);
    } catch (error) {
      console.log("Message object parse error: ", error);
    }
  }
};

/**
 * Checks if message is LogBook, StaffNote or regular message. (Hash tag and user tag still pending).
 * **THIS FUNCTION SHOULD BE DELETED WHEN ALL TYPES CAN BE EDITED IN THE APP**
 * @param {Message} message
 * @returns {boolean}
 */
export const isValidEditMessage = (message) => {
  if (message.indexOf("{") === 0 && message.indexOf("}") > 0) {
    try {
      const json = JSON.parse(message);
      if (json) {
        const { component, data } = json;
        if (component === "MessageCompositeTag" && data.content.length > 0) {
          const isCustomForm = !!data.content.find(
            (value) =>
              value.component === "MessageStaffNoteTag" ||
              value.component === "MessageLogTag"
          );
          return isCustomForm;
        }
      }
    } catch (error) {
      //Is regular string message with brackets.
      return true;
    }
  } else {
    // Is regular string message.
    return true;
  }
  return false;
};

/**
 * Checks if message is LogBook or StaffNote type.
 * @param {Message} message
 * @returns {boolean}
 */
export const isCustomForm = (message) => {
  if (message.indexOf("{") === 0 && message.indexOf("}") > 0) {
    try {
      const json = JSON.parse(message);
      if (json) {
        const { component, data } = json;
        if (component === "MessageCompositeTag" && data.content.length > 0) {
          const result = !!data.content.find(
            (value) =>
              value.component === "MessageStaffNoteTag" ||
              value.component === "MessageLogTag"
          );
          return result;
        }
      }
    } catch (error) {}
  }
  return false;
};
