import {createContext} from "react";

import {UserStruct} from "./UserContext";

const UserList = createContext({
    userList: [{...UserStruct}], setUserList: (user) => {
    }
});

export const UserListContext = {
    UserListContext: UserList
};

export const userListReducer = (state, action) => {
    console.log("UserListContext: action " + action.type);
    switch (action.type) {
        case "RESET_LIST":
            console.log("Cleaning out the user list");
            return [{...UserStruct}];
        case "ADD_USER":
            console.log("Adding a user list context user [" + state.length + "] : " + action.user.id + " => " + action.user.displayName);
            if (state.length === 0) {
                return [action.user];
            } else if (state[0].id === "dummyId") {
                return [action.user];
            }
            return [...state, action.user];
        case "REMOVE_USER":
            return state;
        default:
            return state;
    }
}

export const findUser = (userList, userId) => {
    const user = userList.find(user => user.id === userId);
    return user === undefined ? undefined : user;
}