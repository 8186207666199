import {useContext} from "react";
import {useNavigate} from "react-router";

import {Box, ButtonBase, Chip, Grid, IconButton, Toolbar, Typography, useMediaQuery, useTheme} from "@mui/material";

import logoWhite from "../../../images/axial-shift-logo-horizontal-white.png";
import logoBlack from "../../../images/axial-shift-logo-horizontal-black.png";

import {KeyboardArrowLeft} from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import NotesIcon from "@mui/icons-material/Notes";

import {EdgeTrigger} from "@mui-treasury/layout";

import {SpaceContext} from "../../message/contexts/SpaceContext";
import {AccountDetailsContext} from "../../message/models/AccountDetailsContext";

function SettingsHeader(props) {
    const {accountDetails} = useContext(AccountDetailsContext.AccountDetailsContext);
    const {activeSpace} = useContext(SpaceContext.ActiveSpaceContext);

    const navigate = useNavigate();

    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const logo = prefersDarkMode ? logoWhite : logoBlack;
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("sm"));

    const {data} = props;

    const backToMessaging = () => {
        navigate("/home");
    }

    return (
        <>
            <Toolbar sx={{position: "sticky", top: 0}}>
                <Box sx={{flexGrow: 1}}>
                    <Grid container direction="row" alignItems="center">
                        {data.header ? (
                            <EdgeTrigger target={{anchor: "left", field: "open"}}>
                                {(open, setOpen) => (
                                    <Grid item xs={1}>
                                        <Box sx={{justifyContent: "flex-end", display: "flex", ml: 2}}>
                                            <IconButton onClick={() => setOpen(!open)} edge="end">
                                                {open ? <KeyboardArrowLeft/> : <MenuIcon/>}
                                            </IconButton>
                                        </Box>
                                    </Grid>
                                )}
                            </EdgeTrigger>
                        ) : (
                            <EdgeTrigger target={{anchor: "left", field: "open"}}>
                                {(open, setOpen) => (
                                    <Grid item xs={1}>
                                        <Box sx={{justifyContent: "flex-end", display: "flex", ml: 2}}>
                                            <IconButton onClick={() => setOpen(!open)} edge="end">
                                                {open ? <KeyboardArrowLeft/> : <MenuIcon/>}
                                            </IconButton>
                                        </Box>
                                    </Grid>
                                )}
                            </EdgeTrigger>
                        )}
                        <Grid item xs sx={{flexGrow: 1, justifyContent: "flex-start", display: "flex"}}>
                            {!matches ?
                                <>
                                    <ButtonBase sx={{mr:2}} onClick={backToMessaging}><NotesIcon/></ButtonBase>
                                    <Typography variant="h5">
                                        Settings - {accountDetails.axial.company.name}
                                    </Typography>
                                </> : null
                            }
                            {matches ?
                                <Chip color="primary" variant="outlined" size="small" label={activeSpace.name}/> : null}
                        </Grid>
                        <Grid item xs={6}>
                            <Box sx={{
                                width: "100%", justifyContent: "flex-end", display: "flex"
                            }}>
                                <Box component="img" src={logo}
                                     sx={{
                                         height: "auto", width: "100%", maxWidth: "400px"
                                     }}/>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Toolbar>
        </>
    )
}

export default SettingsHeader;