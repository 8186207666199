import { useState, useContext, useEffect } from "react";

import { UserContext } from "../../models/UserContext";

import { SpaceContext } from "../../contexts/SpaceContext";
import { SpaceOperations } from "../../data/SpaceOperations";
import {
  getSpaceName,
  SpaceOperationTask,
  validateSpaceName,
} from "../../controllers/SpaceController";

import { AccountDetailsContext } from "../../models/AccountDetailsContext";

import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  useTheme,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import FolderOffIcon from "@mui/icons-material/FolderOff";
import { UserListContext } from "../../models/UserListContext";
import SpaceTypes from "../../data/SpaceTypes";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import useUnreadMessages from "../../../../hooks/useUnreadMessages";

export default function MessageSpace(props) {
  const { sourceSpace, handleSidebar } = props;
  const spaceHeading = props.spaceHeading;

  const theme = useTheme();

  const [isArchiveButtonActive, toggleArchiveButton] = useState(false);
  const [isDeleteButtonActive, toggleDeleteButton] = useState(false);
  const [isEditActive, toggleEditActive] = useState(false);
  const [space, setSpace] = useState(sourceSpace);
  const [spaceName, setSpaceName] = useState(sourceSpace.name);

  const { accountDetails } = useContext(
    AccountDetailsContext.AccountDetailsContext
  );
  const { activeSpace, setActiveSpace } = useContext(
    SpaceContext.ActiveSpaceContext
  );
  const { currentUser, setCurrentUser } = useContext(UserContext.UserContext);
  const { userList } = useContext(UserListContext.UserListContext);

  const [unreadMessagesCount] = useUnreadMessages(sourceSpace);

  /*useEffect(() => {
    // Detect membership changes in active space
    if(space.id === activeSpace.id) {
      getUnreadMessagesCount().then(
          () => {
            setUnreadMessagesCount(space.unreadCount);
            // console.log(`Updating ${space.name} [${space.id}] to new count of ${space.unreadCount}`);
          },
          (error) => console.log(`An error occurred loading unread messages - ${error}`)
      );
      const refreshedSpace = spaceList.find((spaceItem) => spaceItem.id === space.id);
      setActiveSpace(refreshedSpace);
    }
    const updatedSpace = spaceList.find((chkSpace) => chkSpace.id === space.id);
    if(updatedSpace){
      setSpace(updatedSpace);
    }
  }, [spaceList]);*/

  /**
   * Logic for sending info to the server and reloading in MessageRecordContainer
   *
   * @param id The new space reference
   */
  const selectSpace = (id) => {
    console.log("Changing spaces: " + id);
    if (id !== activeSpace.id) {
      console.log(`Setting the active space to a new space ${space.name}`);
      setActiveSpace(space);
      setCurrentUser({ ...currentUser, lastSpaceId: id });
      handleSidebar("leftEdgeSidebar", false);
    }
  };

  const updateSpaceName = (newName) => {
    const editedSpace = { ...space, name: newName };
    SpaceOperationTask(SpaceOperations.SEND_UPDATE_SPACE, {
      accountDetails: accountDetails,
      space: editedSpace,
    });
    setActiveSpace(editedSpace);
    handleEditDialog();
  };

  const getSpaceIcon = () => {
    if (space.archived) {
      return spaceHeading.listIcon;
    }

    return space.messageSpaceType !== SpaceTypes.DYNAMIC_GROUP
      ? spaceHeading.listIcon
      : BusinessCenterIcon;
  };

  const archiveSpace = () => {
    const editedSpace = { ...space, archived: !space.archived };
    SpaceOperationTask(SpaceOperations.SEND_UPDATE_SPACE, {
      accountDetails: accountDetails,
      space: editedSpace,
    });
    setActiveSpace(editedSpace);
    handleArchiveButton();
  };

  const handleEditDialog = () => {
    toggleEditActive(!isEditActive);
  };

  const handleDeleteButton = () => {
    toggleDeleteButton(!isDeleteButtonActive);
  };

  const handleArchiveButton = () => {
    toggleArchiveButton(!isArchiveButtonActive);
  };

  const getEditor = () => {
    return (
      <>
        <IconButton
          key="edit"
          onClick={handleEditDialog}
          sx={{
            marginX: "1px",
            color: theme.palette.channel.editText,
            backgroundColor: theme.palette.channel.editChannel,
            "&:hover": {
              backgroundColor: theme.palette.channel.editChannelHover,
              color: theme.palette.channel.editTextHover,
            },
          }}
        >
          <EditIcon
            sx={{
              color: theme.palette.channel.editText,
              "&:hover": {
                color: theme.palette.channel.editTextHover,
              },
              fontSize: "small",
            }}
          />
        </IconButton>
      </>
    );
  };

  const getDelete = () => {
    return (
      <>
        <IconButton
          key="delete"
          edge="end"
          onClick={handleDeleteButton}
          sx={{
            marginLeft: "1px",
            marginRight: "-5px",
            color: theme.palette.channel.editText,
            backgroundColor: theme.palette.channel.editChannel,
            "&:hover": {
              backgroundColor: theme.palette.channel.editChannelHover,
              color: theme.palette.channel.editTextHover,
            },
          }}
        >
          <ClearIcon
            sx={{
              color: theme.palette.channel.editText,
              "&:hover": {
                color: theme.palette.channel.editTextHover,
              },
              fontSize: "small",
            }}
          />
        </IconButton>
        <Dialog open={isDeleteButtonActive} onClose={handleDeleteButton}>
          <DialogTitle>{spaceHeading.deleteDialog.title}</DialogTitle>
          <DialogContent>
            {spaceHeading.deleteDialog.body} {space.name} ?
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteButton} variant="contained" autoFocus>
              Cancel
            </Button>
            <Button
              onClick={() =>
                spaceHeading.deleteDialog.action(
                  accountDetails,
                  space,
                  currentUser
                )
              }
              variant="contained"
            >
              Leave
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const getArchive = () => {
    return (
      <>
        <IconButton
          key="archive"
          edge="end"
          onClick={handleArchiveButton}
          sx={{
            marginX: "1px",
            color: theme.palette.channel.editText,
            backgroundColor: theme.palette.channel.editChannel,
            "&:hover": {
              backgroundColor: theme.palette.channel.editChannelHover,
              color: theme.palette.channel.editTextHover,
            },
          }}
        >
          <FolderOffIcon
            sx={{
              color: theme.palette.channel.editText,
              "&:hover": {
                color: theme.palette.channel.editTextHover,
              },
              fontSize: "small",
            }}
          />
        </IconButton>
        <Dialog open={isArchiveButtonActive} onClose={handleArchiveButton}>
          <DialogTitle>
            Confirm - {space.archived ? "Unarchive" : "Archive"} Space
          </DialogTitle>
          <DialogContent>
            {`Are you sure you want to ${
              space.archived ? "Unarchive" : "Archive"
            } this space -> ${space.name} ?`}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleArchiveButton} variant="contained" autoFocus>
              Cancel
            </Button>
            <Button onClick={() => archiveSpace()} variant="contained">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const isAdminOrOwner = (user, space) => {
    return user.isAdmin || user.id === space.createdBy;
  };

  const [areActionButtonsVisible, showActionButtons] = useState(false);
  const SpaceIcon = getSpaceIcon();

  return (
    <ListItemButton
      onMouseOver={() => showActionButtons(true)}
      onMouseOut={() => showActionButtons(false)}
      sx={{ backgroundColor: "background.paperDark", pr: "4px", py: "4px" }}
    >
      <ListItemIcon sx={{ minWidth: "28px" }}>
        <SpaceIcon
          sx={{
            color:
              activeSpace.id === space.id ? "primary.main" : "text.secondary",
            fontSize: "medium",
          }}
        />
      </ListItemIcon>
      <ListItemText
        title={getSpaceName(space, userList, currentUser)}
        primary={getSpaceName(space, userList, currentUser)}
        onClick={() => selectSpace(space.id)}
        onDoubleClick={handleEditDialog}
        disableTypography={true}
        sx={{
          display: "inline-block",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          marginTop: "1px",
          marginBottom: "1px",
          fontSize: "14px",
          color:
            activeSpace.id === space.id
              ? "primary.main"
              : unreadMessagesCount > 0
              ? "text.primary"
              : "text.secondary",
          fontWeight: unreadMessagesCount > 0 ? "bold" : "normal",
        }}
      />
      {unreadMessagesCount > 0 && (
        <Chip
          label={unreadMessagesCount}
          size="small"
          sx={{
            backgroundColor: theme.palette.message.unread,
            color: "white",
            marginRight: "2px",
          }}
        />
      )}
      <Dialog open={isEditActive} onClose={handleEditDialog}>
        {space.messageSpaceType === SpaceTypes.DIRECT ? (
          <DialogTitle>Edit DM Name</DialogTitle>
        ) : (
          <DialogTitle>Edit Channel Name</DialogTitle>
        )}
        <DialogContent>
          <DialogContentText>
            Tip: choose a name that is descriptive of the channel purpose
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="newSpaceName"
            label="New Name"
            type="text"
            fullWidth
            variant="standard"
            value={spaceName}
            onChange={(e) => setSpaceName(validateSpaceName(e.target.value))}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditDialog} variant="contained">
            Cancel
          </Button>
          <Button
            onClick={() => updateSpaceName(spaceName)}
            startIcon={<DoneIcon />}
            variant="contained"
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
      {isAdminOrOwner(currentUser, space) ? (
        <ListItemSecondaryAction
          sx={{ visibility: areActionButtonsVisible ? "visible" : "hidden" }}
          onMouseOver={() => showActionButtons(true)}
          onMouseOut={() => showActionButtons(false)}
        >
          <>
            {getEditor()}
            {getArchive()}
            {getDelete()}
          </>
        </ListItemSecondaryAction>
      ) : null}
    </ListItemButton>
  );
}
