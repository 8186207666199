import {useEffect, useRef, useContext, useState} from "react";
import moment from "moment";

import { SpaceContext } from "../../contexts/SpaceContext";
import { findUser, UserListContext } from "../../models/UserListContext";
import { MessageContext } from "../../contexts/MessageContext";
import { UserContext } from "../../models/UserContext";

import { SpaceHeadings } from "../../data/SpaceData";
import RecordListWrapper from "./RecordListWrapper";
import MessageRecord, { MessageRecordDimensions } from "./MessageRecord";

import useWindowDimensions from "../../../util/WindowDimensions";
import {
  checksum,
  StringToColor,
} from "../../../util/Helpers";

import {
  Alert,
  Avatar,
  AvatarGroup,
  Grid,
  List,
  Paper, Skeleton,
  Snackbar, Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import SpaceTypes from "../../data/SpaceTypes";
import { getSpaceName } from "../../controllers/SpaceController";
import { AppContext } from "../../contexts/AppContext";
import { AccountDetailsContext } from "../../models/AccountDetailsContext";
import {
  MessagesBottomItemLoader,
  MessagesTopItemLoader,
} from "../../controllers/MessageController";

function MessageRecordContainer() {
  const { snackbar, setSnackbar } = useContext(AppContext.SnackbarContext);
  const { accountDetails } = useContext(AccountDetailsContext.AccountDetailsContext);
  const { messageItemList, messageItemDispatcher } = useContext(MessageContext.MessageRecordMgr);
  const { activeSpace } = useContext(SpaceContext.ActiveSpaceContext);
  const { currentUser } = useContext(UserContext.UserContext);
  const { userList } = useContext(UserListContext.UserListContext);

  const [messagesTopCurrentPage, setMessagesTopCurrentPage] = useState(0);
  const [messagesBottomCurrentPage, setMessagesBottomCurrentPage] = useState(0);
  const [topLoader, setTopLoader] = useState({
    isLoading: false,
    loadNext: false,
  });
  const [bottomLoader, setBottomLoader] = useState({
    isLoading: false,
    loadNext: false,
  });

  /* setting WILL NOT change the active space */
  const [activeSpaceId, setActiveSpaceId] = useState(""); // used for detecting channel changes
  const [messages, setMessages] = useState([]);
  const [messageUpdate, setMessageUpdate] = useState("");
  const [spaceLastViewTime, setSpaceLastViewedTime] = useState(moment.utc('1/1/1970', 'd/M/yyyy'));
  const [spacePreviousViewTime, setSpacePreviousViewedTime] = useState(moment.utc('1/1/1970', 'd/M/yyyy'));

  const [isScrollWaiting, setIsScrollWaiting] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [fakeUpdater, setFakeUpdater] = useState(moment.utc());
  const [autoScrollToNewMessage, setAutoScrollToNewMessage] = useState(false);

  const messagePanel = useRef(null);

  const theme = useTheme();
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const matchesMd = useMediaQuery(theme.breakpoints.down("md"));
  const matchesLg = useMediaQuery(theme.breakpoints.down("lg"));

  const { height } = useWindowDimensions();

  const sizes = {
    small: {
      fontSize: "10px",
      fontSizeLg: "12px",
      iconSize: "small",
      avatarSize: "20px",
      avatarSizeLg: "25px",
    },
    medium: {
      fontSize: "12px",
      fontSizeLg: "15px",
      iconSize: "medium",
      avatarSize: "25px",
      avatarSizeLg: "30px",
    },
    large: {
      fontSize: "14px",
      fontSizeLg: "18px",
      iconSize: "medium",
      avatarSize: "30px",
      avatarSizeLg: "40px",
    },
  };

  const getAvatarSize = (isLarge = false) => {
    if (isLarge) {
      return matchesLg
        ? matchesMd
          ? sizes.small.avatarSizeLg
          : sizes.medium.avatarSizeLg
        : sizes.large.avatarSizeLg;
    }
    return matchesLg
      ? matchesMd
        ? sizes.small.avatarSize
        : sizes.medium.avatarSize
      : sizes.large.avatarSize;
  };

  const getIconSize = () => {
    return matchesLg
      ? matchesMd
        ? sizes.small.iconSize
        : sizes.medium.iconSize
      : sizes.large.iconSize;
  };

  const getFontSize = (isLarge = false) => {
    if (isLarge) {
      return matchesLg
        ? matchesMd
          ? sizes.small.fontSizeLg
          : sizes.medium.fontSizeLg
        : sizes.large.fontSizeLg;
    }
    return matchesLg
      ? matchesMd
        ? sizes.small.fontSize
        : sizes.medium.fontSize
      : sizes.large.fontSize;
  };

  const getHeaderHeight = () => {
    return parseInt(getAvatarSize().slice(0, 2)) + 4 + 24;
  };

  useEffect(() => {
    // console.log(`handleInitialScroll ${isInitialLoad} - ${messages.length}`);
    if (activeSpace.id === "") {
      setIsInitialLoad(false);
      return;
    }
    if (isInitialLoad && messages.length > 0) {
      // console.log(`handleInitialScroll - handleInitialLoad`);
      const missingRendersList = messages.filter((msg) => !msg.dimensions || !msg.dimensions.isDataRendered);
      if(!missingRendersList || missingRendersList.length === 0) {
        setIsInitialLoad(false);
        // go ahead and scroll
        let scrollToHeight = 0;
        let skipCount = 0;
        let totalCount = 0;
        messages.map((msg, index) => {
          if (new Date(msg.createdUtc) <= spaceLastViewTime) {
            scrollToHeight += msg.dimensions.height;
            skipCount++;
          }
          totalCount++;
          // console.log(`Calculating Scroll [${index}] -> [${msg.id}] @ ${msg.createdUtc} (width: ${msg.dimensions.width}px, height: ${msg.dimensions.height}px) -> ${scrollToHeight}`);
          return null;
        });
        if (skipCount === totalCount) {
          scrollToHeight = messagePanel.current.scrollHeight;
        } else if (scrollToHeight >= messagePanel.current.scrollHeight) {
          scrollToHeight = messagePanel.current.scrollHeight - 10;
        } else if (scrollToHeight === 0) {
          scrollToHeight = 10;
        }
        console.log(`handleInitialScroll - Scrolling to [${skipCount}] ${scrollToHeight} of ${messagePanel.current.scrollHeight}`);
        messagePanel.current.scroll({
          top: scrollToHeight,
          behavior: "smooth",
        });
      } else {
        missingRendersList.map((missingMsg) => {
          console.log(`handleInitialScroll - Scrolling [still need ${missingMsg.id} @ ${missingMsg.createdUtc} to update]`);
          return null;
        });
      }
    }
  }, [fakeUpdater]);

  useEffect(() => {
    if (activeSpace.id !== activeSpaceId) {
      console.log(`Active Space has changed - resetting variables`);
      setActiveSpaceId(activeSpace.id);
      setIsInitialLoad(true);
      setFakeUpdater(moment.utc());
      setAutoScrollToNewMessage(false);
      setIsScrollWaiting(false);
      setMessages([]);
    }
  }, [activeSpace]);

  useEffect(() => {
    /* This will trigger any time the channel changes ... a form of reset */
    console.log(`Active Space Id has changed`);
  }, [activeSpaceId]);

  useEffect(() => {
    // This is an initial page load effect only
    if(messageItemList.firstPageLoaded >= 0) {
      setMessagesTopCurrentPage(messageItemList.firstPageLoaded);
      setMessagesBottomCurrentPage(messageItemList.firstPageLoaded);
    }
  }, [messageItemList.firstPageLoaded]);

  useEffect(() => {
    if(messageUpdate !== ""){
      // setMessageUpdate("");
    }
  }, [messageUpdate]);

  useEffect(() => {
    console.log(`Touching Space = ${activeSpace.members.length}`);
    const currentSpaceMember = activeSpace.members.find((member) => member.memberId === currentUser.id);
    let baseDate = moment.utc().subtract(6, 'months');
    if (currentSpaceMember !== undefined && currentSpaceMember.lastAccessed !== undefined) {
      baseDate = moment.utc(new Date(currentSpaceMember.lastAccessed));
    }
    setSpaceLastViewedTime((prevState) => {
      setSpacePreviousViewedTime(prevState)
      return baseDate;
    });
  }, [activeSpace, currentUser]);

  useEffect(() => {
    console.log(`Bottom Loader ------------------------>`);
    if (bottomLoader.loadNext && !bottomLoader.isLoading) {
      console.log(`   Loading next section at the bottom`);
      setBottomLoader((prevState) => {return {loadNext: prevState.loadNext, isLoading: true}});
      const nextPage = messagesBottomCurrentPage + 1;
      try {
        const loader = async () => await MessagesBottomItemLoader(
            nextPage,
            accountDetails,
            activeSpace,
            currentUser,
            messageItemDispatcher
        ).then(() => {
          setMessagesBottomCurrentPage(nextPage);
          console.log(`   Finished Loading at the bottom`);
        }, (error) => {
          console.log(`An error occurred - ${error}`);
        });
        loader().then(
            () => {
              setBottomLoader({loadNext: false, isLoading: false});
              console.log(`   Finished loading at the bottom - setting status`);
            },
            (error) => console.log(`Unable to reset status - ${error}`));
      } catch (error) {
        console.log(error);
      }
    }
  }, [bottomLoader]);

  /**
   * Changes to message list - additions, removals, first time loads
   */
  useEffect(() => {
    console.log(`Checking for scroll triggers [Auto Scroll = ${autoScrollToNewMessage}] - [${messages.length}] -> [${messageItemList.messageList.length}]`);

    // Merge previous list with new list
    setMessages((prevMessages) => {
      // Check for space change
      let resultant = [];
      if (prevMessages.some((pMessage) => messageItemList.messageList.length > 0 && pMessage.spaceId === messageItemList.messageList[0].spaceId)){
        resultant = [...prevMessages];
      }
      messageItemList.messageList.forEach((message) => {
        const exists = resultant.some((prevMessage) => prevMessage.id === message.id);
        if (exists){
          const existingMsg = resultant.find((msg) => msg.id === message.id);
          message.dimensions = existingMsg.dimensions;
          const checksum1 = checksum(JSON.stringify(existingMsg));
          const checksum2 = checksum(JSON.stringify(message));
          if(checksum1 !== checksum2){
            console.log(`Updated message - flushing old and replacing with the new ${message.id}`);
            const filteredResultant = resultant.filter((msg) => msg.id !== message.id);
            resultant = [...filteredResultant, message];
          }
        } else {
          message.dimensions = {...MessageRecordDimensions};
          resultant.push(message);
        }
      });
      if (messageItemList.deletedIds && messageItemList.deletedIds.length > 0) {
        const filterRemoved = resultant.filter((msg) => {
          return messageItemList.deletedIds.find((id) => msg.id === id) === undefined;
        });
        return getSortedMessageList(filterRemoved);
      }
      return getSortedMessageList(resultant);
    })
    if (autoScrollToNewMessage) {
      setIsScrollWaiting(true);
    }
  }, [messageItemList.messageList]);

  useEffect(() => {
    // console.log(`Auto Scroll Appending = ${autoScrollToNewMessage}`)
  }, [autoScrollToNewMessage]);

  useEffect(() => {
    // console.log(`Scroll Waiting = ${isScrollWaiting}`)
    let intervalId = 0;
    if(isScrollWaiting){
      intervalId = setInterval(batchedScroller, 1000);
    }
    return () => clearInterval(intervalId);
  }, [isScrollWaiting]);

  const getSortedMessageList = (messageList, inverted = true) => {
    const tmpList = [...messageList];
    tmpList.sort((thisMsg, compareMsg) => {
      const thisTime = (
          thisMsg.createdUtc
              ? new Date(thisMsg.createdUtc.toString())
              : new Date()
      ).getTime();
      const compareTime = (
          compareMsg.createdUtc
              ? new Date(compareMsg.createdUtc.toString())
              : new Date()
      ).getTime();
      return inverted ? thisTime - compareTime : compareTime - thisTime;
    });
    return tmpList;
  };

  const batchedScroller = () => {
    if(autoScrollToNewMessage) {
      messagePanel.current.scroll({
        top: messagePanel.current.scrollHeight,
        behavior: "smooth",
      });
    }
    setIsScrollWaiting(false);
  }

  const getDisplayName = (createdById) => {
    const user = findUser(userList, createdById);
    return user === undefined ? ""
        : ((user.firstName.length > 0 ? user.firstName : "") + " " + (user.lastName.length > 0 ? user.lastName : "")).trim();
  }

  const getSpaceMemberAvatar = (memberId) => {
    const spaceMember = userList.find((user) => user.id === memberId);
    if (spaceMember !== undefined) {
      const avatar =
        (spaceMember.firstName.length > 0
          ? spaceMember.firstName.substring(0, 1).toUpperCase()
          : "") +
        (spaceMember.lastName.length > 0
          ? spaceMember.lastName.substring(0, 1).toUpperCase()
          : "");
      const avatarColor = StringToColor(getDisplayName(memberId), prefersDarkMode);
      return (
        <Avatar
          key={memberId}
          sx={{
            height: getAvatarSize(),
            width: getAvatarSize(),
            fontSize: getFontSize(),
            backgroundColor: avatarColor,
          }}
        >
          {avatar}
        </Avatar>
      );
    }
    return null;
  };

  const getMembers = () => {
    if (activeSpace.id === "") {
      return <AvatarGroup max={8}>
        <Skeleton variant="circular"><Avatar /></Skeleton>
        <Skeleton variant="circular"><Avatar /></Skeleton>
        <Skeleton variant="circular"><Avatar /></Skeleton>
      </AvatarGroup>;
    }
    if (activeSpace.messageSpaceType === SpaceTypes.DYNAMIC_GROUP) {
      return <></>;
    }
    const listMembers = activeSpace.members.map(
      ({ memberId, doShow }) => doShow && getSpaceMemberAvatar(memberId)
    );
    return <AvatarGroup max={8}>{listMembers}</AvatarGroup>;
  };

  const getSpaceIcon = () => {
    if (activeSpace.id === "") {
      return <Skeleton variant="circular"><Avatar /></Skeleton>;
    }
    const heading = SpaceHeadings.find(
      (heading) => heading.id === activeSpace.messageSpaceType
    );

    if (heading !== undefined && heading.listIcon !== undefined) {
      const Icon = heading.listIcon;
      return <Icon />;
    }

    return null;
  };

  const renderSpaceHeading = (aSpace, uList, cUser) => {
    if (aSpace.id === "") {
      return <Skeleton variant="rounded" width={100} height={40}/>;
    }
    if (aSpace.messageSpaceType === SpaceTypes.DIRECT) {
      return <Typography sx={{
        display: "inline-block",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"}}>{getSpaceName(aSpace, uList, cUser)}</Typography>;
    }
    return <Typography sx={{
      display: "inline-block",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"}}>{aSpace.name}</Typography>;
  }

  const handleOnScroll = async (evt, initialLoad) => {
    if (initialLoad) {
      return;
    }
    if (evt.target.scrollTop === 0) {
      if (!messageItemList.isTopEndReached) {
        const nextPage = messagesTopCurrentPage - 1;
        const prevScrollHeight = messagePanel.current.scrollHeight;
        try {
          await MessagesTopItemLoader(
            nextPage,
            accountDetails,
            activeSpace,
            currentUser,
            messageItemDispatcher
          ).then(() => {
            setTimeout(() => {
              messagePanel.current.scroll({
                top: messagePanel.current.scrollHeight - prevScrollHeight,
                behavior: "smooth",
              });
            }, 500);
            setMessagesTopCurrentPage(nextPage);
          }, (error) => {
            console.log(`An error occurred - ${error}`);
          });
        } catch (error) {
          console.log(error);
        }
      }
      setAutoScrollToNewMessage(false);
    } else if ((evt.target.scrollTop + evt.target.offsetHeight) >= (evt.target.scrollHeight - 5)) { // within 5 pixels to account for rounding
      if (!messageItemList.isBottomEndReached) {
        setBottomLoader((prevState) => {return {loadNext: true, isLoading: prevState.isLoading}});
        setAutoScrollToNewMessage(false);
      } else {
        setAutoScrollToNewMessage(true);
      }
    } else {
      setAutoScrollToNewMessage(false);
    }
  };

  const handleMessageUpdate = (recordId) => {
    // console.log(`Trigger message re-render ${recordId}`);
    setMessageUpdate(recordId);
  }

  const updateLastViewTime = (newDate = moment.utc()) => {
    setSpaceLastViewedTime((prevDate) => {
      if (!prevDate) {
        return newDate;
      }
      if (newDate > prevDate) {
        setSpacePreviousViewedTime(prevDate);
        return newDate;
      }
      return prevDate;
    });
  }

  const handleDimensionChange = (msgDimension = {...MessageRecordDimensions}) => {
    // console.log(`Updating dimensions of message ${msgDimension.id} --> [${msgDimension.width}, ${msgDimension.height}] - Has Message Content: ${msgDimension.isDataRendered}`);
    if (!msgDimension || msgDimension.id.length === 0 || !msgDimension.isDataRendered) { return }

    setMessages((messageList) => {
      if (!messageList) { return [] }
      const msgIndex = messageList.map((msg) => msg.id).indexOf(msgDimension.id);
      if (msgIndex < 0) {
        // not found
        return messageList;
      }
      const msgToUpdate = messageList[msgIndex];
      const existingHeight = msgToUpdate.dimensions ? msgToUpdate.dimensions.height : 0;
      if (msgDimension.isDataRendered && existingHeight !== msgDimension.height) {
        // console.log(`   Found and updating dimensions of message ${msgDimension.id} --> [${msgToUpdate.dimensions.width}, ${msgToUpdate.dimensions.height}] => [${msgDimension.width}, ${msgDimension.height}]`);
        messageList.splice(msgIndex, 1, {...msgToUpdate, dimensions: msgDimension});
        // Used to trigger a re-evaluation for scrolling
        setFakeUpdater(moment.utc());
      }
      return messageList;
    });
  }

  const renderMessageList = () => {
    const messageUpdateCheck = messageUpdate;
    let hasToggled = false;
    return messages.map((record, index) => {
      let doRenderUpdate = record.id !== "" && messageUpdateCheck === record.id;
      let doHighlightNew = false;
      const messageDate = record.createdUtc ? moment.utc(record.createdUtc) : moment.utc();
      if (messageDate > spaceLastViewTime && !hasToggled && record.createdById !== currentUser.id) {
        hasToggled = true;
        doHighlightNew = true;
        doRenderUpdate = true;
        // console.log(`Forced refresh of record on ${messageDate} [Last Viewed ${spaceLastViewTime}]`);
      }
      if (messageDate >= spacePreviousViewTime && messageDate <= spaceLastViewTime) {
        // console.log(`Update previous record on ${messageDate} [Previous Viewed ${spacePreviousViewTime}] && [Current Viewed ${spaceLastViewTime}]`);
        doRenderUpdate = true;
      }
      if (messageUpdate && messageUpdate === record.id) {
        // console.log(`Render update for flagged message - ${messageUpdate}`);
        doRenderUpdate = true;
      }
      return <MessageRecord
          key={record.id}
          item={record}
          shouldUpdate={doRenderUpdate}
          updateRecord={(recordId) => handleMessageUpdate(recordId)}
          recordIndex={index}
          avatarSize={getAvatarSize(true)}
          iconSize={getIconSize()}
          fontSize={getFontSize(true)}
          lastViewTime={spaceLastViewTime}
          updateLastViewTime={updateLastViewTime}
          highlightUnread={doHighlightNew}
          handleDimensionChange={handleDimensionChange}
      />
    });
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: snackbar.vertical,
          horizontal: snackbar.horizontal,
        }}
        autoHideDuration={snackbar.duration}
        open={snackbar.open}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        key={snackbar.vertical + snackbar.horizontal}
      >
        <Alert severity={snackbar.severity}>{snackbar.message}</Alert>
      </Snackbar>
      <RecordListWrapper>
        <Paper elevation={3}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ marginBottom: "8px", padding: "8px" }}
          >
            <Grid item container xs={7}>
              <Grid>{getSpaceIcon()}</Grid>
              <Grid sx={{width: "10px"}}>{" "}</Grid>
              <Grid>{renderSpaceHeading(activeSpace, userList, currentUser)}</Grid>
            </Grid>
            <Grid item xs={5}>
              {getMembers()}
            </Grid>
          </Grid>
        </Paper>
        <Paper
          ref={messagePanel}
          onScroll={(event) => handleOnScroll(event, isInitialLoad)}
          elevation={3}
          sx={{
            height: `${height - 60 - 56 - 30 - getHeaderHeight()}px`,
            marginBottom: "10px",
            overflowY: "auto",
            flexWrap: "nowrap",
          }}
        >
          <List
            sx={{
              py: 0,
              px: 0,
              textAlign: "center",
              width: "100%",
            }}
          >
            {renderMessageList()}
          </List>
        </Paper>
      </RecordListWrapper>
    </>
  );
}

export default MessageRecordContainer;
