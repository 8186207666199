import {
    ComponentRef,
    getComponentRef,
    getFieldString,
    isObject,
    renderMessageObject,
    renderMessageText
} from "./MessageTagUtil";
import {Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";

const MessageTableBasicTagDataTemplate = {
    title: "A Test Table",
    caption: "Making it work",
    headings: [],
    rows: [
        {row: []}
    ]
}

export const MessageTableBasicTagTemplate = {
    component: "MessageTableBasicTag",
    data: {}
}
/**
 * {
 *     "component": "MessageTableBasicTag",
 *     "data": {
 *          "title": "A Test Table",
 *          "caption": "Making it work",
 *          "headings": [
 *              objects or strings
 *          ],
 *          "rows": [
 *              {"row": [objects or strings]}
 *          ]
 *     }
 * }
 *
 * e.g. a simple table
 * {
 *     "component": "MessageTableBasicTag",
 *     "data": {
 *          "title": "A Test Table",
 *          "caption": "Making it work",
 *          "headings": [
 *              "Heading 1", "Heading 2", "Heading 3"
 *          ],
 *          "rows": [
 *              {"row": ["Row 1, Col 1","Row 1, Col 2","Row 1, Col 3"]},
 *              {"row": ["Row 2, Col 1","Row 2, Col 2",{"component": "MessageBoldTag", "data": {"content":["Row 2, Col 3"]}}]},
 *              {"row": [{"component": "MessageBoldTag", "data": {"content":["Row 3, Col 1"]}},{"component": "MessageLinkTag", "data": {"link":"https://app.axialshift.com", "display": "A Report Link"}},"Row 3, Col 3"]}
 *          ]
 *     }
 * }
 */
export function MessageTableBasicTag(props) {
    const {objectData, handleSubComponentLoaded = (subComponent = {...ComponentRef}, isLoaded = false) => {}, messageTime, itemIndex, level} = props;

    const handleCellComponentLoading = (component = {...ComponentRef}, isLoaded = false) => {
        console.log(`handleCellComponentLoading ${component.componentName} - ${isLoaded}`);
    }

    const renderCellData = (cellData, cellIndex) => {
        const cellContent = isObject(cellData) ? renderMessageObject(cellData, handleCellComponentLoading, messageTime, cellIndex, level + 1) : cellData;

        return <TableCell key={`cell-${cellIndex}`}>{cellContent}</TableCell>;
    }

    const renderRow = (rowId = "", rowData = []) => {
        return <TableRow key={rowId}>{rowData.map((cellData, cellIndex) => renderCellData(cellData, cellIndex))}</TableRow>;
    }

    const renderTableBasicObject = (data = MessageTableBasicTagDataTemplate) => {
        const headings = data.headings;
        const rows = data.rows;
        const title = getFieldString(data.title);
        const caption = getFieldString(data.caption);
        if (headings) {
            return <>
                {title.length > 0 ? <Typography variant="h5">{title}</Typography> : null}
                <TableContainer key={`table-container-${messageTime}-${itemIndex}-${level}`} component={Paper} sx={{backgroundColor: "background.paper"}}>
                    <Table size="small">
                        <TableHead>
                            {renderRow("header-1", headings)}
                        </TableHead>
                        <TableBody>
                            {rows.map((rowData, index) => renderRow(`data-row-${index}`, rowData.row))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {caption.length > 0 ? <Box sx={{width: '100%', textAlign: 'right'}}><Typography variant="caption">{caption}</Typography></Box> : null}
            </>;
        }
        return <></>;
    }

    const renderTag = () => {
        handleSubComponentLoaded(getComponentRef("MessageTableBasicTag", itemIndex, level, messageTime), true);
        if (isObject(objectData)) {
            return renderTableBasicObject(objectData);
        }
        return renderMessageText(objectData);
    }

    return renderTag();
}
