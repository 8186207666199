import React, { forwardRef, useState, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { getElementToRender } from "./RenderElements/ElementDefinitions";

const FormRenderer = forwardRef((props, ref) => {
  const { formTemplate, forceReadOnly } = props;
  const [formValues, setFormValues] = useState(
    (formTemplate || []).map((t) => ({ id: t.id, value: t.value }))
  );

  useImperativeHandle(ref, () => ({
    isFormValid() {
      const mergedFormTemplateAndValues = formTemplate.map((t) => ({
        required: t.required,
        label: t.label,
        value: formValues.find((u) => u.id === t.id)?.value,
      }));

      const formElementsMarkedAsRequiredAndMissingValue =
        mergedFormTemplateAndValues.filter(
          (t) => t.required && (t.value === undefined || t.value === "")
        );

      return {
        isValid: formElementsMarkedAsRequiredAndMissingValue.length === 0,
        errors: formElementsMarkedAsRequiredAndMissingValue
          .map((t, i) => `${i + 1}. ${t.label}`)
          .join("\n"),
      };
    },
    getFormData() {
      return formValues;
    },
  }));

  const onChange = (id, value, extension) => {
    //Extension value will be just on files input
    let newFormValue = {
      id: id,
      value: value,
    };
    if (extension) {
      newFormValue = {
        ...newFormValue,
        extension,
      };
    }

    setFormValues((prevState) => [
      ...prevState.filter((t) => t.id !== id),
      newFormValue,
    ]);
  };

  return (
    <Box sx={{ flexDirection: "row", padding: 1 }}>
      <Box sx={{ flex: 1 }}>
        {formTemplate.map((t) => {
          let initialValue = formValues.filter((u) => u.id === t.id);

          if (initialValue[0]) {
            initialValue = initialValue[0];
          }

          const settings = t;
          if (forceReadOnly) {
            settings.readOnly = true;
          }

          return (
            <Box key={t.id}>
              {getElementToRender(settings, onChange, initialValue)}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
});

FormRenderer.propTypes = {
  formTemplate: PropTypes.array.isRequired,
  formData: PropTypes.array,
  // overrides the form data settings and forces readonly on all form elements
  forceReadOnly: PropTypes.bool,
};

FormRenderer.defaultProps = {
  formData: [],
  forceReadOnly: false,
};

export default FormRenderer;
