import React, {useEffect, useState} from "react";
import {
  ComponentRef, getComponentRef,
  isObject,
  renderMessageObject,
  renderMessageText,
  TagContentTemplate,
} from "./MessageTagUtil";

export const MessageCompositeTagTemplate = {
  component: "MessageCompositeTag",
  data: {
    content: [],
  },
};
/**
 * {
 *     "component": "MessageCompositeTag",
 *     "data": {
 *          "content": [
 *              objects
 *          ]
 *     }
 * }
 */
const initializeState = (data) => {
  if (data) {
    return data.map((item, index) => {
      return {subIndex: index, isLoaded: false};
    });
  }
  return [];
}

export function MessageCompositeTag(props) {
  const { objectData, handleSubComponentLoaded = (subComponent = {...ComponentRef}, isLoaded = false) => {}, messageTime, itemIndex = "", level = 0 } = props;

  const [subComponents, setSubcomponents] = useState(initializeState(objectData.content));

  useEffect(() => {
    if (objectData.content) {
      // console.log(`MessageCompositeTag subcomponents changed`);
      const missingCount = subComponents.filter((c) => !c.isLoaded).length;
      if (missingCount === 0 && subComponents.length === objectData.content.length) {
        handleSubComponentLoaded(getComponentRef("MessageCompositeTag [List]", itemIndex, level, messageTime), true);
      }
    }
  }, [subComponents]);

  const handleSubComponents = (subIndex = 0, subComponent = {...ComponentRef}, isLoaded = false) => {
    // console.log(`[MessageCompositeTag] SubComponent Loading -> [${subIndex}] ${subComponent.componentName} = ${isLoaded}`);
    const findSubComponent = subComponents.find((c) => c.subIndex === subIndex);
    if (isLoaded && findSubComponent.isLoaded !== isLoaded) {
      setSubcomponents((prevState) => {
        const filteredState = prevState.filter((item) => item.subIndex !== subIndex);
        return [...filteredState, {subIndex: subIndex, isLoaded: isLoaded}];
      });
    }
  }

  const renderNormalText = (text) => {
    handleSubComponentLoaded(getComponentRef("MessageCompositeTag", itemIndex, level, messageTime), true);
    return renderMessageText(text);
  };

  const renderCompositeObject = (data = TagContentTemplate) => {
    const content = objectData.content;
    if (content) {
      return content.map((contentItem, compositeIndex) => {
        if (isObject(contentItem)) {
          //console.log(`Rendering : ${contentItem.component}`);
          return renderMessageObject(
            contentItem,
            (subComponent, isLoaded) => handleSubComponents(compositeIndex, subComponent, isLoaded),
            messageTime,
            `${itemIndex}-${compositeIndex}`,
            level
          );
        }
        return contentItem;
      });
    }
    return <></>;
  };

  return isObject(objectData)
    ? renderCompositeObject(objectData)
    : renderNormalText(objectData);
}
