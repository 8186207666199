import PropTypes from "prop-types";
import React, { Component } from "react";
import { TextField, InputAdornment } from "@mui/material";
import { Guid } from "../util/Helpers";
import { VisibilityOff } from "@mui/icons-material";

class TextInputField extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);

    let id;
    if (props.id) {
      id = props.id;
    } else if (props.label) {
      id = props.label.replace(/\s/g, "");
    } else if (props.name) {
      id = props.name.replace(/\s/g, "");
    } else if (props.helperText) {
      id = props.helperText.replace(/\s/g, "");
    } else {
      id = Guid();
    }

    this.state = {
      id: id,
    };
  }

  render() {
    const {
      className,
      value,
      formField,
      inputProps,
      hiddenValue,
      readOnly,
      hiddenValueTooltip,
      name,
      isRequired,
      ...otherProps
    } = this.props;

    //const classes = classNames("input-field", className);

    const fieldName = (formField && formField.name) || name;

    let disabled = readOnly;
    let fieldValue = value;
    if (formField) {
      fieldValue = formField.value;
    }

    const classLevelInputProps = {};
    if (hiddenValue) {
      fieldValue = undefined;
      disabled = true;

      classLevelInputProps.endAdornment = (
        <InputAdornment position="end">
          <span title={hiddenValueTooltip}>
            <VisibilityOff />
          </span>
        </InputAdornment>
      );
      classLevelInputProps.disabled = readOnly;
    }

    return (
      <TextField
        {...otherProps}
        id={this.state.id}
        name={fieldName}
        className={`input-field ${className}`}
        value={fieldValue}
        onChange={(e) => this.handleChange(e)}
        margin="none"
        InputProps={{ ...classLevelInputProps, ...inputProps }}
        disabled={disabled}
        required={isRequired}
      />
    );
  }

  handleChange = (event) => {
    const value = event.target.value;

    if (this.props.formField) {
      this.props.formField.onChange(value);
    }

    if (this.props.onChange) {
      this.props.onChange(value, event);
    }
  };
}

TextInputField.muiName = "TextField";

TextInputField.propTypes = {
  formField: PropTypes.object,
  value: PropTypes.any,
  label: PropTypes.string,
  icon: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.object,
  ]),
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  readOnly: PropTypes.bool,
  inputProps: PropTypes.object,
  hiddenValue: PropTypes.bool,
  hiddenValueTooltip: PropTypes.string,
  helperText: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.any,
  autoComplete: PropTypes.string,
};

export default TextInputField;
