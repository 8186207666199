import axios from "axios";
import moment from "moment";

import { MessageItemListStruct, MessageOperations } from "../data/MessageData";
import { proxyOptions, proxyOptionsWithToken } from "../../util/ProxyOptions";
import { MESSAGING_PAGE_SIZE, PageType } from "../../constants";

export const MessageItemReducer = (state, action) => {
  console.log("MessageItemReducer [" + action.type + "]");
  switch (action.type) {
    case MessageOperations.LOAD_MESSAGE_LIST:
      return action.ref;
    case MessageOperations.ADD_TOP_MESSAGE_LIST:
      return addTopMessagesItemList(state, action.ref);
    case MessageOperations.ADD_BOTTOM_MESSAGE_LIST:
      return addBottomMessagesItemList(state, action.ref);
    case MessageOperations.ADD_MESSAGE:
      return addMessageItem(state, action.ref);
    case MessageOperations.DELETE_MESSAGE:
      return deleteMessageItem(state, action.ref);
    case MessageOperations.UPDATE_MESSAGE:
      return updateMessageItem(state, action.ref);
    default:
      return state;
  }
};

export const MessageOperationTask = (action, data) => {
  console.log(`MessageOperations :: ${action}`);
  switch (action) {
    case MessageOperations.SEND_ADD_MESSAGE:
      sendAddMessageItem(data.accountDetails, data.payload);
      return;
    case MessageOperations.SEND_DELETE_MESSAGE:
      sendDeleteMessageItem(data.accountDetails, data.messageItem);
      return;
    case MessageOperations.SEND_UPDATE_MESSAGE:
      sendUpdateMessageItem(data.accountDetails, data.payload);
      return;
    default:
      return;
  }
};

const getSpaceLastViewed = (space, user) => {
  let lastViewedMember = space.members.find((member) => member.memberId === user.id);
  if (lastViewedMember) {
    // console.log(`getSpaceLastViewed [member found] -> ${lastViewedMember.lastAccessed}`);
    return lastViewedMember.lastAccessed;
  }
  const defaultLastViewed = moment.utc().subtract(6, 'months');
  // console.log(`getSpaceLastViewed [member not found] -> ${defaultLastViewed}`);
  return defaultLastViewed;
}

const loadMessages = async (
  accountDetails,
  currentUser,
  activeSpace,
  pageNumber
) => {
  return await axios.post(
    accountDetails.baseUrl + "/messageListV3",
    {
      accountId: accountDetails.axial.account.id,
      companyId: accountDetails.axial.company.id,
      activeUserId: currentUser.id,
      activeSpaceId: activeSpace.id,
      pageNumber: pageNumber,
      detectMostRecentUnreadMessage: false,
      lastViewed: getSpaceLastViewed(activeSpace, currentUser),
      pageSize: MESSAGING_PAGE_SIZE,
    },
    proxyOptions
  );
};

const loadInitialPageMessages = async (
  accountDetails,
  currentUser,
  activeSpace
) => {
  return await axios.post(
    accountDetails.baseUrl + "/messageListV3",
    {
      accountId: accountDetails.axial.account.id,
      companyId: accountDetails.axial.company.id,
      activeUserId: currentUser.id,
      activeSpaceId: activeSpace.id,
      detectMostRecentUnreadMessage: true,
      lastViewed: getSpaceLastViewed(activeSpace, currentUser),
      pageSize: MESSAGING_PAGE_SIZE,
    },
    proxyOptions
  );
};

export const MessageItemLoader = async (
  accountDetails,
  activeSpace,
  currentUser,
  dispatcher
) => {
  try {
    const response = await loadInitialPageMessages(
      accountDetails,
      currentUser,
      activeSpace
    );
    dispatcher({
      type: MessageOperations.LOAD_MESSAGE_LIST,
      ref: {
        ...MessageItemListStruct,
        accountId: accountDetails.axial.account.id,
        companyId: accountDetails.axial.company.id,
        spaceId: activeSpace.id,
        messageList: response.data.messages,
        firstPageLoaded: response.data.pageNumber,
        startIndex: response.data.unseenMessageIndex,
        pageCount: response.data.pageCount,
        totalMessageCount: response.data.totalMessageCount,
        lastPageLoaded: response.data.pageNumber,
        isTopEndReached:
          response.data.pageType === PageType.TopBottomPage ||
          response.data.pageType === PageType.TopPage,
        isBottomEndReached:
          response.data.pageType === PageType.TopBottomPage ||
          response.data.pageType === PageType.BottomPage,
      },
    });
  } catch (error) {
    console.log("Error loading initial messages: ", error);
  }
};

export const MessagesTopItemLoader = async (
  pageNumber,
  accountDetails,
  activeSpace,
  currentUser,
  dispatcher
) => {
  try {
    const response = await loadMessages(
      accountDetails,
      currentUser,
      activeSpace,
      pageNumber
    );
    dispatcher({
      type: MessageOperations.ADD_TOP_MESSAGE_LIST,
      ref: {
        ...MessageItemListStruct,
        accountId: accountDetails.axial.account.id,
        companyId: accountDetails.axial.company.id,
        spaceId: activeSpace.id,
        messageList: response.data.messages,
        pageCount: response.data.pageCount,
        totalMessageCount: response.data.totalMessageCount,
        lastPageLoaded: response.data.pageNumber,
        isTopEndReached: response.data.pageType === PageType.TopPage,
      },
    });
  } catch (error) {
    console.log("Error loading top new messages: ", error);
  }
};

export const MessagesBottomItemLoader = async (
  pageNumber,
  accountDetails,
  activeSpace,
  currentUser,
  dispatcher
) => {
  try {
    const response = await loadMessages(
      accountDetails,
      currentUser,
      activeSpace,
      pageNumber
    );
    dispatcher({
      type: MessageOperations.ADD_BOTTOM_MESSAGE_LIST,
      ref: {
        ...MessageItemListStruct,
        accountId: accountDetails.axial.account.id,
        companyId: accountDetails.axial.company.id,
        spaceId: activeSpace.id,
        messageList: response.data.messages,
        pageCount: response.data.pageCount,
        totalMessageCount: response.data.totalMessageCount,
        lastPageLoaded: response.data.pageNumber,
        isBottomEndReached: response.data.pageType === PageType.BottomPage,
      },
    });
  } catch (error) {
    console.log("Error loading top new messages: ", error);
  }
};

const getDay = (date) => {
  return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
};

export const messageDateFormat = (date) => {
  if (date.constructor === Date) {
    const checkDate = new Date();
    // Locale aware day difference
    const dateDiffDays = Math.floor(
      (getDay(checkDate).getTime() - getDay(date).getTime()) /
        (1000 * 60 * 60 * 24)
    );
    // Hide Year
    const hideYear = checkDate.getUTCFullYear() === date.getUTCFullYear();

    if (dateDiffDays === 0) return moment(date).format("h:mma");
    // if (dateDiffDays === 1) return moment(date).format("[Yesterday] h:mma");
    if (dateDiffDays < 8) return moment(date).format("ddd h:mma");
    if (hideYear) return moment(date).format("MMM Do @ h:mma");
    return moment(date).format("MMM Do, YYYY @ h:mma");
  }
  return "";
};

const sendAddMessageItem = (accountDetails, data) => {
  console.log("Adding new message item");
  const sendMessageItem = async () => {
    await axios.post(
      accountDetails.baseUrl + "/createMessage",
      data,
      proxyOptionsWithToken(accountDetails.token)
    );
  };
  sendMessageItem()
      .then(
          () => console.log(`Message Sent`),
          (error) => console.log(`Error Occurred Performing Message Sent - ${error}`)
      );
};

export const addTopMessagesItemList = (state, messageItemList) => {
  // console.log(`Adding to the top message item list.  Adding ${messageItemList.messageList.length} messages`);
  return {
    ...messageItemList,
    firstPageLoaded: state.firstPageLoaded,
    isBottomEndReached: state.isBottomEndReached,
    messageList: [...messageItemList.messageList, ...state.messageList],
  };
};

export const addBottomMessagesItemList = (state, messageItemList) => {
  // console.log(`Adding to the bottom message item list.  Adding ${messageItemList.messageList.length} messages`);
  return {
    ...messageItemList,
    firstPageLoaded: state.firstPageLoaded,
    isTopEndReached: state.isTopEndReached,
    messageList: [...state.messageList, ...messageItemList.messageList],
  };
};

const addMessageItem = (messageItemList, messageItem) => {
  if (
    messageItemList.accountId !== messageItem.accountId ||
    messageItemList.spaceId !== messageItem.spaceId
  ) {
    return messageItemList;
  }
  // check if last page is loaded
  if (!messageItemList.isBottomEndReached) {
    return messageItemList;
  }
  // console.log(`I can add this new message ${messageItem.id}`);
  const filteredMessageList = messageItemList.messageList.filter(
    (item) =>
      (item.marker && item.marker !== messageItem.marker) || !item.marker
  );

  if (
    messageItem.isSchedule === undefined &&
    new Date().getTime() < new Date(messageItem.createdUtc).getTime()
  ) {
    //isSchedule is temp value to show message in frontend a couple of seconds.
    return {
      ...messageItemList,
      messageList: [...filteredMessageList],
    };
  } else {
    return {
      ...messageItemList,
      messageList: [...filteredMessageList, messageItem],
    };
  }
};

const sendDeleteMessageItem = (accountDetails, messageItem) => {
  console.log("Deleting Msg Id: " + messageItem.id);
  const sendDeleteItem = async () => {
    await axios.post(
      accountDetails.baseUrl + "/deleteMessage",
      {
        id: messageItem.id,
        accountId: accountDetails.axial.account.id,
        companyId: accountDetails.axial.company.id,
        spaceId: messageItem.spaceId,
      },
      proxyOptionsWithToken(accountDetails.token)
    );
  };
  sendDeleteItem().then(() => {
    console.log(`Deleted message item -> ${messageItem.id}`);
  });
};

const deleteMessageItem = (messageItemList, messageItem) => {
  console.log("Deleting message item : " + messageItem.id);
  const removeIndex = messageItemList.messageList.findIndex((element) => {
    return element.id === messageItem.id;
  });
  console.log("message item element index to remove : " + removeIndex);
  if (removeIndex < 0) return messageItemList;
  const updatedList = messageItemList.messageList.filter(
    (item, index) => index !== removeIndex
  );
  const deletedIds = messageItemList.deletedIds ? [...messageItemList.deletedIds] : [];
  deletedIds.push(messageItem.id);
  return { ...messageItemList, deletedIds: [...deletedIds], messageList: [...updatedList] };
};

const updateMessageItem = (messageItemList, messageItem) => {
  console.log("Updating message item : " + messageItem.id);
  const updateIndex = messageItemList.messageList.findIndex((element) => {
    return element.id === messageItem.id;
  });
  console.log("message item element index to update : " + updateIndex);
  if (updateIndex < 0) return messageItemList;
  const updatedList = messageItemList.messageList.filter(
      (item, index) => index !== updateIndex
  );
  updatedList.splice(updateIndex, 1, messageItem);
  return { ...messageItemList, messageList: [...updatedList] };
}

const sendUpdateMessageItem = (accountDetails, data) => {
  const sendMessageUpdate = async () => {
    await axios.put(
      accountDetails.baseUrl + "/updateMessage",
      data,
      proxyOptionsWithToken(accountDetails.token)
    );
  };
  sendMessageUpdate().then(
    () => console.log(`Message Updated`),
    (error) => console.log(`Failed to send updated message - ${error}`)
  );
};
