import {
    ComponentRef,
    getComponentRef,
    isObject,
    renderMessageObject,
    renderMessageText,
    TagContentTemplate
} from "./MessageTagUtil";

/**
 * {
 *     "component": "MessageItalicTag",
 *     "data": {
 *          "content": [
 *              objects or strings
 *          ]
 *     }
 * }
 */
export function MessageItalicTag(props) {
    const {objectData, handleSubComponentLoaded = (subComponent = {...ComponentRef}, isLoaded = false) => {}, messageTime, itemIndex, level} = props;

    const handleItalicContentsComponentLoading = (component = {...ComponentRef}, isLoaded = false) => {
        console.log(`handleItalicContentsComponentLoading ${component.componentName} - ${isLoaded}`);
    }

    const renderItalicObject = (data = TagContentTemplate) => {
        const content = data.content;
        if (content) {
            return <i key={`italic-${messageTime}-${itemIndex}-${level}`} style={{fontStyle: 'italic'}}>{
                content.map((contentItem) => {
                        if (isObject(contentItem)) {
                            return renderMessageObject(contentItem, handleItalicContentsComponentLoading, messageTime, itemIndex, level);
                        }
                        return contentItem;
                    }
                )}</i>;
        }
        return <></>;
    }

    const renderTag = () => {
        handleSubComponentLoaded(getComponentRef("MessageItalicTag", itemIndex, level, messageTime), true);
        if (isObject(objectData)) {
            return renderItalicObject(objectData);
        }
        return renderMessageText(objectData)
    }

    return renderTag();
}
