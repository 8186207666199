const SpaceTypes = {
  PRIVATE: 0,
  PUBLIC: 1,
  DIRECT: 2,
  READ_ONLY: 3,
  DYNAMIC_GROUP: 6,
  ARCHIVED: 7,
};

export default SpaceTypes;
