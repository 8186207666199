import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../models/UserContext";
import { SpaceContext } from "../../contexts/SpaceContext";
import {
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { isSpaceMember } from "../../controllers/SpaceController";
import AddSpace from "./AddSpace";
import SpaceTypes from "../../data/SpaceTypes";

export default function MessageSpaceSection(props) {
  const { spaceHeading, sidebarState, handleSidebar } = props;

  const { currentUser } = useContext(UserContext.UserContext);
  const { spaceList } = useContext(SpaceContext.SpaceListContext);

  const [isSectionOpen, setSectionOpen] = useState(true);

  const [isAddButtonActive, toggleAddButton] = useState(false);
  const [isEditButtonActive, toggleEditButton] = useState(false);

  const handleAddButton = () => {
    toggleAddButton(!isAddButtonActive);
  };

  const handleEditButton = () => {
    toggleEditButton(!isEditButtonActive);
  };

  const handleSectionOpen = () => {
    setSectionOpen(!isSectionOpen);
  };

  const validateSpace = (space, spaceTypeNumber) => {
    if (spaceTypeNumber === SpaceTypes.ARCHIVED) {
      return (
        space.archived &&
        isSpaceMember(space, currentUser) &&
        (currentUser.isSuperUser || currentUser.isAdmin || currentUser.isOwner)
      );
    }

    if (spaceTypeNumber === SpaceTypes.PRIVATE) {
      return (
        !space.archived &&
        (space.messageSpaceType === SpaceTypes.PRIVATE ||
          space.messageSpaceType === SpaceTypes.DYNAMIC_GROUP) &&
        isSpaceMember(space, currentUser)
      );
    }

    if (
      spaceTypeNumber === SpaceTypes.DIRECT ||
      spaceTypeNumber === SpaceTypes.PUBLIC
    ) {
      return (
        !space.archived &&
        space.messageSpaceType === spaceTypeNumber &&
        isSpaceMember(space, currentUser)
      );
    }
    return false;
  };

  const getEditor = () => {
    const EditDialog = spaceHeading.editor;
    if (spaceHeading.hasEditButton)
      return (
        <>
          <IconButton key="edit" onClick={handleEditButton} sx={{ ml: 1 }}>
            <EditIcon
              sx={{
                color: "text.disabled",
                "&:hover": { color: "text.primary" },
                fontSize: "medium",
              }}
            />
          </IconButton>
          <EditDialog
            isOpen={isEditButtonActive}
            toggleOpen={handleEditButton}
            spaceHeading={spaceHeading}
          />
        </>
      );
    else return <></>;
  };

  const getAdd = (isActive) => {
    const EditDialog = spaceHeading.editor;
    const handleAdd =
      spaceHeading.id === SpaceTypes.DIRECT
        ? handleEditButton
        : handleAddButton;
    if (spaceHeading.hasAddButton) {
      return (
        <>
          <IconButton key="add" edge="end" onClick={handleAdd}>
            <AddIcon
              sx={{
                color: "text.disabled",
                "&:hover": { color: "text.primary" },
                fontSize: "medium",
              }}
            />
          </IconButton>
          {spaceHeading.id === SpaceTypes.DIRECT ? (
            <EditDialog
              isOpen={isEditButtonActive}
              toggleOpen={handleEditButton}
              spaceHeading={spaceHeading}
            />
          ) : (
            <AddSpace
              isOpen={isActive}
              toggleOpen={handleAddButton}
              spaceHeading={spaceHeading}
            />
          )}
        </>
      );
    }
    return <></>;
  };

  return (
    <>
      <ListItem
        onClick={handleSectionOpen}
        sx={{ width: 1, pl: "16px", pr: "4px", py: "4px" }}
      >
        {isSectionOpen ? <ExpandLess /> : <ExpandMore />}
        <ListItemText key="heading-text" primary={spaceHeading.name} />
        <ListItemSecondaryAction>
          <>
            {getAdd(isAddButtonActive)}
            {getEditor()}
          </>
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={isSectionOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {spaceList.map((space) => {
            if (validateSpace(space, spaceHeading.id)) {
              const SpaceItem = spaceHeading.listComponent;
              return (
                <SpaceItem
                  key={space.id}
                  sourceSpace={space}
                  spaceHeading={spaceHeading}
                  sidebarState={sidebarState}
                  handleSidebar={handleSidebar}
                />
              );
            }
            return false;
          })}
        </List>
      </Collapse>
    </>
  );
}
