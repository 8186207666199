import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

const YouTubePlayer = ({ embedId, style }) => (
  <Box sx={style}>
    <iframe
      width="853"
      height="480"
      src={`https://www.youtube.com/embed/${embedId}`}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </Box>
);

YouTubePlayer.propTypes = {
  embedId: PropTypes.string.isRequired,
};

export default YouTubePlayer;
