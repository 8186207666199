export const SpaceOperations = {
  INITIALIZE: "INITIALIZE",
  MERGE_UNREAD_DATA: "MERGE_UNREAD_DATA",
  ADD_MESSAGE: "ADD_MESSAGE",
  UPDATE_SPACE: "UPDATE_SPACE",
  DELETE_SPACE: "DELETE_SPACE",
  ADD_SPACE: "ADD_SPACE",
  ADD_SPACE_MEMBER: "ADD_SPACE_MEMBER",
  REMOVE_SPACE_MEMBER: "REMOVE_SPACE_MEMBER",
  SEND_DELETE_SPACE: "SEND_DELETE_SPACE",
  SEND_UPDATE_SPACE: "SEND_UPDATE_SPACE",
  ADD_NEW_SPACE: "ADD_NEW_SPACE",
  SEND_ADD_SPACE: "SEND_ADD_SPACE",
  MAP_USER: "MAP_USER",
  UNMAP_USER: "UNMAP_USER",
  SET_SPACE_UNREAD_MESSAGES_COUNT: "SET_SPACE_UNREAD_MESSAGES_COUNT",
};
