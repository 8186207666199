import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Grid, Typography, useTheme } from "@mui/material";
import moment from "moment";
import { TimePickerField } from "../../Forms";
import {
  ComponentRef,
  getComponentRef,
} from "../../message/components/records/MessageTagUtil";
import { FontSizes } from "../../constants";

const TimeInput = ({
  label,
  id,
  readOnly,
  placeholderText,
  required,
  onChange,
  value,
  handleSubComponentLoaded = (
    subComponent = { ...ComponentRef },
    isLoaded = false
  ) => {},
  itemIndex,
  level,
  messageTime,
  ...rest
}) => {
  const [internalValue, setInternalValue] = useState(value);

  const theme = useTheme();

  useEffect(() => {
    if (internalValue) {
      setInternalValue(value);
    }
  }, [value]);

  useEffect(() => {
    handleSubComponentLoaded(
      getComponentRef("TimeInput", itemIndex, level, messageTime),
      true
    );
  }, []);

  const handleOnInputChange = (value) => {
    setInternalValue(value);
    onChange(id, value);
  };

  const renderReadOnly = () => {
    if (!internalValue || internalValue.length === 0) {
      return <></>;
    }
    const formattedTime = internalValue
      ? moment.parseZone(`${internalValue}`).format("h:mm a")
      : "";
    return (
      <>
        <Grid item container sx={{ marginBottom: "1px" }}>
          <Grid item sx={{ marginRight: "10px" }}>
            <Typography
              sx={{
                fontWeight: "bold",
                color: theme.palette.forms.label,
                fontSize: FontSizes.RegularText,
              }}
            >
              {label}:
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              sx={{
                color: theme.palette.forms.regular,
                fontSize: FontSizes.RegularText,
              }}
            >
              {formattedTime}
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  };

  const renderTimePicker = () => {
    return (
      <TimePickerField
        id={id}
        label={label}
        className="col-12"
        date={internalValue}
        onChange={(val) => handleOnInputChange(val)}
        isRequired={required}
        readOnly={readOnly}
        {...rest}
      />
    );
  };

  const renderElement = () => {
    if (readOnly) {
      return renderReadOnly();
    }
    return renderTimePicker();
  };

  return renderElement();
};

TimeInput.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  placeholderText: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.any,
};

export default TimeInput;
