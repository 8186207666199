import { useContext, useEffect, useState } from "react";
import { AccountDetailsContext } from "../components/message/models/AccountDetailsContext";
import { SpaceContext } from "../components/message/contexts/SpaceContext";
import { SpaceOperationTask } from "../components/message/controllers/SpaceController";
import { SpaceOperations } from "../components/message/data/SpaceOperations";
import { SpaceMemberStruct } from "../components/message/data/SpaceData";
import { Guid } from "../components/util/Helpers";

import { api } from "../App";

const useUnreadMessages = (space) => {
  const { accountDetails } = useContext(
    AccountDetailsContext.AccountDetailsContext
  );
  const { activeSpace } = useContext(SpaceContext.ActiveSpaceContext);
  const { spaceList, spaceListDispatch } = useContext(
    SpaceContext.SpaceListContext
  );

  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);

  const handleSpaceMessagesRead = async (message, user) => {
    if (message === undefined) {
      return;
    }

    // Newly added or dynamic may not exist in member list
    const spaceMember = activeSpace.members.find((member) => {
      return member.memberId === user.id;
    });
    if (spaceMember) {
      // Update date
      spaceMember.lastAccessed = message.createdDate;
    } else {
      activeSpace.members.push({
        ...SpaceMemberStruct,
        id: Guid(),
        memberId: user.id,
        doShow: true,
        lastAccessed: message.createdDate,
        messageSpaceId: activeSpace.id,
      });
    }

    await SpaceOperationTask(SpaceOperations.SEND_UPDATE_SPACE, {
      accountDetails: accountDetails,
      space: activeSpace,
    });

    getSpaceUnreadMessagesCount();
  };

  const getSpaceUnreadMessagesCount = async () => {
    const res = await api.getSpaceUnreadMessagesCount(space.id);
    if (res.status === 200) {
      if (res.data.hasError) {
        console.log(res.data.errorMessages);
      } else {
        // console.log(`Updated response from server for ${space.name} [${space.id}] is a new count of ${res.data.result}`);
        setUnreadMessagesCount(res.data.result);
        spaceListDispatch({
          type: SpaceOperations.SET_SPACE_UNREAD_MESSAGES_COUNT,
          ref: {
            spaceId: space.id,
            unreadMessagesCount: res.data.result,
          },
        });
      }
    } else {
      console.log(
        `There was an error finding unread messages -> (${res.status}) ${res.statusText}`
      );
    }
  };

  const loadSpaceUnreadMessagesCount = () => {
    if (space.id === "") {
      return;
    }

    const unreadCount = spaceList.find((s) => s.id === space.id)?.unreadCount;
    if (unreadCount) {
      setUnreadMessagesCount(unreadCount);
    } else {
      getSpaceUnreadMessagesCount(space);
    }
  };

  //Keeps unreadMessagesCount updated when space changes.
  useEffect(() => {
    loadSpaceUnreadMessagesCount();
  }, [space]);

  return [unreadMessagesCount, handleSpaceMessagesRead];
};

export default useUnreadMessages;
