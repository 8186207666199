import {
  useContext,
  useState,
  useMemo,
  useRef,
  useLayoutEffect,
  useEffect,
} from "react";

import { AccountDetailsContext } from "../../models/AccountDetailsContext";
import { UserContext } from "../../models/UserContext";

import { SpaceContext } from "../../contexts/SpaceContext";

import { MessageOperationTask } from "../../controllers/MessageController";
import { MessageOperations } from "../../data/MessageData";
import {
  TextField,
  Box,
  Button,
  IconButton,
  useTheme,
  useMediaQuery,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import NearMeIcon from "@mui/icons-material/NearMe";
import AddIcon from "@mui/icons-material/Add";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import NoteIcon from "@mui/icons-material/Note";
import CloseIcon from "@mui/icons-material/Close";
import YouTubeIcon from "@mui/icons-material/YouTube";
import ImageIcon from "@mui/icons-material/Image";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowUp";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import { api } from "../../../../App";
import { MessageContext } from "../../contexts/MessageContext";
import { UserListContext } from "../../models/UserListContext";
import { MessageTextTagTemplate } from "../records/MessageTextTag";
import { MessageLogTagTemplate } from "../records/MessageLogTag";
import { MessageCompositeTagTemplate } from "../records/MessageCompositeTag";
import LogEntryModal from "../attachments/LogEntryModal";
import StaffNoteModal from "../attachments/StaffNoteModal";
import YouTubeModal from "../attachments/YoutubeModal";
import FileModal from "../attachments/FileModal";
import Popup from "../modals/Popup";
import ScheduleMessageModal from "../modals/ScheduleMessageModal";
import { MessageTagOptions } from "../../models/MessageRecordModel";
import { AppContext } from "../../contexts/AppContext";
import { LETTERS_AND_NUMBERS_REGEX } from "../../../constants";
import { AxiosErrorHandler } from "../../../util/AxiosHelper";
import { Guid } from "../../../util/Helpers";

const options = {
  shouldForwardProp: (prop) => prop !== "borderColor",
};

const outlinedSelectors = [
  "& .MuiOutlinedInput-notchedOutline",
  "&:hover .MuiOutlinedInput-notchedOutline",
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline",
];

const TextFieldStyled = styled(
  TextField,
  options
)(({ borderColor, borderWidth = 3 }) => ({
  "& label.Mui-focused": {
    color: borderColor,
  },
  [outlinedSelectors.join(",")]: {
    borderWidth: borderWidth,
    borderColor,
  },
}));

function MessageEntry() {
  const [msgContent, setMsgContent] = useState("");
  const [compositeTagList, setCompositeTagList] = useState([]);
  const [attachedTags, setAttachedTags] = useState([]);
  const [isMissingFields, setIsMissingFields] = useState(false);

  const { snackbar, setSnackbar } = useContext(AppContext.SnackbarContext);
  const { accountDetails } = useContext(
    AccountDetailsContext.AccountDetailsContext
  );
  const { currentUser } = useContext(UserContext.UserContext);
  const { messageItemDispatcher } = useContext(MessageContext.MessageRecordMgr);
  const defaultAdminTags =
    currentUser.isAdmin || currentUser.isOwner || currentUser.hasAnyPermissions
      ? [
          { memberId: "all", name: "all" },
          { memberId: "all", name: "everyone" },
        ]
      : [];

  const { activeSpace, setActiveSpace } = useContext(
    SpaceContext.ActiveSpaceContext
  );
  const { userList } = useContext(UserListContext.UserListContext);

  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.down("md"));
  const matchesLg = useMediaQuery(theme.breakpoints.down("lg"));

  const [selectedLogType, setSelectedLogType] = useState("");
  const [logTypeEntries, setLogTypeEntries] = useState([]);
  const [staffNoteTypeEntries, setStaffNoteTypeEntries] = useState([]);

  const sendDisabled = useMemo(() => {
    return msgContent.length === 0;
  }, [msgContent]);

  const getLogTypes = () => {
    const loader = async () => {
      await api.getDayOfBusinessLogTypes().then(
        (response) => {
          const hasAccessLogTypes = response.data.result.logTypes.filter(
            (logType) => {
              if (logType.hasRestrictions === false) {
                return true;
              }
              return logType.channelIds.includes(activeSpace.id);
            }
          );
          setLogTypeEntries(hasAccessLogTypes);
        },
        (error) => AxiosErrorHandler(error)
      );
    };
    loader().then(
      () => console.log(`Loaded Log Types`),
      (error) => console.log(`An error occurred loading log types - ${error}`)
    );
  };

  const loadStaffNoteTypes = () => {
    const loader = async () => {
      await api.getDayOfBusinessStaffNoteTypes().then(
        (response) => {
          const hasAccessStaffNotes =
            response.data.result.staffNoteTypes.filter((staffNoteType) => {
              if (staffNoteType.hasRestrictions === false) {
                return true;
              }
              return staffNoteType.channelIds.includes(activeSpace.id);
            });
          setStaffNoteTypeEntries(hasAccessStaffNotes);
        },
        (error) => AxiosErrorHandler(error)
      );
    };
    loader().then(
      () => console.log(`Loaded Staff Notes`),
      (error) => console.log(`An error occurred loading staff notes - ${error}`)
    );
  };

  useEffect(() => {
    if (activeSpace.id !== "") {
      getLogTypes();
      loadStaffNoteTypes();
    }
  }, [activeSpace.id]);

  const widths = {
    small: {
      marginLeft: "10px",
      marginRight: "40px",
      indentLeft: "10px",
      indentRight: "20px",
    },
    medium: {
      marginLeft: "60px",
      marginRight: "40px",
      indentLeft: "10px",
      indentRight: "70px",
    },
    large: {
      marginLeft: "100px",
      marginRight: "40px",
      indentLeft: "10px",
      indentRight: "110px",
    },
  };

  //Options Popup.
  const [showOptionsPopup, setShowOptionsPopup] = useState(false);
  const [showSecondaryOptionsPopup, setShowSecondaryOptionsPopup] =
    useState(false);

  const PopupOptions = [
    {
      id: "LogEntry",
      label: "Add New Log Entry",
      icon: <BookmarksIcon style={{ marginRight: 5 }} />,
      show: logTypeEntries.length > 0,
      onPress: () => {
        setShowLogEntryModal(true);
        setShowOptionsPopup(false);
        setIsMissingFields(false);
      },
    },
    {
      id: "StaffNoteEntry",
      label: "Add New Staff Note Entry",
      icon: <NoteIcon style={{ marginRight: 5 }} />,
      show: staffNoteTypeEntries.length > 0,
      onPress: () => {
        setShowStaffNoteModal(true);
        setShowOptionsPopup(false);
        setIsMissingFields(false);
      },
    },
    {
      id: "YoutubeSelector",
      label: "Link a YouTube Video",
      icon: <YouTubeIcon style={{ marginRight: 5 }} />,
      show: true,
      onPress: () => {
        setShowYouTubeModal(true);
        setShowOptionsPopup(false);
      },
    },
    {
      id: "FileSelector",
      label: "Select an Image",
      icon: <ImageIcon style={{ marginRight: 5 }} />,
      show: true,
      onPress: () => {
        setShowFileModal(true);
        setShowOptionsPopup(false);
      },
    },
  ];

  const SecondPopupOptions = [
    {
      id: "ScheduleMessage",
      label: "Schedule message for the future",
      icon: <EventAvailableIcon style={{ marginRight: 5 }} />,
      show: true,
      onPress: () => {
        setShowScheduleMsgModal(true);
        setShowSecondaryOptionsPopup(false);
      },
    },
  ];

  //Form modal.
  const [showLogEntryModal, setShowLogEntryModal] = useState(false);
  const [showStaffNoteModal, setShowStaffNoteModal] = useState(false);
  const [showYouTubeModal, setShowYouTubeModal] = useState(false);
  const [showFileModal, setShowFileModal] = useState(false);
  const [formTemplateData, setFormTemplateData] = useState([]);

  const [showScheduleMsgModal, setShowScheduleMsgModal] = useState(false);

  // Members tag states.
  const [atIndex, setAtIndex] = useState(-1); //Tag(@) index. Is -1 when no tag exists.
  const [mentionsList, setMentionsList] = useState([]); //List of mentions with index in message.
  const [searchedUsers, setSearchedUsers] = useState([]); //List of users selection when tag added in message.
  const [tagUserSearched, setTagUserSearched] = useState("");
  //Core list of spaceMembers to search when showing users selection.
  const spaceMembers = useMemo(() => {
    if (userList.length !== 0) {
      return [
        ...activeSpace.members.map((member) => {
          const user = userList.find(({ id }) => member.memberId === id);
          return { ...member, name: `${user?.firstName} ${user?.lastName}` };
        }),
        ...defaultAdminTags,
      ];
    } else {
      return [];
    }
  }, [activeSpace, userList]);
  const [inputSelection, setInputSelection] = useState({ start: 0, end: 0 });

  const entryInputRef = useRef();
  const entryDivRef = useRef();
  const entryContainerRef = useRef();
  const formRendererRef = useRef();

  const hasMsgEditPermission = useMemo(() => {
    const { authorizedUsersIds } = activeSpace;
    if (authorizedUsersIds && authorizedUsersIds.length > 0) {
      return (
        currentUser.isSuperUser || authorizedUsersIds.includes(currentUser.id)
      );
    } else {
      return true;
    }
  }, [activeSpace, currentUser]);

  const MIN_TEXT_AREA_HEIGHT = 1;

  const getMargin = (isLeft = true) => {
    if (isLeft) {
      return matchesLg
        ? matchesMd
          ? widths.small.marginLeft
          : widths.medium.marginLeft
        : widths.large.marginLeft;
    }
    return matchesLg
      ? matchesMd
        ? widths.small.marginRight
        : widths.medium.marginRight
      : widths.large.marginRight;
  };

  const getIndent = (isLeftIndent = false) => {
    if (isLeftIndent) {
      return matchesLg
        ? matchesMd
          ? widths.small.indentLeft
          : widths.medium.indentLeft
        : widths.large.indentLeft;
    }
    return matchesLg
      ? matchesMd
        ? widths.small.indentRight
        : widths.medium.indentRight
      : widths.large.indentRight;
  };

  const addMessageWithTagSet = (messageTagSet) => {
    const findScheduled = messageTagSet.userTags.find(
      (tag) =>
        tag.name === MessageTagOptions.ScheduleTag.Name &&
        tag.createdBy === currentUser.id
    );
    const updatedDate =
      findScheduled &&
      findScheduled.metaData.find(
        (kv) => kv.key === MessageTagOptions.ScheduleTag.KVDate
      ).value;
    const marker = new Date().getTime();

    let payload = {
      accountId: accountDetails.axial.account.id,
      companyId: accountDetails.axial.company.id,
      spaceId: activeSpace.id,
      createdById: currentUser.id,
      message: buildMessageContent(msgContent),
      isVisible: false,
      marker: marker,
      createdUtc: new Date(updatedDate),
      messageTagSet,
    };

    MessageOperationTask(MessageOperations.SEND_ADD_MESSAGE, {
      accountDetails: accountDetails,
      payload: payload,
    });
    setMsgContent("");
    setMentionsList([]);
    setCompositeTagList([]);
    setAttachedTags([]);
    messageItemDispatcher({
      type: MessageOperations.ADD_MESSAGE,
      ref: { ...payload, id: marker, isSchedule: true },
    });
    setSnackbar({
      ...snackbar,
      open: true,
      severity: "success",
      message: "Message scheduled",
    });
  };

  const addMessageItem = (content) => {
    const marker = new Date().getTime();
    const messageId = Guid();
    const payload = {
      accountId: accountDetails.axial.account.id,
      companyId: accountDetails.axial.company.id,
      spaceId: activeSpace.id,
      createdById: currentUser.id,
      message: buildMessageContent(content),
      isVisible: false,
      marker: marker,
      id: messageId,
    };

    MessageOperationTask(MessageOperations.SEND_ADD_MESSAGE, {
      accountDetails: accountDetails,
      payload: payload,
    });
    setMsgContent("");
    setMentionsList([]);
    setCompositeTagList([]);
    setAttachedTags([]);
    messageItemDispatcher({
      type: MessageOperations.ADD_MESSAGE,
      ref: { ...payload, id: messageId, createdUtc: new Date() },
    });
  };

  const getTagList = (message, userTags) => {
    let tagsList = [];
    let hashTagIndex = -1;
    for (let charId = 0; charId < message.length; charId++) {
      const prevCharId = charId - 1;
      const isLeftBlank = message[prevCharId] === " " || prevCharId < 0;
      if (isLeftBlank && message[charId] === "#") {
        hashTagIndex = charId;
      } else {
        // If hashtag found.
        if (hashTagIndex !== -1) {
          //If end of hashtag.
          if (message[charId] === " " || message[charId + 1] === undefined) {
            let sliceIndex = 0;
            for (let mentionId = 0; mentionId < userTags.length; mentionId++) {
              const userTag = userTags[mentionId];
              if (hashTagIndex > userTag.indexStart) {
                tagsList.push({
                  ...userTag,
                  value: userTag.name,
                  component: "MessageUserTag",
                });
              } else {
                break;
              }
              sliceIndex = mentionId + 1;
            }
            userTags = userTags.slice(sliceIndex);
            const hashTagEnd = message[charId] === " " ? charId : charId + 1;
            tagsList.push({
              indexStart: hashTagIndex,
              value: message.slice(hashTagIndex + 1, hashTagEnd),
              component: "MessageHashTag",
            });
            hashTagIndex = -1;
          } else {
            if (!LETTERS_AND_NUMBERS_REGEX.test(message[charId])) {
              hashTagIndex = -1;
            }
          }
        }
      }
    }
    return [
      ...tagsList,
      ...userTags.map((tag) => ({
        ...tag,
        value: tag.name,
        component: "MessageUserTag",
      })),
    ];
  };

  const buildMessageContent = (content) => {
    let tagsMessageContent = [];
    let tagList = getTagList(content.trim(), mentionsList); // Builds tags lists for both user and hash tags.
    if (compositeTagList.length === 0 && tagList.length === 0) {
      return content;
    }
    if (tagList.length > 0) {
      tagList.map(({ indexStart, value, id, component }, i) => {
        let chunkStart = 0;
        const chunkEnd = indexStart + value.length + 1;
        const tagSymbol = component === "MessageUserTag" ? "@" : "#";
        if (i !== 0) {
          chunkStart =
            tagList[i - 1].indexStart + tagList[i - 1].value.length + 1;
        }
        const firstChunk = content.substring(chunkStart, indexStart);
        firstChunk.length > 0 && tagsMessageContent.push(firstChunk);
        tagsMessageContent.push({
          component,
          id,
          data: { content: [`${tagSymbol}${value}`] },
        });
        if (i === tagList.length - 1) {
          const lastChunk = content.substring(chunkEnd);
          lastChunk.length > 0 && tagsMessageContent.push(lastChunk);
        }
      });
    }
    const textTag = {
      ...MessageTextTagTemplate,
      data: {
        content:
          tagsMessageContent.length > 0 ? tagsMessageContent : [msgContent],
      },
    };
    const contentTag = {
      ...MessageCompositeTagTemplate,
      data: {
        content: [textTag, ...compositeTagList],
      },
    };

    return JSON.stringify(compositeTagList.length > 0 ? contentTag : textTag);
  };

  const handleKeyPress = (event, content) => {
    if (event.ctrlKey && event.key === "Enter") {
      setMsgContent(content + String.fromCharCode(13));
      event.preventDefault();
    } else if (event.key === "Enter") {
      addMessageItem(content);
      event.preventDefault();
    }
  };

  const handleOnChange = (start, message) => {
    const lastCharIndex = start - 1;
    const lastChar = message[lastCharIndex];
    const prevChar = message[lastCharIndex - 1];
    const nextChar = message[lastCharIndex + 1];

    //Checks if removed or added char in message.
    if (message.length < msgContent.length) {
      const lengthDifference = msgContent.length - message.length;
      //When char removed, if next char isn't tag then hide users selection.
      if (message[atIndex] !== "@") {
        setAtIndex(-1);
        setSearchedUsers([]);
        setTagUserSearched("");
      }
      reCalculateMentionsIndex(lastCharIndex, lengthDifference, true);
      //When char removed, if next char is tag then show users selection.
      if (
        lastChar === "@" &&
        (!prevChar || prevChar === " ") &&
        (!nextChar || nextChar === " ")
      ) {
        setAtIndex(lastCharIndex);
        setSearchedUsers(spaceMembers);
      }
    } else {
      const lengthDifference = message.length - msgContent.length;
      //Check no tag exists before showing user selection.
      if (atIndex === -1) {
        if (
          lastChar === "@" &&
          (!prevChar || prevChar === " ") &&
          (!nextChar || nextChar === " ")
        ) {
          setAtIndex(lastCharIndex);
          setSearchedUsers(spaceMembers);
          setTagUserSearched("");
        }
      } else {
        //If user tag exists in message then filter users with chars after tag.
        if (message[atIndex] === "@") {
          const userSearched = message.substring(atIndex + 1, start);
          setTagUserSearched(userSearched);
          setSearchedUsers(
            spaceMembers.filter(({ name }) =>
              name
                .trim()
                .toLowerCase()
                .includes(userSearched.trim().toLowerCase())
            )
          );
        }
      }
      reCalculateMentionsIndex(lastCharIndex, lengthDifference);
    }

    setMsgContent(message);
  };

  /**
   * Recalculates indexes from mentions list when the message is beeing modified.
   * @param {int} charIndex Index of last char.
   * @param {int} charDifference Difference between new message and old one.
   * @param {boolean} isCharRemoved Value to increase or reduce index in mentionsList.
   */
  const reCalculateMentionsIndex = (
    charIndex,
    charDifference,
    isCharRemoved = false
  ) => {
    //Works only when message changes.
    if (charDifference > 0) {
      if (charDifference === 1) {
        calculateMentions(charIndex, charDifference, isCharRemoved);
      } else {
        isCharRemoved &&
          calculateMentionsOnSelection(charIndex, charDifference);
      }
    }
  };

  const calculateMentions = (charIndex, charDifference, isCharRemoved) => {
    let newMentionsList = [];
    for (let i = 0; i < mentionsList.length; i++) {
      const tagStart = mentionsList[i].indexStart;
      const tagEnd = mentionsList[i].indexStart + mentionsList[i].name.length;
      //Better way to handle tag remove. The code will be added and improved depending on QA.
      if (tagStart < charIndex && tagEnd > charIndex) {
        /*const newMessage = msgContent.substring(0, tagStart) + msgContent.substring(tagEnd + 1);
        console.log('newMessage: ', newMessage);
        const newMentionsList = mentionsList.map((mention) =>
          mention.indexStart < charIndex
            ? mention
            : {...mention, indexStart: mention.indexStart - (mention.name.length + 1)}
        );
        setMentionsList(newMentionsList);
        setBackgroundMessageContent(newMessage);
        setMsgContent(newMessage);*/
      } else {
        //Keeps mentions without changes if they are before the new char added.
        if (tagEnd <= charIndex) {
          newMentionsList.push(mentionsList[i]);
        } else {
          //Increase or reduce atTag index from mentions.
          const newIndexStart = isCharRemoved
            ? mentionsList[i].indexStart - charDifference
            : mentionsList[i].indexStart + charDifference;
          newMentionsList.push({
            ...mentionsList[i],
            indexStart: newIndexStart,
          });
        }
      }
    }
    setMentionsList(newMentionsList);
  };

  const calculateMentionsOnSelection = (charIndex, charDifference) => {
    let newMentionsList = [];
    for (let i = 0; i < mentionsList.length; i++) {
      const tagStart = mentionsList[i].indexStart;
      const tagEnd = mentionsList[i].indexStart + mentionsList[i].name.length;

      if (tagStart < charIndex && tagEnd < charIndex) {
        newMentionsList.push(mentionsList[i]);
      }
      if (tagStart > inputSelection.end && tagEnd > inputSelection.end) {
        const newIndexStart = mentionsList[i].indexStart - charDifference;
        newMentionsList.push({ ...mentionsList[i], indexStart: newIndexStart });
      }
    }
    setMentionsList(newMentionsList);
  };

  /**
   * Handle press on user selection for tagging.
   * @param {string} name User name.
   * @param {Guid} id User id.
   */
  const handleUserPress = (name, id) => {
    const newMessage = `${msgContent.substring(
      0,
      atIndex
    )}@${name}${msgContent.substring(
      atIndex + tagUserSearched.length + 1,
      msgContent.length
    )}`;
    let isNewMentionAdded = false;
    const newMention = { name, id, indexStart: atIndex };
    let newMentionsList = [];
    //Recalculates indexes in mentionList when a tag is added in the message.
    for (let i = 0; i < mentionsList.length; i++) {
      if (mentionsList[i].indexStart < atIndex) {
        newMentionsList.push(mentionsList[i]);
        if (mentionsList.length === i + 1 && !isNewMentionAdded)
          newMentionsList.push(newMention); //If is last one and no mention was added, then added.
      } else {
        if (isNewMentionAdded) {
          newMentionsList.push({
            ...mentionsList[i],
            indexStart:
              mentionsList[i].indexStart - tagUserSearched.length + name.length,
          });
        } else {
          isNewMentionAdded = true;
          newMentionsList.push(newMention);
          newMentionsList.push({
            ...mentionsList[i],
            indexStart:
              mentionsList[i].indexStart - tagUserSearched.length + name.length,
          });
        }
      }
    }

    setMsgContent(newMessage);
    setAtIndex(-1);
    setSearchedUsers([]);
    setTagUserSearched("");
    setMentionsList(
      newMentionsList.length > 0 ? newMentionsList : [newMention]
    );
    entryInputRef.current.focus();
  };

  const handleLogEntrySave = (formTemplateAndValues) => {
    if (formRendererRef) {
      const formSaveResult = formRendererRef.current.isFormValid();

      if (!formSaveResult.isValid) {
        setIsMissingFields(true);
        console.log("Some Fields Are Required!", formSaveResult.errors);

        return;
      }

      const logEntryAttachedTag = {
        key: formTemplateAndValues.id,
        tag: {
          ...MessageLogTagTemplate,
          data: formTemplateAndValues,
        },
      };

      setAttachedTags((prevState) => [...prevState, logEntryAttachedTag]);
    }
  };

  const handleTagAdd = (key, tag) => {
    setAttachedTags((prevState) => [...prevState, { key, tag }]);
  };

  useEffect(() => {
    if (attachedTags.length > 0) {
      console.log(`attachedTags = ${attachedTags.length}`);
      const tagsOnlyArray = attachedTags.map((attachment) => {
        return attachment.tag;
      });
      setCompositeTagList([...tagsOnlyArray]);
    }
  }, [attachedTags]);

  useEffect(() => {
    if (compositeTagList.length > 0) {
      const logEntryFound = compositeTagList.find(
        (tag) => tag.component === MessageLogTagTemplate.component
      );
      if (logEntryFound) {
        addMessageItem(msgContent);
        setSelectedLogType("");
      }
    }
  }, [compositeTagList]);

  useLayoutEffect(() => {
    entryInputRef.current.style.height = "1px";
    entryInputRef.current.style.height = `${Math.max(
      entryInputRef.current.scrollHeight,
      MIN_TEXT_AREA_HEIGHT
    )}px`;
    entryDivRef.current.style.height = `${Math.max(
      entryInputRef.current.scrollHeight,
      MIN_TEXT_AREA_HEIGHT
    )}px`;
    entryContainerRef.current.style.height = `${Math.max(
      entryInputRef.current.scrollHeight,
      MIN_TEXT_AREA_HEIGHT
    )}px`;
  }, [msgContent]);

  return (
    <>
      <LogEntryModal
        show={showLogEntryModal}
        formData={formTemplateData}
        formRendererRef={formRendererRef}
        isMissingFields={isMissingFields}
        hideModal={() => setShowLogEntryModal(false)}
        setFormData={setFormTemplateData}
        onSave={handleLogEntrySave}
      />
      <StaffNoteModal
        show={showStaffNoteModal}
        formData={formTemplateData}
        formRendererRef={formRendererRef}
        isMissingFields={isMissingFields}
        hideModal={() => setShowStaffNoteModal(false)}
        setFormData={setFormTemplateData}
        onSave={handleLogEntrySave}
      />
      <YouTubeModal
        show={showYouTubeModal}
        hideModal={() => setShowYouTubeModal(false)}
        onSave={handleTagAdd}
      />
      <FileModal
        show={showFileModal}
        hideModal={() => setShowFileModal(false)}
        onSave={handleTagAdd}
      />
      <ScheduleMessageModal
        show={showScheduleMsgModal}
        hideModal={() => setShowScheduleMsgModal(false)}
        onSave={(messageTagSet) => {
          addMessageWithTagSet(messageTagSet);
          setShowScheduleMsgModal(false);
        }}
      />
      <Popup
        show={showOptionsPopup}
        items={PopupOptions}
        style={{
          position: "absolute",
          bottom: 30,
          left: 100,
        }}
        renderItem={({ id, label, icon, show, onPress }) => (
          <>
            {show && (
              <Button
                key={id}
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  width: "100%",
                  color: "white",
                }}
                id={id}
                onClick={onPress}
              >
                {icon}
                {label}
              </Button>
            )}
          </>
        )}
      />
      <Popup
        show={showSecondaryOptionsPopup}
        items={SecondPopupOptions}
        style={{
          position: "absolute",
          bottom: 30,
          right: 100,
        }}
        renderItem={({ id, label, icon, show, onPress }) => (
          <>
            {show && (
              <Button
                key={id}
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  width: "100%",
                  color: "white",
                }}
                id={id}
                onClick={onPress}
              >
                {icon}
                {label}
              </Button>
            )}
          </>
        )}
      />
      <Box sx={{ width: "100%" }}>
        {searchedUsers.length !== 0 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: 1,
            }}
          >
            {searchedUsers.map(({ name, memberId }) => (
              <Button
                key={memberId}
                style={{
                  justifyContent: "left",
                  backgroundColor: "#212b38",
                  color: "white",
                  marginBottom: 1,
                  width: "80%",
                }}
                onClick={() => handleUserPress(name, memberId)}
              >
                {name}
              </Button>
            ))}
          </Box>
        )}
        <Box
          ref={entryContainerRef}
          sx={{
            display: "flex",
            position: "relative",
            marginX: `${getMargin()}`,
            overflowY: "visible",
            borderRadius: "30px",
            backgroundColor: "background.paperDark",
          }}
        >
          <div
            style={{
              fontSize: 17,
              minHeight: 38,
              fontWeight: "normal",
              lineHeight: 17,
            }}
          >
            <div
              ref={entryDivRef}
              style={{
                position: "absolute",
                height: 38,
                width: "90%",
                padding: 9,
                paddingLeft: 45,
                boxSizing: "border-box",
                overflowWrap: "break-word",
                whiteSpace: "pre-wrap",
                textAlign: "start",
                overflowY: "hidden",
                border: "1px solid transparent",
                backgroundColor: "transparent",
                lineHeight: "20px",
                color: "red",
              }}
            >
              {/*mentionsList.length === 0 && <span>{msgContent}</span> <-- Use this to check if regular text is align with textarea value.*/}
              {mentionsList.length !== 0 && (
                <>
                  {mentionsList.map(({ indexStart, name }, i) => {
                    let chunkStart = 0;
                    const chunkEnd = indexStart + name.length + 1;
                    if (i !== 0) {
                      chunkStart =
                        mentionsList[i - 1].indexStart +
                        mentionsList[i - 1].name.length +
                        1;
                    }
                    return (
                      <span id={i}>
                        <span style={{ visibility: "hidden" }}>
                          {msgContent.substring(chunkStart, indexStart)}
                        </span>
                        <span
                          style={{
                            position: "relative",
                            color: "#726eff", // primary.main not working here.
                            textShadow:
                              "1px 1px 1px #2b3747, 1px -1px 1px #2b3747, -1px 1px 1px #2b3747, -1px -1px 1px #2b3747",
                            zIndex: 1,
                          }}
                        >{`@${name}`}</span>
                        {i === mentionsList.length - 1 && (
                          <span style={{ visibility: "hidden" }}>
                            {msgContent.substring(chunkEnd)}
                          </span>
                        )}
                      </span>
                    );
                  })}
                </>
              )}
            </div>
            <textarea
              placeholder={`${
                hasMsgEditPermission ? "Add comment" : "Read only permissions"
              }`}
              ref={entryInputRef}
              rows={1}
              style={{
                position: "absolute",
                height: 38,
                width: "90%",
                padding: 9,
                paddingLeft: 45,
                boxSizing: "border-box",
                resize: "none",
                border: "1px solid transparent",
                outline: "none",
                backgroundColor: "transparent",
                fontFamily: "inherit",
                fontSize: "inherit",
                letterSpacing: "inherit",
                overflowY: "hidden",
              }}
              onChange={({ target: { selectionStart, value } }) =>
                handleOnChange(selectionStart, value)
              }
              onSelect={({ target: { selectionStart, selectionEnd } }) => {
                setInputSelection({
                  start: selectionStart,
                  end: selectionEnd,
                });
              }}
              value={msgContent}
              disabled={!hasMsgEditPermission}
            />
          </div>
          <IconButton
            onClick={() => setShowOptionsPopup(!showOptionsPopup)}
            color="primary"
            size="large"
          >
            {!showOptionsPopup ? (
              <AddIcon fontSize="inherit" />
            ) : (
              <CloseIcon fontSize="inherit" />
            )}
          </IconButton>
          <Box
            sx={{
              display: "flex",
              marginLeft: "auto",
              backgroundColor: "primary.main",
              borderRadius: 10,
            }}
          >
            <IconButton
              onClick={() => addMessageItem(msgContent)}
              size={"medium"}
              disabled={sendDisabled}
            >
              <NearMeIcon fontSize={"small"} />
            </IconButton>
            <hr />
            <IconButton
              onClick={() =>
                setShowSecondaryOptionsPopup(!showSecondaryOptionsPopup)
              }
              size={"small"}
              disabled={msgContent.length === 0}
            >
              {!showSecondaryOptionsPopup ? (
                <KeyboardArrowUpIcon fontSize={"small"} />
              ) : (
                <KeyboardArrowDownIcon fontSize={"small"} />
              )}
            </IconButton>
          </Box>
        </Box>
        <Box sx={{ marginLeft: "10%" }}>
          {compositeTagList.length > 0 && (
            <Typography>{`${compositeTagList.length} attachment${
              compositeTagList.length > 1 ? "s" : ""
            } pending`}</Typography>
          )}
        </Box>
      </Box>
    </>
  );
}

export default MessageEntry;
