import PrivateSpaceEditor from "../components/spaces/PrivateSpaceEditor";
import DirectSpaceEditor from "../components/spaces/DirectSpaceEditor";
import MessageSpace from "../components/spaces/MessageSpace";
import LockIcon from "@mui/icons-material/Lock";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import FolderCenterIcon from "@mui/icons-material/FolderOff";

import SpaceTypes from "./SpaceTypes";

import { unmapUser, deleteSpace } from "./SpaceActions";

const deleteArchivedSpace = (account, space, user) => {
  if (
    space.messageSpaceType === SpaceTypes.PRIVATE ||
    space.messageSpaceType === SpaceTypes.DYNAMIC_GROUP
  ) {
    unmapUser(account, space, user);
  }
  deleteSpace(account, space, user);
};

export const SpaceMemberStruct = {
  id: "",
  memberId: "",
  lastAccessed: new Date(1970, 1, 1),
  doShow: false,
  messageSpaceId: "",
};

export const SpaceStruct = {
  id: "",
  createdUtc: new Date(),
  createdBy: "",
  accountId: "",
  companyId: "",
  messageSpaceType: SpaceTypes.PRIVATE,
  name: "[initial name]",
  members: [{ ...SpaceMemberStruct }],
  unreadCount: 0,
};

const SpaceHeading = {
  id: 0,
  name: "",
  isPanelActive: false,
  hasAddButton: false,
  hasEditButton: false,
  placeholderText: "",
  className: "",
  editor: null,
  listComponent: null,
  listIcon: null,
  deleteDialog: {
    title: "",
    body: "",
    action: null,
  },
};

export const SpaceHeadings = [
  {
    ...SpaceHeading,
    id: SpaceTypes.PRIVATE,
    name: "Channels",
    isPanelActive: false,
    hasAddButton: true,
    hasEditButton: true,
    placeholderText: "new-private-name",
    className: "space-heading",
    editor: PrivateSpaceEditor,
    listComponent: MessageSpace,
    listIcon: LockIcon,
    deleteDialog: {
      title: "Confirm - Leave Channel",
      body: "Are you sure you want to LEAVE this channel -> ",
      action: unmapUser,
    },
  },
  {
    ...SpaceHeading,
    id: SpaceTypes.DIRECT,
    name: "Direct Messages",
    isPanelActive: false,
    hasAddButton: false,
    hasEditButton: true,
    placeholderText: "find-user",
    className: "space-heading",
    editor: DirectSpaceEditor,
    listComponent: MessageSpace,
    listIcon: AlternateEmailIcon,
    deleteDialog: {
      title: "Confirm - Leave DM",
      body: "Are you sure you want to LEAVE this DM -> ",
      action: deleteSpace,
    },
  },
  {
    ...SpaceHeading,
    id: SpaceTypes.DYNAMIC_GROUP,
    name: "Dynamic Groups",
    isPanelActive: false,
    hasAddButton: true,
    hasEditButton: false,
    placeholderText: "new-dynamic-group",
    className: "space-heading",
    //editor: DirectSpaceEditor,
    listComponent: MessageSpace,
    listIcon: BusinessCenterIcon,
    deleteDialog: {
      title: "Confirm - Leave Dynamic Group",
      body: "Are you sure you want to LEAVE this channel -> ",
      action: deleteSpace,
    },
  },
  {
    ...SpaceHeading,
    id: SpaceTypes.ARCHIVED,
    name: "Archived",
    isPanelActive: false,
    hasAddButton: false,
    hasEditButton: false,
    placeholderText: "new-dynamic-group",
    className: "space-heading",
    // editor: DirectSpaceEditor,
    listComponent: MessageSpace,
    listIcon: FolderCenterIcon,
    deleteDialog: {
      title: "Confirm - Leave Space",
      body: "Are you sure you want to LEAVE this space -> ",
      action: deleteArchivedSpace,
    },
  },
];
/*
    {
        ...SpaceHeading,
        id: SpaceTypes.PUBLIC,
        name: "Public",
        isPanelActive: false,
        hasAddButton: true,
        hasEditButton: true,
        placeholderText: "new-public-name",
        className: "space-heading",
        editor: PublicSpaceEditor,
        listComponent: MessageSpace,
        listIcon: FeedIcon,
        deleteDialog: {
            title: "Confirm - User Remove",
            body: "Are you sure you want to be removed from this space -> ",
            action: unmapUser
        }
    },
    {
        ...SpaceHeading,
        id: SpaceTypes.READ_ONLY,
        name: "Read Only",
        isPanelActive: false,
        hasAddButton: false,
        hasEditButton: false,
        placeholderText: "readonly-space",
        className: "space-heading",
        editor: null,
        listComponent: null,
        listIcon: null,
        deleteDialog: {
            title: "",
            body: "",
            action: null
        }
    }
];
*/
