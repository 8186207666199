import React from "react";
import PropTypes from "prop-types";
import { Box, Switch, Typography } from "@mui/material";

const SwitchField = ({ label = "", labelStyle = {}, value, color, toggle }) => {
  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography style={labelStyle}>{label}</Typography>
      <Switch checked={value} onChange={toggle} color={color} />
    </Box>
  );
};

SwitchField.propTypes = {
  label: PropTypes.string,
  labelStyle: PropTypes.object,
  value: PropTypes.bool,
  color: PropTypes.string,
  toggle: PropTypes.func,
};

export default SwitchField;
