import React from "react";
import { Box } from "@mui/material";
import {
  MessageTagOptions,
  MessageTagSetModel,
} from "../../../models/MessageRecordModel";
import { Emoji } from "emoji-picker-react";

const ReactionTag = ({
  messageTagSet = MessageTagSetModel,
  handleReactionRemove,
}) => {
  const reactions = messageTagSet.reactionTags;
  if (reactions === undefined || reactions.length === 0) {
    return false;
  }
  return (
    <Box
      key={"reaction"}
      sx={{display: "flex", flexDirection: "row", marginLeft: "auto", alignItems: "center"}}
    >
      {reactions.map((tag) => {
        const emoji = tag.metaData.find(
          (kv) => kv.key === MessageTagOptions.ReactionTag.KVReaction
        );
        if (emoji) {
          return (
            <Box key={tag.id} onClick={() => handleReactionRemove(emoji.value)} sx={{marginX: "1px", height: "24px"}}>
              <Emoji
                unified={emoji.value.codePointAt(0).toString(16)} // Get unicode code from emoji.
                //emojiStyle={EmojiStyle.NATIVE} // <-- This will set the default unicode Emojis.
                size={22}
              />
            </Box>
          );
        }
      })}
    </Box>
  );
};

export default ReactionTag;
