import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Grid, Typography, useTheme } from "@mui/material";
import moment from "moment";
import { DateTimeField } from "../../Forms";
import { FontSizes } from "../../constants";
import {
  ComponentRef,
  getComponentRef,
} from "../../message/components/records/MessageTagUtil";

const DateTimeInput = ({
  label,
  id,
  readOnly,
  required,
  onChange,
  value,
  handleSubComponentLoaded = (
    subComponent = { ...ComponentRef },
    isLoaded = false
  ) => {},
  itemIndex,
  level,
  messageTime,
}) => {
  const theme = useTheme();

  const [internalValue, setInternalValue] = useState(value);

  useEffect(() => {
    if (internalValue) {
      setInternalValue(value);
    }
  }, [value]);

  useEffect(() => {
    handleSubComponentLoaded(
      getComponentRef("DateTimeInput", itemIndex, level, messageTime),
      true
    );
  }, []);

  const handleOnInputChange = (value) => {
    setInternalValue(value);
    onChange(id, value);
  };

  const renderReadOnly = () => {
    if (!internalValue || internalValue.length === 0) {
      return <></>;
    }
    const date = new Date(internalValue.toString());
    const formattedDate = date
      ? moment.parseZone(date).format("dddd, MMM D YYYY @ h:mm a")
      : "";
    return (
      <>
        <Grid item container sx={{ marginBottom: "1px" }}>
          <Grid item sx={{ marginRight: "10px" }}>
            <Typography
              sx={{
                fontWeight: "bold",
                color: theme.palette.forms.label,
                fontSize: FontSizes.RegularText,
              }}
            >
              {label}:
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              sx={{
                color: theme.palette.forms.regular,
                fontSize: FontSizes.RegularText,
              }}
            >
              {formattedDate}
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  };

  const renderDateTimePicker = () => {
    return (
      <DateTimeField
        id={id}
        label={label}
        date={internalValue}
        onChange={(val) => handleOnInputChange(val)}
        isRequired={required}
        readOnly={readOnly}
      />
    );
  };

  const renderElement = () => {
    if (readOnly) {
      return renderReadOnly();
    }
    return renderDateTimePicker();
  };

  return renderElement();
};

DateTimeInput.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  placeholderText: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.any,
};

export default DateTimeInput;
