import {Footer} from "@mui-treasury/layout";
import MessageEntry from "./MessageEntry";

export default function MessageFooterContainer(props) {
    const {data} = props;

    return (<Footer sx={{position: "sticky", bottom: 0, left: 0, right: 0}}>
        {data.footer && <MessageEntry/>}
    </Footer>);
}
