import {SpaceOperationTask} from "../controllers/SpaceController";
import {SpaceOperations} from "./SpaceOperations";

export const unmapUser = (account, space, user) => {
    console.log("Before Member Remove => " + JSON.stringify(space));
    SpaceOperationTask(SpaceOperations.REMOVE_SPACE_MEMBER, {space: space, user: user});
    console.log("After Member Remove => " + JSON.stringify(space));
    SpaceOperationTask(SpaceOperations.SEND_UPDATE_SPACE, {
        accountDetails: account,
        space: space
    });
};

export const deleteSpace = (account, space, user) => {
    console.log("Deleting a space");
    SpaceOperationTask(SpaceOperations.SEND_DELETE_SPACE, {
        accountDetails: account,
        space: space
    })
}