import React, { useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import {
  Box,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from "@mui/material";
import { api } from "../../../App";
import {
  ComponentRef,
  getComponentRef,
} from "../../message/components/records/MessageTagUtil";
import { FontSizes } from "../../constants";

const SingleSelectInput = ({
  label,
  id,
  readOnly,
  placeholderText,
  required,
  options,
  optionsUrl,
  loadOptionsFromUrl,
  onChange,
  value,
  handleSubComponentLoaded = (
    subComponent = { ...ComponentRef },
    isLoaded = false
  ) => {},
  itemIndex,
  level,
  messageTime,
  ...rest
}) => {
  const theme = useTheme();

  const [internalValue, setInternalValue] = useState(value ? value : "");
  const [resolvedValue, setResolvedValue] = useState("");
  const [isLoadingOptions, setIsLoadingOptions] = useState(loadOptionsFromUrl);
  const [items, setItems] = useState(loadOptionsFromUrl ? [] : options);

  useEffect(() => {
    if (internalValue) {
      setInternalValue(value);
    }
  }, [value]);

  const handleOnInputChange = (value) => {
    setInternalValue(value);
    onChange(id, value);
  };

  const loadData = () => {
    const optionsLoader = async () =>
      await api.getByUrl(optionsUrl).then(
        (response) => {
          setItems(response.data);
          const findValue = response.data.find(
            (t) => t.value === internalValue
          );
          setResolvedValue(findValue ? findValue.name : "");
          setIsLoadingOptions(false);
        },
        (error) => {
          console.log(
            `An error occurred loading objects from ${optionsUrl} -> ${error.status}`
          );
        }
      );
    optionsLoader().then(
      () => {
        if (readOnly) {
          handleSubComponentLoaded(
            getComponentRef(
              "SingleSelectInput [From API Lookup]",
              itemIndex,
              level,
              messageTime
            ),
            true
          );
        }
      },
      (error) => console.log(`An error occurred loading options -> ${error}`)
    );
  };

  useEffect(() => {
    if (loadOptionsFromUrl === false) {
      if (readOnly) {
        handleSubComponentLoaded(
          getComponentRef(
            "SingleSelectInput [From Option List]",
            itemIndex,
            level,
            messageTime
          ),
          true
        );
      }
      return;
    }
    loadData();
  }, []);

  const renderReadOnly = () => {
    const displayValue = loadOptionsFromUrl ? resolvedValue : internalValue;
    if (!displayValue || displayValue.length === 0) {
      return <></>;
    }
    return (
      <>
        <Grid item container sx={{ marginBottom: "1px" }}>
          <Grid item sx={{ marginRight: "10px" }}>
            <Typography
              sx={{
                color: theme.palette.forms.label,
                fontWeight: "bold",
                fontSize: FontSizes.RegularText,
              }}
            >
              {label}:
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              sx={{
                color: theme.palette.forms.regular,
                fontSize: FontSizes.RegularText,
              }}
            >
              {displayValue}
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  };

  const renderOptionPicker = () => {
    return (
      <Box sx={{ marginBottom: 2 }}>
        <InputLabel>{label}</InputLabel>
        <Select
          label={label}
          id={id}
          value={internalValue}
          onChange={(e) => handleOnInputChange(e.target.value)}
          required={required}
          disabled={readOnly}
          fullWidth
        >
          {items.map(({ name, value }) => {
            return <MenuItem key={value} value={value}>{`${name}`}</MenuItem>;
          })}
        </Select>
      </Box>
    );
  };

  const renderElement = () => {
    if (isLoadingOptions) {
      return <></>;
    }
    if (readOnly) {
      return renderReadOnly();
    }
    return renderOptionPicker();
  };

  return renderElement();
};

SingleSelectInput.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  placeholderText: PropTypes.string,
  required: PropTypes.bool,
  options: PropTypes.array,
  optionsUrl: PropTypes.string,
  loadOptionsFromUrl: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.any,
};

export default SingleSelectInput;
