import React from "react";
import moment from "moment";
import { Grid, Typography, useTheme } from "@mui/material";

const Tag = ({ mode = "", label = "", date = new Date(), styles = {} }) => {
  const theme = useTheme();

  return (
    <Grid
      style={{
        display: "flex",
        flexDirection: "column",
        borderRadius: 3,
        borderWidth: 1,
        paddingLeft: 6,
        paddingRight: 6,
        paddingBottom: 1,
        paddingTop: 2,
        alignItems: "center",
        marginRight: 10,
        marginTop: 4,
        marginBottom: 3,
        height: 28,
        ...styles,
      }}
    >
      <Typography
        sx={{
          fontSize: 8,
          color: theme.palette.message.tag.text,
          padding: 0,
          margin: 0,
        }}
      >
        {label.toUpperCase()}
      </Typography>
      <Typography sx={{ fontSize: 9, padding: 0, margin: 0 }}>
        {moment(new Date(Number(date))).format(
          mode === "date" ? "M/D/YYYY" : "M/D/YYYY h:mm a"
        )}
      </Typography>
    </Grid>
  );
};

export default Tag;
