import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";
import moment from "moment";
import { DateField } from "../../Forms";
import {
  ComponentRef,
  getComponentRef,
} from "../../message/components/records/MessageTagUtil";
import { FontSizes } from "../../constants";

const DateInput = ({
  label,
  id,
  readOnly,
  required,
  onChange,
  value,
  handleSubComponentLoaded = (
    subComponent = { ...ComponentRef },
    isLoaded = false
  ) => {},
  itemIndex,
  level,
  messageTime,
  ...rest
}) => {
  const [internalValue, setInternalValue] = useState(value);

  useEffect(() => {
    if (internalValue) {
      setInternalValue(value);
    }
  }, [value]);

  useEffect(() => {
    handleSubComponentLoaded(
      getComponentRef("DateInput", itemIndex, level, messageTime),
      true
    );
  }, []);

  const handleOnInputChange = (value) => {
    setInternalValue(value);
    onChange(id, value);
  };

  const renderReadOnly = () => {
    const formattedDate = internalValue
      ? moment.parseZone(`${internalValue}`).format("dddd MMM D YYYY")
      : "";
    return (
      <>
        <Grid item container sx={{ marginBottom: "1px" }}>
          <Grid item sx={{ marginRight: "10px" }}>
            <Typography
              sx={{ fontWeight: "bold", fontSize: FontSizes.RegularText }}
            >
              {label}:
            </Typography>
          </Grid>
          <Grid item>
            <Typography sx={{ fontSize: FontSizes.RegularText }}>
              {formattedDate}
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  };

  const renderDatePicker = () => {
    return (
      <DateField
        id={id}
        label={label}
        className="col-12"
        date={internalValue}
        onChange={(val) => handleOnInputChange(val)}
        isRequired={required}
        readOnly={readOnly}
        {...rest}
      />
    );
  };

  const renderElement = () => {
    if (readOnly) {
      return renderReadOnly();
    }
    return renderDatePicker();
  };

  return renderElement();
};

DateInput.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  placeholderText: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.any,
};

export default DateInput;
